import moment from 'moment';
import {
    ADDRESS_COMPLETE_PARAM,
    COLLECTIVE_OPERATOR,
    EQUALS_OPERATOR,
    IS_ACTIVE_PARAM,
    IS_OPERATOR,
    OR_OPERATOR,
    AND_OPERATOR,
    GREATER_THAN_OPERATOR,
    LESS_THAN_OPERATOR,
    DOUBLE_EQUALS_OPERATOR,
    DATE_FROM_OPERATOR,
    DATE_TO_OPERATOR,
    GEODISTANCE_OPERATOR,
    LOCATION_PARAM,
    ADDRESS_PARCEL_SUBURB_STATE_PARAM,
    LEGAL_DESCRIPTION_PARAM,
    TITLE_REFERENCE_PARAM,
    NESTED_OPERATOR,
    CONTAINS_OPERATOR,
    STARTS_WITH_OPERATOR,
    AND_NOT_OPERATOR,
    COMPARE_OPERATOR,
    SALES_LAST_SALE_CONTRACT_DATE_PARAM,
    DISTINCT_FIELDS_PARAM,
    MAX_PROPERTIES_PER_PAGE,
    MAX_PAGE_PER_BATCH,
    GEOPOLYGON_OPERATOR,
    RENTAL_LAST_CAMPAIGN_END_DATE_PARAM,
    LATEST_PROPERTY_CAMPAIGN_PARAM,
} from 'js/constants/batchSearch/batchSearch';
import Commons from 'js/helpers/Commons';
import Export from 'js/helpers/Export';
import { RAPID_PROPERTY_TYPE_V2_TENURE } from 'js/helpers/Localization';
import { NOT_DIGIT_WITH_DECIMAL } from 'js/constants/regexPatterns';
import SearchFilterHelper from 'js/helpers/search/SearchFilterHelper';
import { isAU } from 'js/constants/crux';

const RANGE_OPERATOR = [GREATER_THAN_OPERATOR, LESS_THAN_OPERATOR];
const DATE_RANGE_OPERATOR = [DATE_FROM_OPERATOR, DATE_TO_OPERATOR];
const SEARCH_FIELDS = {
    [ADDRESS_PARCEL_SUBURB_STATE_PARAM]: 'parcelList.suggestions',
    [LEGAL_DESCRIPTION_PARAM]: 'titles.estates.legalDescription',
    [TITLE_REFERENCE_PARAM]: 'titles.titleReference',
};

const buildFilter = (field, operation, value) => ({
    field,
    operation,
    value,
});

const defaultBuilder = ({ param, paramValue }) => buildFilter(param, EQUALS_OPERATOR, paramValue);

const resultFormatBuilder = ({ resultsFormat, param, paramValue }) => ({
    ...resultsFormat,
    [param]: param === DISTINCT_FIELDS_PARAM ? paramValue.split(',') : paramValue,
});

const resultFormatSortBuilder = ({ resultsFormat, param, paramValue }) => {
    let sort = paramValue;
    if (Array.isArray(paramValue)) {
        sort = paramValue.map(item => (`${item.order}${item.attr}`));
    }
    return resultFormatBuilder({ resultsFormat, param, paramValue: sort.toString() });
};

const addressCollectiveBuilder = ({ paramValue }) => buildFilter(
    ADDRESS_COMPLETE_PARAM,
    COLLECTIVE_OPERATOR,
    paramValue,
);

const addressContainsBuilder = ({ paramValue }) => buildFilter(
    ADDRESS_COMPLETE_PARAM,
    CONTAINS_OPERATOR,
    paramValue,
);

const exactAddressBuilder = ({ paramValue }) => buildFilter(
    ADDRESS_COMPLETE_PARAM,
    EQUALS_OPERATOR,
    paramValue,
);

const isActiveFilterBuilder = ({ paramValue = 'true' }) => {
    // should set the paramValue to string to handle the boolean issue
    const activeValues = `${paramValue}`.split(',');
    return {
        operation: OR_OPERATOR,
        filters: activeValues.map(isActive =>
            buildFilter(IS_ACTIVE_PARAM, IS_OPERATOR, isActive === 'true')),
    };
};

const rangeFilterFromObject = (param, paramValue) => {
    const filters = [];
    const parseValue = (value, unit) => {
        const multiplier = unit === 'ha' ? 10000 : 1;
        try {
            return parseFloat(value.replace(NOT_DIGIT_WITH_DECIMAL, '').trim()) * multiplier;
        } catch {
            return value;
        }
    };
    const { min, max, unit } = paramValue;
    const _min = parseFloat(min);
    const _max = parseFloat(max);

    if (_min && _max && _min === _max) {
        filters.push(buildFilter(param, DOUBLE_EQUALS_OPERATOR, parseValue(min, unit)));
    } else {
        if (_min) filters.push(buildFilter(param, GREATER_THAN_OPERATOR, parseValue(min, unit)));
        if (_max) filters.push(buildFilter(param, LESS_THAN_OPERATOR, parseValue(max, unit)));
    }

    return filters;
}
const rangeFilterBuilder = ({ param, paramValue }) => {
    const filters = [];
    if (typeof paramValue === 'string' && paramValue.includes('-')) {
        const activeValues = paramValue.split('-');
        activeValues.forEach((value, index) => {
            if (value !== '') {
                filters.push(buildFilter(param, RANGE_OPERATOR[index], value.trim()));
            }
        });
    } else if (typeof paramValue === 'object') {
        filters.push(...rangeFilterFromObject(param, paramValue));
    } else {
        filters.push(buildFilter(param, DOUBLE_EQUALS_OPERATOR, paramValue));
    }
    return filters.length > 1 ?
        {
            operation: AND_OPERATOR,
            filters,
        }
        : filters[0];
};

const containFilterBuilder = ({ param, paramValue }) => paramValue && buildFilter(param, CONTAINS_OPERATOR, paramValue);

const multiValuesBuilder = (param, values) => ({
    operation: OR_OPERATOR,
    filters: values.map(value =>
        defaultBuilder({ param, paramValue: value.trim() })),
});

const splitValuesBuilder = (param, splitValues) => splitValues.length > 1
    ? multiValuesBuilder(param, splitValues)
    : defaultBuilder({ param, paramValue: splitValues[0].trim() });

const typeFilterBuilder = ({ param, paramValue }) => {
    const trimmedValue = paramValue?.replace(/"/g, ''); // MSG Saved Search v2 - v1 issue
    const values = trimmedValue.split(',');
    return splitValuesBuilder(param, values);
};

const buildWithdrawnCompare = () => ({
    operation: AND_NOT_OPERATOR,
    filters: [{
        operation: COMPARE_OPERATOR,
        condition: 'salesLastSaleContractDate > salesLastCampaignStartDate',
    }],
});

const commaConcatenatedToJsonBuilder = ({ param, paramValue }) => {
    const values = paramValue
        .replace(/"(.*)"/g, '$1')
        .split('","');
    return splitValuesBuilder(param, values);
};

const keywordsFilterBuilder = ({ paramValue = [] }) => ({
    operation: NESTED_OPERATOR,
    parentPath: LATEST_PROPERTY_CAMPAIGN_PARAM,
    filter: {
        operation: OR_OPERATOR,
        filters: paramValue?.map(keyword => buildFilter(
            `${LATEST_PROPERTY_CAMPAIGN_PARAM}.campaignDescription`,
            CONTAINS_OPERATOR,
            keyword
        )),
    },
});

const dateRangeFilterBuilder = ({ param, paramValue }) => {
    if (typeof paramValue === 'object') {
        if (!paramValue?.min && !paramValue?.max) {
            return null
        }

        return {
            operation: AND_OPERATOR,
            filters: [
                buildFilter(param, DATE_FROM_OPERATOR, Commons.formatDate(paramValue?.min?.trim(), 'YYYY-MM-DD')),
                buildFilter(param, DATE_TO_OPERATOR, Commons.formatDate(paramValue?.max?.trim(), 'YYYY-MM-DD')),
            ],
        };
    }

    const values = paramValue.split('-');
    return {
        operation: AND_OPERATOR,
        filters: values.map((value, index) =>
            buildFilter(param, DATE_RANGE_OPERATOR[index], Commons.formatDate(value.trim(), 'YYYY-MM-DD'))),
    };
};

const withdrawnDateRangeFilterBuilder = ({ param, paramValue }, isAndNot = false ) => {
    const values = typeof paramValue === 'string' ? paramValue.split('-') : [paramValue.min, paramValue.max];
    return {
        operation: isAndNot ? AND_NOT_OPERATOR : AND_OPERATOR,
        filters: [{
            operation: AND_OPERATOR,
            filters: values.map((value, index) =>
                buildFilter(param, DATE_RANGE_OPERATOR[index], Commons.formatDate(value.trim(), 'YYYY-MM-DD'))),
        }],
    }
};

const rentalOccupancyFilterBuilder = () => ({
    operation: AND_NOT_OPERATOR,
    filters: [
        {
            operation: AND_OPERATOR,
            filters: [
                buildFilter(
                    RENTAL_LAST_CAMPAIGN_END_DATE_PARAM,
                    DATE_FROM_OPERATOR,
                    moment().subtract(24, 'months').format('YYYY-MM-DD')
                ),
                buildFilter(
                    RENTAL_LAST_CAMPAIGN_END_DATE_PARAM,
                    DATE_TO_OPERATOR,
                    moment().format('YYYY-MM-DD')
                ),
            ],
        },
        {
            operation: AND_NOT_OPERATOR,
            filters: [
                buildFilter(
                    SALES_LAST_SALE_CONTRACT_DATE_PARAM,
                    DATE_FROM_OPERATOR,
                    moment().subtract(24, 'months').format('YYYY-MM-DD')
                ),
                buildFilter(
                    SALES_LAST_SALE_CONTRACT_DATE_PARAM,
                    DATE_TO_OPERATOR,
                    moment().format('YYYY-MM-DD')
                ),
            ]
        },
    ],
});
const withdrawnAndNotDateRangeFilterBuilder = ({ param, paramValue }) =>
    withdrawnDateRangeFilterBuilder({ param, paramValue }, true);

const checkboxFilterBuilder = ({ param, paramValue }) => {
    return buildFilter(param, IS_OPERATOR, paramValue);
};

const isActiveListingsFilterBuilder = ({ param, paramValue }) => buildFilter(
    param,
    IS_OPERATOR,
    paramValue,
);

const radiusFormatBuilder = ({ radiusParams }) => {
    return buildFilter(LOCATION_PARAM, GEODISTANCE_OPERATOR, radiusParams);
};

const excludePropertyBuilder = ({ propertyId }) => {
    return propertyId ? {
        operation: AND_NOT_OPERATOR,
        filters: [{
            field: 'id',
            operation: EQUALS_OPERATOR,
            value: propertyId,
        }]
    } : null;
};

const territoryFormatBuilder = ({ paramValue }) => {
    let parsedParamValue = paramValue;
    if (typeof paramValue === 'string') {
        parsedParamValue = paramValue.replace(/[\u201C\u201D]/g, '"');
        parsedParamValue = JSON.parse(parsedParamValue);
    }
    return buildFilter(LOCATION_PARAM, GEOPOLYGON_OPERATOR, parsedParamValue);
};

const tenureDateRangeBuilder = ({ paramValue, type }) => {
    const filters = [];
    const values = [paramValue?.min, paramValue?.max];
    const _values = values.filter(value => value).reverse();
    filters.push(buildFilter('type', EQUALS_OPERATOR, type));
    filters.push(..._values.map((value, index) => {
        const _value = Export.formatTenureDate(value, 'YYYY-MM-DD');
        return buildFilter(
            SALES_LAST_SALE_CONTRACT_DATE_PARAM,
            _values.length === 1 ? DATE_TO_OPERATOR : DATE_RANGE_OPERATOR[index],
            _value,
        );
    }));
    return {
        operation: AND_OPERATOR,
        filters,
    };
};

const COMPANY_OWNER_NAME_FILTER = {
    companyOwner: {
        ownerFirstName: (prefix, paramValue, isExact) =>
            (buildFilter(`${prefix}.firstName`, isExact ? EQUALS_OPERATOR : STARTS_WITH_OPERATOR, paramValue)),
        ownerLastName: (prefix, paramValue, isExact) =>
            (buildFilter(`${prefix}.lastName`,  isExact ? EQUALS_OPERATOR : STARTS_WITH_OPERATOR, paramValue)),
        companyName: (prefix, paramValue, isExact) => {
            const operator = isExact ? EQUALS_OPERATOR : CONTAINS_OPERATOR;
            return buildFilter(`${prefix}.companyName`, operator, paramValue);
        },
    },
    address: {
        addressState: (param, paramValue) => (defaultBuilder({ param, paramValue })),
        addressSuburb: (param, paramValue) => (defaultBuilder({ param, paramValue })),
    },
};

const VOLUME_FOLIO_FILTER = {
    volumeFolio: {
        volumeNumber: (prefix, paramValue) =>
            (buildFilter(`${prefix}.volume`, STARTS_WITH_OPERATOR, paramValue)),
        folioNumber: (prefix, paramValue) =>
            (buildFilter(`${prefix}.folio`, STARTS_WITH_OPERATOR, paramValue)),
    },
    address: {
        addressState: (param, paramValue) => (defaultBuilder({ param, paramValue })),
        addressSuburb: (param, paramValue) => (defaultBuilder({ param, paramValue })),
    },
};

const buildCompanyOwnerName = (parentPath, paramValue) => {
    const companyOwnerNameFilters = [];
    Object.keys(COMPANY_OWNER_NAME_FILTER.companyOwner).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            companyOwnerNameFilters
                // eslint-disable-next-line max-len
                .push(COMPANY_OWNER_NAME_FILTER.companyOwner[keys](parentPath, paramValue[keys], paramValue?.exactSearch));
        }
    });
    return {
        operation: NESTED_OPERATOR,
        parentPath,
        filter: {
            operation: AND_OPERATOR,
            filters: companyOwnerNameFilters,
        },
    };
};

const companyNameFilterBuilder = ({ paramValue }) => {
    const companyOwnerNameSearchFilters = [];
    const companyOwnerName = [];
    Object.keys(COMPANY_OWNER_NAME_FILTER.address).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            companyOwnerNameSearchFilters
                .push(COMPANY_OWNER_NAME_FILTER.address[keys](keys, paramValue[keys]));
        }
    });
    const isCurrentOwnerOnly = paramValue.isCurrentOwner === 'true';
    companyOwnerName
        .push(buildCompanyOwnerName('owners', paramValue));
    if (!isCurrentOwnerOnly) {
        companyOwnerName
            .push(buildCompanyOwnerName('previousOwners.owners', paramValue));
    }

    companyOwnerNameSearchFilters.push({
        operation: OR_OPERATOR,
        filters: companyOwnerName,
    });

    return companyOwnerNameSearchFilters;
};

const searchFieldFilterBuilder = ({ param, paramValue }) =>
    defaultBuilder({ param: SEARCH_FIELDS[param], paramValue });

const buildingNameFilterBuilder = (params) => {
    const _operation = params.exactSearch ? EQUALS_OPERATOR : STARTS_WITH_OPERATOR;
    return buildFilter('addressBuildingName', _operation, params.addressBuildingName);
};

const startsWithFilterBuilder = ({ param, paramValue }) =>
    buildFilter(param, STARTS_WITH_OPERATOR, paramValue);

const volumeFolioFilterBuilder = ({ paramValue }) => {
    const volumeFolioSearchFilters = [];
    Object.keys(VOLUME_FOLIO_FILTER.address).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            volumeFolioSearchFilters
                .push(VOLUME_FOLIO_FILTER.address[keys](keys, paramValue[keys]));
        }
    });

    const parentPath = 'volumeFolioList';
    Object.keys(VOLUME_FOLIO_FILTER.volumeFolio).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            const outerFilter = {
                operation: NESTED_OPERATOR,
                parentPath,
                filter: VOLUME_FOLIO_FILTER.volumeFolio[keys](parentPath, paramValue[keys]),
            };
            volumeFolioSearchFilters.push(outerFilter);
        }
    });

    return volumeFolioSearchFilters;
};

const tenureFilterBuilder = ({ paramValue }) => {
    let propertyType = paramValue?.type?.split(',');
    if (!paramValue.type) {
        propertyType = RAPID_PROPERTY_TYPE_V2_TENURE.map(e => e.value);
    }

    const values = {
        [isAU ? 'house' : 'residential']: paramValue?.holdPeriodHouse,
        unit: paramValue?.holdPeriodUnit,
    };

    const emptyFields = SearchFilterHelper.getEmptyPropertyTypes(paramValue);

    return {
        operation: OR_OPERATOR,
        filters: propertyType.reduce((acc, curr) => {
            const currentType = curr.toLowerCase();
            if (!emptyFields.find(e => e.toLowerCase() === currentType)) {
                acc.push(tenureDateRangeBuilder({
                    paramValue: values[currentType],
                    type: currentType,
                }));
            }
            return acc;
        }, []),
    }
};

const isGreaterThanOrEqual = ({ param, paramValue }) =>
    buildFilter(param, GREATER_THAN_OPERATOR, paramValue);
const isOperation = ({ param, paramValue }) => buildFilter(param, IS_OPERATOR, paramValue);

// This converts the page limit into 10 properties per page and build start and end page accordingly
// regardless of the page limit selected, i.e. 20, 30, 40 or 50.
// The reason behind this is 10 is the common factor
// that we could get exactly 10k properties including the initially displayed ones
const getBatchStartPage = (currentOffset, limit) =>
    ((currentOffset + 1) * limit) / MAX_PROPERTIES_PER_PAGE;

const getBatchEndPage = (totalElementCount) => {
    const initTotalPage = Math.trunc(totalElementCount / MAX_PROPERTIES_PER_PAGE)
    return initTotalPage > MAX_PAGE_PER_BATCH ? MAX_PAGE_PER_BATCH : initTotalPage;
};

export default {
    defaultBuilder,
    resultFormatBuilder,
    buildWithdrawnCompare,
    keywordsFilterBuilder,
    resultFormatSortBuilder,
    addressCollectiveBuilder,
    exactAddressBuilder,
    isActiveFilterBuilder,
    rangeFilterBuilder,
    typeFilterBuilder,
    commaConcatenatedToJsonBuilder,
    dateRangeFilterBuilder,
    withdrawnAndNotDateRangeFilterBuilder,
    isActiveListingsFilterBuilder,
    radiusFormatBuilder,
    searchFieldFilterBuilder,
    buildingNameFilterBuilder,
    companyNameFilterBuilder,
    startsWithFilterBuilder,
    volumeFolioFilterBuilder,
    containFilterBuilder,
    checkboxFilterBuilder,
    buildFilter,
    isGreaterThanOrEqual,
    isOperation,
    withdrawnDateRangeFilterBuilder,
    tenureFilterBuilder,
    tenureDateRangeBuilder,
    addressContainsBuilder,
    getBatchStartPage,
    getBatchEndPage,
    territoryFormatBuilder,
    rentalOccupancyFilterBuilder,
    excludePropertyBuilder,
};
