export default {
    RP_PROPOSALS_REPORT: 'rp.proposals.report',
    NEARMAP: 'nearmap.type',
    PROPERTY_INVESTMENT_REPORT: 'crux-property-investment-report',
    DIGITAL_PROFILE_REPORT: 'crux.digital.profile.report',
    FORMS_INTEGRATION: 'crux-rei-forms',
    NZ_MAP_LAYER_FLOOD: 'pg.flood.layer',
    SEARCH_RESULT_MAP_VIEW: 'search.result.map.view',
    REINZ_MEMBERSHIP: 'crux-reinz-member',
};
