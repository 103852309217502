import { select, takeLatest, put, call } from 'redux-saga/effects';
import MapApi from '../api/map';
import {
    AUCKLAND_FLOOD_GEOJSON,
    NEARMAP_COVERAGE,
    NEARMAP_COVERAGE_FAIL,
    NEARMAP_COVERAGE_SUCCESS,
    setSelectedNearmapCoverage,
    getAucklandFloodGeoJsonSuccess,
} from '../actions/map';
import SagaHelper from '../sagas/helper';
import Bugsnag from '../bugsnag';
import GeoJsonUtil from '../components/property/map/util/GeoJsonUtil';

function getNearmapCoverageGenerator() {
    return function* (action) {
        try {
            const {
                payload: {
                    bounds: {
                        north, east, west, south,
                    },
                },
            } = action;
            const polygon = `${west},${north},${east},${north},${east},${south},${west},${south},${west},${north}`;
            const clUsrId = yield select(SagaHelper.getClUsrId);
            const coverage = yield call(MapApi.Nearmap.getCoverage, clUsrId, polygon);
            yield put({
                type: NEARMAP_COVERAGE_SUCCESS,
                payload: {
                    coverage,
                },
            });
        } catch (error) {
            Bugsnag.notify(new Error(error.message));
            yield put({
                type: NEARMAP_COVERAGE_FAIL,
            });
        }
    };
}

function getAucklandFloodGeoJsonGenerator() {
    return function* (action) {
        const {
            payload: {
                bounds,
                signal,
                zoomLevel = 18,
                isDisabled = false,
            },
        } = action;
        if (isDisabled || zoomLevel <= 10) {
            yield put(getAucklandFloodGeoJsonSuccess(null));
        } else {
            try {
                const geometryPrecision = GeoJsonUtil.getGeometryPrecisionByZoomLevel(zoomLevel);
                const geoJson = yield call(MapApi.getAucklandFloodLayerGeoJson, bounds, geometryPrecision, signal);
                const geoJsonWithFeatureIds = GeoJsonUtil.getGeoJsonWithFeatureIds(geoJson);
                yield put(getAucklandFloodGeoJsonSuccess(geoJsonWithFeatureIds));
            } catch (error) {
                Bugsnag.notify(new Error(error.message));
            }
        }
    };
}

export const getSelectedNearmapCoverage = state => state.map.get('nearmap').selectedCoverage;
function setInitialSelectedNearmapCoverageGenerator() {
    return function* (action) {
        try {
            const { payload: { coverage } } = action;
            const selectedCoverage = yield select(getSelectedNearmapCoverage);
            const coverageFromList = selectedCoverage ?
                coverage.surveys.find(survey => survey.id === selectedCoverage.id) : null;
            if (!coverageFromList) {
                const latestCoverage = coverage && coverage.surveys && coverage.surveys.length ?
                    coverage.surveys[0] : null;
                if (latestCoverage) {
                    yield put(setSelectedNearmapCoverage(latestCoverage));
                }
            }
        } catch (error) {
            Bugsnag.notify(new Error(error.message));
        }
    };
}

export const getNearmapCoverage = getNearmapCoverageGenerator();
export const getAucklandFloodGeoJson = getAucklandFloodGeoJsonGenerator();
export const setInitialSelectedNearmapCoverage = setInitialSelectedNearmapCoverageGenerator();

function* getNearmapCoverageWatcher() {
    yield takeLatest(NEARMAP_COVERAGE, getNearmapCoverage);
}

function* getAucklandFloodGeoJsonWatcher() {
    yield takeLatest(AUCKLAND_FLOOD_GEOJSON, getAucklandFloodGeoJson);
}

function* setInitialSelectedNearmapCoverageWatcher() {
    yield takeLatest(NEARMAP_COVERAGE_SUCCESS, setInitialSelectedNearmapCoverage);
}

export default [
    getNearmapCoverageWatcher(),
    getAucklandFloodGeoJsonWatcher(),
    setInitialSelectedNearmapCoverageWatcher(),
];
