import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PDFViewer from 'js/components/reports/PDFViewer';
import { getIntellivalAvmReport } from 'js/actions/pdfReports';
import { trackIntellivalAvmReport } from 'js/actions/segment';
import Reports from 'js/constants/reports';
import { entryPoints } from 'js/constants/entryPoint';

/**
 * Use <Valuation /> component instead.
 * See source/js/components/common/Valuation.jsx
 */
export default class ValuationReport extends Component {
    static propTypes = {
        propertyId: PropTypes.number,
        propertyAddress: PropTypes.string,
        pageContext: PropTypes.string,
        dispatch: PropTypes.func,
        valuationId: PropTypes.number,
        trigger: PropTypes.object,
        entryPoint: PropTypes.string,
    };

    static defaultProps = {
        entryPoint: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            filename: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.propertyId !== prevState.id) {
            const dateNow = moment().format('DD-MM-YYYY');
            return {
                id: nextProps.propertyId,
                filename: `Valuation_Estimate_AVM_${nextProps.propertyAddress && nextProps.propertyAddress.replace(/\s/g, '_')}_${dateNow}`,
            };
        }
        return null;
    }

    handler = () => {
        const {
            dispatch, pageContext, entryPoint,
        } = this.props;
        const { id } = this.state;

        const payload = {
            pageContext,
            propertyId: id,
            reportType: Reports.INTELLIVAL_AVM_REPORT,
            entryPoint,
        };

        dispatch(trackIntellivalAvmReport(payload));
        dispatch(getIntellivalAvmReport(id));
    };

    render() {
        const { entryPoint, propertyId } = this.props;
        const finalEntryPoint = (entryPoint !== entryPoints.REPORTS_MENU && entryPoint !== entryPoints.VALUATION_ESTIMATE_PANEL) ? entryPoint : null;
        return (
            <PDFViewer
                propertyId={propertyId}
                trigger={this.props.trigger}
                title={this.props.propertyAddress}
                triggerHandler={this.handler}
                reportType={Reports.INTELLIVAL_AVM_REPORT}
                filename={this.state.filename}
                entryPoint={finalEntryPoint}
            />
        );
    }
}
