import React from 'react';
import PropTypes from 'prop-types';
import useRedirection from '../../../hooks/multiLocality/useRedirection';
import { cruxLoader, cruxUnloader } from '../../../actions/crux';
import Clapi from '../../../api/clapi';
import SearchResultsFunctions from '../../../helpers/search/SearchResultsFunctions';

const DisplayText = (props) => {
    const redirect = useRedirection({
        searches: [{
            suggestion: props.suggestion,
            suggestionType: props.suggestionType,
        }],
        statusType: 'all',
        isHistoric: false,
        entryPoint: props.entryPoint,
    });
    const handleOnClick = () => {
        props.dispatch(cruxLoader());
        let localityHasAverageHoldPeriod;
        let averageHoldPeriod = {};
        Clapi.getStatistics({ searchString: props.suggestion, suggestionType: props.suggestionType }, 'averageHoldTrends').then((response) => {
            localityHasAverageHoldPeriod = SearchResultsFunctions.hasAverageHoldTrend(response);
            if (localityHasAverageHoldPeriod) {
                const holdPeriod = response?.seriesResponseList || [];
                averageHoldPeriod.house = holdPeriod.length
                    && Math.round(holdPeriod[0]?.seriesDataList[0]?.value);
                averageHoldPeriod.unit = holdPeriod.length
                    && Math.round(holdPeriod[1]?.seriesDataList[0]?.value);
            }
        }).finally(() => {
            props.dispatch(cruxUnloader());
            if (localityHasAverageHoldPeriod) {
                redirect(averageHoldPeriod);
            } else {
                redirect();
            }
        });
    }
    return (
        <a onClick={handleOnClick}>
            {props.displayText}
        </a>
    );
}

DisplayText.propTypes = {
    suggestion: PropTypes.string,
    suggestionType: PropTypes.string,
    entryPoint: PropTypes.string,
    displayText: PropTypes.string,
    dispatch: PropTypes.func,
};

export default DisplayText;