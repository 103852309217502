import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import TOOLTIP, { UNSUBSCRIBED } from '../../constants/tooltipText';
import { HISTORIC } from '../../constants/propertyDetail';
import { getAssetDomain } from '../../constants/assets';
import ReportHelper from '../../helpers/report/ReportHelper';
import ButtonWithTooltip from '../common/ButtonWithTooltip';
import CruxSnackBar from '../common/CruxSnackBar';
import Report from '../../constants/reports';
import {
    goToSignatureReport,
    removeSignatureError,
} from '../../actions/signature';
import { SIGNATURE_REPORT_COLLECTION_SUB_TYPE } from './ReportCollection';

export const SIGNATURE_SUB_TYPE = 'pdp-cma';

export const PropertyCMA = ({
    userSummaryApiSuccess, clapiUsrDetail, isPropertyExist,
    isHistoricProperty, propertyId, pageContext,
    dispatch, isFetching, errorMessage, signatureSubType,
}) => {
    const reportRoles = ReportHelper.getReportRoles(clapiUsrDetail);
    const isEntitled = reportRoles?.includes('Property CMA');
    const isDisabled = !isEntitled || !isPropertyExist || isHistoricProperty;

    let TOOLTIP_MESSAGE;
    if (!isEntitled) {
        TOOLTIP_MESSAGE = UNSUBSCRIBED;
    } else if (isHistoricProperty) {
        TOOLTIP_MESSAGE = HISTORIC.FEATURE_NOT_AVAILABLE;
    } else {
        TOOLTIP_MESSAGE = TOOLTIP.TEXT.MENU.UNAVAILABLE;
    }

    const handleClick = () => {
        if (!isFetching) {
            dispatch(goToSignatureReport(
                propertyId,
                Report.SIGNATURE_REPORT_TYPES.CMA,
                SIGNATURE_SUB_TYPE,
                null,
                pageContext,
            ));
        }
    };

    return userSummaryApiSuccess && (
        <Fragment>
            <ButtonWithTooltip
                className="cma-report-button"
                onClick={handleClick}
                tooltipMessage={TOOLTIP_MESSAGE}
                tooltipPosition="bottom"
                tooltipId="cma-report-button-tooltip"
                isDisabled={isDisabled}
                displayTooltip={isDisabled}
                color="secondary"
                sx={{  marginLeft: '8px' }}
                startIcon={
                    <img
                        src={getAssetDomain('cma-white@2x.png')}
                        width="20px"
                        alt="print-icon"
                    />
                }
            >
                <span>CMA</span>
                {
                    signatureSubType === SIGNATURE_SUB_TYPE &&
                    isFetching &&
                    <div data-testid="cma-loader" className="cma-loader">
                        <CircularProgress
                            size={20}
                            thickness={5}
                        />
                    </div>
                }
            </ButtonWithTooltip>
            <CruxSnackBar
                open={
                    !!errorMessage &&
                    [SIGNATURE_SUB_TYPE, SIGNATURE_REPORT_COLLECTION_SUB_TYPE]
                        .includes(signatureSubType)
                }
                onClose={() => dispatch(removeSignatureError())}
                severity="error"
                message={errorMessage}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />
        </Fragment>
    )
};

PropertyCMA.propTypes = {
    userSummaryApiSuccess: PropTypes.bool,
    isPropertyExist: PropTypes.bool,
    isHistoricProperty: PropTypes.bool,
    clapiUsrDetail: PropTypes.object,
    propertyId: PropTypes.number,
    pageContext: PropTypes.string,
    dispatch: PropTypes.func,
    isFetching: PropTypes.bool,
    errorMessage: PropTypes.string,
    signatureSubType: PropTypes.string,
};

export default connect(state => ({
    isFetching: state.signature.get('isFetching'),
    errorMessage: state.signature.get('errorMessage'),
    signatureSubType: state.signature.get('subType'),
}))(PropertyCMA);
