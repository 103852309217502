import { isAU } from 'js/constants/crux';
import { STATE } from 'js/constants/states';

const getSingleLineAddress = (address) => address?.singleLine || address?.street?.singleLine || '';
const getLocallyFormattedAddress = address => address?.locallyFormattedAddress || '';
const getAddress = (address) => {
    if (isAU) {
        return getSingleLineAddress(address);
    }
    return getLocallyFormattedAddress(address);
};

const isVictoria = (addressString = '') => {
    const suggestions = addressString.split('|');
    return suggestions
        ?.some(suggestion => suggestion
            .toLowerCase()
            .split(' ')
            .includes(STATE.VIC));
};

const excludeUnit = (address) => {
    const tokens = address.split('/');
    if (tokens?.length > 1) {
        return tokens[1].replace(/\s/g, '').toLowerCase();
    }
    return address.replace(/\s/g, '').toLowerCase();
};

const getLocalityName = (property) => property.addressSuburb || property.addressTown;

export default {
    get: getAddress,
    getSingleLine: getSingleLineAddress,
    getLocallyFormatted: getLocallyFormattedAddress,
    isVictoria,
    excludeUnit,
    getLocalityName,
}
