import { Map as IMap } from 'immutable';
import * as Action from '../actions/reinz';
export const initialState = () => IMap({
    reinz: {
        isMember: null,
        error: false,
    },
    integration: {
        connectStatus: null,
        cancelStatus: null,
    },
});
export default function reducer(state = initialState(), action = {}) {
    switch (action.type) {
        case Action.SYNC_MEMBERSHIP_STATUS_SUCCESS: {
            return state.mergeDeep(IMap({
                reinz: {
                    isMember: action.payload.isMember,
                },
            }));
        }
        case Action.SYNC_MEMBERSHIP_STATUS_FAIL: {
            return state.mergeDeep(IMap({
                reinz: {
                    error: true,
                },
            }));
        }
        case Action.CONNECT_REINZ_SUCCESS: {
            return state.mergeDeep(IMap({
                reinz: {
                    isMember: action.payload.isMember,
                },
            }));
        }
        case Action.CANCEL_REINZ_SUCCESS: {
            return state.mergeDeep(IMap({
                reinz: {
                    isMember: action.payload.isMember,
                },
            }));
        }
        case Action.SET_REINZ_INTEGRATION_STATUS: {
            return state.mergeDeep(IMap({
                integration: {
                    ...state.get('integration'),
                    ...action.payload,
                },
            }));
        }
        default:
            return state;
    }
}