import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { routeCodes } from 'js/constants/routes';
import { clearSuggestion, setSearchStatusType } from 'js/actions/clapi';
import { invalidateCruxAppError } from 'js/actions/errorHandler';
import Commons from 'js/helpers/Commons';
import OTM from 'js/constants/otm';
import { isAU } from 'js/constants/crux';
import SuggestionTypes from 'js/constants/suggestionTypes';
import { getSuggestionTypeId } from '../../constants/msg/suggestionType';
import { saveRecentAddressSearches } from '../../actions/msgApi';
import SEGMENT from '../../constants/segment';

const useRedirection = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleAverageHoldPeriod = (holdPeriod, searchParams) => {
        const averageHold = Commons.minAndMaxToRange({
            min: holdPeriod.house,
            max: holdPeriod.unit,
        });
        if (averageHold) {
            searchParams.set('averageHoldPeriod', averageHold);
        }
    }

    const handleHistoric = (isHistoric, searchParams) => {
        if (isHistoric) searchParams.set('active', 'false');
    };

    const handleStatusType = (statusType, searchParams) => {
        if (statusType !== OTM.ALL) searchParams.set('statusType', statusType)
    };

    const buildSuggestions = () => props.searches.map(search => search.suggestion).join('|');

    const buildUrlParams = (holdPeriod) => {
        const searchParams = new URLSearchParams()
        searchParams.set('sType', props.searches[0].suggestionType);
        searchParams.set('suggestions', buildSuggestions());

        handleHistoric(props.isHistoric, searchParams);
        handleStatusType(props.statusType, searchParams);

        handleAverageHoldPeriod(holdPeriod, searchParams);

        return searchParams.toString();
    };

    return (extraStateParams = {}) => {
        const routePath = {
            pathname:  routeCodes.MULTI_LOCALITY_SEARCH.path,
            search: buildUrlParams(extraStateParams),
        };
        dispatch(setSearchStatusType(props.statusType));
        history.push(routePath, { entryPoint: SEGMENT.EVENT_PROPERTIES.SEARCH });

        props.trackSearchEvent?.();
        dispatch(clearSuggestion());
        dispatch(invalidateCruxAppError());

        // Save recent searches if applicable
        const suggestions = buildSuggestions();
        const suggestionType = props.searches?.[0]?.suggestionType;

        const shouldSaveRecentSearch = isAU || suggestionType !== SuggestionTypes.POSTCODE;
        if (suggestions && shouldSaveRecentSearch) {
            dispatch(saveRecentAddressSearches(suggestions, getSuggestionTypeId(suggestionType)));
        }

    }
};

useRedirection.propTypes = {
    searches: PropTypes.array,
    isHistoric: PropTypes.bool,
    statusType: PropTypes.string,
    entryPoint: PropTypes.string,
    trackSearchEvent: PropTypes.func,
};

export default useRedirection;
