export const RECENT_SEARCH_START = 'RECENT_SEARCH_START';
export const RECENT_SEARCH_END = 'RECENT_SEARCH_END';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const SET_SELECTED_PROPERTY_FROM_DYNAMIC_BUBBLE = 'SET_SELECTED_PROPERTY_FROM_DYNAMIC_BUBBLE';
export const GET_PROPERTY_BY_COORDINATES = 'GET_PROPERTY_BY_COORDINATES';
export const GET_PROPERTY_BY_COORDINATES_SUCCESS = 'GET_PROPERTY_BY_COORDINATES_SUCCESS';
export const GET_PROPERTY_BY_COORDINATES_FAILED = 'GET_PROPERTY_BY_COORDINATES_FAILED';
export const HANDLE_DYNAMIC_PROPERTY_SELECTED = 'HANDLE_DYNAMIC_PROPERTY_SELECTED';
export const startRecentSearchProgress = () => {
    return {
        type: RECENT_SEARCH_START,
    };
}

export const handleDynamicPropertySelected = (payload) => {
    return {
        type: HANDLE_DYNAMIC_PROPERTY_SELECTED,
        payload,
    };
}

export const endRecentSearchProgress = () => {
    return {
        type: RECENT_SEARCH_END,
    };
}

export const setSelectedProperty = (details) => ({
    type: SET_SELECTED_PROPERTY,
    payload: details,
});

export const setSelectedPropertyFromDynamicBubble = (id) => ({
    type: SET_SELECTED_PROPERTY_FROM_DYNAMIC_BUBBLE,
    payload: id,
});

export const setDynamicProperty = (coordinates, mixpanel, isRadiusSearch = false) => ({
    type: GET_PROPERTY_BY_COORDINATES,
    payload: { coordinates, mixpanel , isRadiusSearch},
});
