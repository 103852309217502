import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AutoSuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { styled } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { createEventSuggestion, trackEvent } from 'js/actions/segment';
import {
    getParcelSuggestions, clearParcelSuggestion, getLegalSuggestions, clearLegalSuggestion,
} from 'js/actions/clapi';
import { routeCodes } from 'js/constants/routes';
import Commons from 'js/helpers/Commons';
import { cruxAppError, invalidateCruxAppError } from 'js/actions/errorHandler';
import { isAU, isNZ } from 'js/constants/crux';
import { getAssetDomain } from 'js/constants/assets';
import Tooltip from 'js/components/common/Tooltip';
import SuggestionHighlighter from 'js/components/common/SuggestionHighlighter';
import SuggestionTypes from 'js/constants/suggestionTypes';
import Parcels from 'js/helpers/Parcels';
import { NO_RECENT_SEARCH } from 'js/constants/slas';
import {
    saveRecentParcelSearches,
    saveRecentTitleSearches,
    removeRecentParcelSearch,
    removeRecentTitleSearch,
    resetHandler
} from 'js/actions/msgApi';
import ParcelAndLegalDescriptionSearchParamsBuilders
    from 'js/helpers/nameSearch/ParcelAndLegalDescriptionSearchParamsBuilders';
import Clapi from 'js/api/clapi';
import ErrorMsg from 'js/constants/errorMsg';
import { ErrorType } from 'js/constants/redirect';
import { endRecentSearchProgress, startRecentSearchProgress } from 'js/actions/search';
import Colors from 'js/constants/colors';
import CruxDialogBox from '../../../common/modals/CruxDialogBox';
import Segment from 'js/helpers/Segment';
import ListPropertyPreview from 'js/constants/listPropertyPreview';
import CruxSnackBar from 'js/components/common/CruxSnackBar';
import UrlParamsExtractor from 'js/helpers/search/UrlParamsExtractor';
import RapidSearch from 'js/constants/rapidSearch';
import SEGMENT from 'js/constants/segment';

const { LEGAL_DESC, TITLE_REF } = SuggestionTypes;

const StyledHeader = styled('div')({
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginRight: '20px',
});

const SuggestionHeaderText = styled('h3')({
    fontSize: '12px',
    fontWeight: '400',
    color: Colors.BLACK_60_OPAQUE,
    letterSpacing: '1px',
    marginLeft: '16px',
    textTransform: 'uppercase',
});

const StyledButton = styled('button')({
    position: 'relative',
    border: 'none',
    background: 'transparent',
    marginRight: '7px',
    marginTop: '10px',
    '& svg': {
        color: Colors.BLACK_54_OPAQUE,
    },
})

export class ParcelSearch extends Component {
    static propTypes = {
        styles: PropTypes.object,
        usrDetail: PropTypes.object,
        location: PropTypes.object,
        suggestions: PropTypes.array,
        legalSuggestions: PropTypes.array,
        route: PropTypes.object.isRequired,
        entryPoint: PropTypes.string.isRequired,
        dispatch: PropTypes.func,
        recentSearches: PropTypes.array,
        suggestionType: PropTypes.string,
        placeholder: PropTypes.string,
        routePathname: PropTypes.string,
        routeSearch: PropTypes.string,
        handler: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            keyword: '',
            hasRecentSearch: true,
            isFocused: false,
            locationKey: null,
            openDeleteDialog: false,
        };
    }

    componentDidMount() {
        const { location: { search } = {}, suggestionType } = this.props;
        if (search) {
            const params = ParcelAndLegalDescriptionSearchParamsBuilders(search);
            if (!Commons.isObjectEmpty(params)) {
                const key = suggestionType === LEGAL_DESC ? 'legalDescription' : 'titleReference';
                this.setState({
                    keyword: params[isAU ? 'addressParcelSuburbState' : key] || '',
                });
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.location.key !== prevState.locationKey) {
             const params = UrlParamsExtractor
                .parseUrlParams(nextProps.location?.search, nextProps.searchType) || {};
            return {
                locationKey: nextProps.location.key,
                keyword: params?.[RapidSearch.BASE_FILTER_BY_SEARCH_TYPE[nextProps.searchType]] || '',
            };
        }
        return null;
    }

    onSuggestionSelected = (event, { suggestionValue, method, suggestion }) => {
        const {
            history, clUsrId, recentSearches, dispatch, suggestionType,
        } = this.props;
        const { hasRecentSearch } = this.state;

        const _suggestionTypes = isAU ? 'Parcel' : suggestionType;

        if (_suggestionTypes) {
            this.setState({ isFocused: false });

            event.preventDefault();
            if (hasRecentSearch) {
                if (recentSearches.length === 0) return;
                let apiMethod;
                if (suggestionType === TITLE_REF) {
                    apiMethod = Clapi.getTitleRefSuggestionByClUserId;
                } else {
                    apiMethod = isAU ? Clapi.getParcelSuggestionByClUserId : Clapi.getLegalSuggestionByClUserId;
                }

                dispatch(startRecentSearchProgress());
                apiMethod(clUsrId, suggestionValue)
                    .then((data) => {
                        const [first] = data.suggestions.filter(e => e.suggestion === suggestionValue);
                        let propertyIds;
                        if (first) {
                            propertyIds = first.propertyIds;
                        }

                        dispatch(endRecentSearchProgress());
                        const path = this.getRoute({ ...suggestion, propertyIds });
                        const goToPdp = path?.pathname.includes('property');
                        this.finishParcel('Recent', _suggestionTypes, suggestionValue, 'Parcel', goToPdp);
                        history.push(path, { entryPoint: SEGMENT.EVENT_PROPERTIES.SEARCH });
                    })
                    .catch(() => {
                        dispatch(cruxAppError(ErrorMsg.FAILED_TO_FETCH, ErrorType.PAGE_ERROR));
                    });
            } else {
                const path = this.getRoute(suggestion);
                const goToPdp = path?.pathname.includes('property');
                this.finishParcel('Typed', _suggestionTypes, suggestionValue, 'Parcel', goToPdp);
                history.push(path, { entryPoint: SEGMENT.EVENT_PROPERTIES.SEARCH });
            }
        }
    };

    onBlur = () => {
        // clear suggestion list if available
        const { dispatch, suggestions, legalSuggestions } = this.props;
        const { hasRecentSearch } = this.state;
        if (isAU && (suggestions.length > 0 || (suggestions.length === 0 && hasRecentSearch === false))) {
            dispatch(clearParcelSuggestion());
        } else if (isNZ && (legalSuggestions.length > 0 || (legalSuggestions.length === 0 && hasRecentSearch === false))) {
            dispatch(clearLegalSuggestion());
        }
        this.setState({ keyword: '', isFocused: false, hasRecentSearch: true });
    };

    getSuggestionList() {
        const {
            suggestions = [],
            legalSuggestions = [],
            recentSearches,
        } = this.props;
        const { hasRecentSearch } = this.state;
        let items = [];
        if (suggestions.length > 0 || legalSuggestions.length > 0) {
            items = isAU ? suggestions : legalSuggestions;
        } else if (hasRecentSearch && recentSearches.length > 0) {
            items = recentSearches.slice(0, 10);
        } else if (hasRecentSearch && recentSearches.length === 0) {
            items = NO_RECENT_SEARCH;
        }
        return items;
    }

    getRoute(suggestion) {
        const { entryPoint, routePathname, routeSearch } = this.props;
        const {
            propertyIds = [],
            searchString: recentSearchText,
            suggestion: suggestText,
        } = suggestion;
        const _suggestion = recentSearchText || suggestText;
        const formattedSuggest = _suggestion.toLowerCase().replace(/ /g, '-');
        let pathname = '';
        const encodedSuggestion = encodeURIComponent(_suggestion);

        if (propertyIds.length === 1) {
            pathname = routeCodes.PROPERTY.path(formattedSuggest, propertyIds[0]);
            return {
                pathname,
                state: {
                    entryPoint,
                },
            };
        }

        return {
            pathname: routePathname,
            search: routeSearch(encodedSuggestion),
        };
    }

    getSuggestionValue = suggestion => (suggestion.searchString || suggestion.suggestion);

    handleInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault();
            event.currentTarget.blur();
        }
    };

    clearSuggestions = () => {
        const { dispatch } = this.props;
        if (isAU) {
            dispatch(clearParcelSuggestion());
        } else {
            dispatch(clearLegalSuggestion());
        }
    }

    handleInputTextChange = (event, { newValue, method }) => {
        const { dispatch, suggestionType } = this.props;
        const val = newValue.trim();
        if (method === 'type') {
            let displayRecentSearch = true;
            if (val.length < 3 || Commons.hasBackSlash(val)) {
                this.clearSuggestions();
            } else {
                if (isAU) {
                    dispatch(getParcelSuggestions(val));
                } else {
                    dispatch(getLegalSuggestions(val, suggestionType));
                }
                displayRecentSearch = false;
            }
            this.setState({ keyword: newValue, hasRecentSearch: displayRecentSearch });
        } else {
            this.setState({ keyword: newValue });
        }
    };

    finishParcel = (entryPoint, searchContext, searchString, searchType, goToPdp) => {
        // * Entry Point: Recent, Typed
        // * Search Context: e.g. Parcel
        // * Search String:{Search string}
        // * Search Type: Parcel
        const {
            dispatch,
            route: { pageContext },
            suggestions = [],
            legalSuggestions = [],
            suggestionType,
            activeView,
        } = this.props;
        const { hasRecentSearch } = this.state;
        let _searchContext = searchContext;
        let _searchType = searchType;
        // suggestions will only be 0 when link is clicked before GET_SUGGESTION_SUCCESS
        if ((isAU && suggestions.length) || (isNZ && legalSuggestions.length) || hasRecentSearch) {
            if (searchContext === LEGAL_DESC || searchContext === 'legal') {
                _searchContext = 'Legal';
                _searchType = 'Legal';
            } else if (searchContext === TITLE_REF) {
                _searchContext = 'Title';
                _searchType = 'Title';
            } else {
                _searchContext = 'Parcel';
            }

            dispatch(createEventSuggestion(
                pageContext,
                entryPoint,
                _searchContext,
                searchString,
                _searchType,
                false,
                false,
                null,
                !goToPdp && activeView,
            ));
        }
        this.clearSuggestions();
        dispatch(invalidateCruxAppError());
        if (suggestionType === TITLE_REF) {
            dispatch(saveRecentTitleSearches(searchString, ''));
        } else {
            dispatch(saveRecentParcelSearches(searchString, isAU ? 'lotPlan' : ''));
        }
    };

    handleInputFocus = () => {
        this.setState({
            isFocused: true,
        });
    };

    clearRecentSearchesMixpanel = () => {
        const {
            dispatch,
            route: { pageContext },
            suggestionType,
        } = this.props;
        const _suggestionType = isAU ? 'parcel'
            : (suggestionType === 'legalDesc' ? 'legal description' : 'title');
        dispatch(trackEvent(Segment.removeRecentSearch({
            pageContext: pageContext,
            objectContext: ListPropertyPreview.MIXPANEL_SEARCH_TYPE[_suggestionType],
        })));
    };

    handleDeleteDialogOpen = (event) => {
        event.preventDefault();
        this.setState({ openDeleteDialog: true });
    }

    handleDeleteDialogClose = () => {
        this.setState({ openDeleteDialog: false });
    };

    handleRemoveRecentSearches = () => {
        const { suggestionType, dispatch } = this.props;
        this.setState({ openDeleteDialog: false });
        isNZ && suggestionType !== LEGAL_DESC ? dispatch(removeRecentTitleSearch('save-title'))
            : dispatch(removeRecentParcelSearch('parcel'));
        this.clearRecentSearchesMixpanel();
    };

    renderSuggestion = (suggestion, { query }) => {
        const { recentSearches, suggestionType } = this.props;
        const { hasRecentSearch } = this.state;
        const {
            searchString: recentSearchText, suggestion: suggestText,
        } = suggestion;
        const _suggestion = recentSearchText || suggestText;
        const matches = match(_suggestion, query);
        const parts = parse(_suggestion, matches);

        const isRecentSearchEmpty = hasRecentSearch && recentSearches.length === 0;
        const tooltipId = `suggestion-type-tooltip-${_suggestion.toLowerCase().replace(/ /g, '-')}`;
        return (
            <Fragment>
                {
                    !isRecentSearchEmpty && (
                        <div className="suggestion-type-icon">
                            <Tooltip
                                tooltipTxt={Parcels.suggestionTypeTooltip[suggestionType]}
                                tooltipId={tooltipId}
                                tooltipClass="tooltip-suggestion-type"
                                tooltipPosition="bottom"
                            >
                                <img
                                    className="img-responsive"
                                    src={getAssetDomain('parcel.svg')}
                                    alt={`${suggestionType}-icon`}
                                    data-for={tooltipId}
                                />
                            </Tooltip>
                        </div>
                    )
                }
                {
                    !isRecentSearchEmpty ? (
                        <a title={_suggestion} className="slas-suggestion" href="javascript:;">
                            <SuggestionHighlighter parts={parts} className="slas-match-suggestion" />
                        </a>
                    ) : (
                        <div title={_suggestion}>
                            <SuggestionHighlighter parts={parts} className="slas-match-suggestion" />
                        </div>
                    )
                }
            </Fragment>
        );
    };

    renderSuggestionsContainer = ({ containerProps, children }) => {
        const { suggestions, legalSuggestions, recentSearches } = this.props;
        const _suggestions = isAU ? suggestions : legalSuggestions;
        const dividerText = !_suggestions.length ? 'Recent Searches' : 'Suggestions';

        if (this.state.keyword?.length >= 3 && !_suggestions.length) {
            return null;
        }

        return (
            <div {...containerProps}>
                <StyledHeader>
                    <SuggestionHeaderText>{ dividerText }</SuggestionHeaderText>
                    {
                        (this.state.keyword?.length < 3 && recentSearches.length && !_suggestions.length) ?
                            <StyledButton onMouseDown={this.handleDeleteDialogOpen}>
                                <DeleteOutlineIcon sx={{ fill: Colors.STAR_DUST }} />
                            </StyledButton> : ''
                    }
                </StyledHeader>
                {children}
            </div>
        );
    };

    render() {
        const { isFocused, keyword, openDeleteDialog } = this.state;
        const { placeholder, handler, dispatch } = this.props;
        const inputProps = {
            value: keyword,
            type: 'search',
            onChange: this.handleInputTextChange,
            onKeyDown: this.handleInputKeyDown,
            onFocus: this.handleInputFocus,
            onBlur: this.onBlur,
            className: 'form-control',
            placeholder,
        };
        const suggestionList = this.getSuggestionList();
        const handlerMessage = Commons.get(handler, 'message');
        return (
            <div className={`search-inputs${isNZ ? ' nz' : ''}`}>
                <AutoSuggest
                    suggestions={suggestionList}
                    onSuggestionsFetchRequested={() => {/* do nothing */}}
                    onSuggestionsClearRequested={() => {/* do nothing */}}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    shouldRenderSuggestions={() => true}
                    inputProps={inputProps}
                    onSuggestionSelected={this.onSuggestionSelected}
                    focusInputOnSuggestionClick={false}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    theme={{
                        container: `form-control slas-keyword${isFocused ? ' slas-focused' : ''}`,
                        suggestionsContainer: `slas-suggestions${isFocused ? '' : ' hide'}`,
                        suggestion: 'slas-suggestion',
                        suggestionHighlighted: 'slas-suggestion-highlight',
                        containerOpen: 'slas-keyword-open',
                    }}
                />
                <CruxDialogBox
                    open={openDeleteDialog}
                    id="remove-recent-search-modal"
                    title="Are you sure you want to remove all recent searches for this search type?"
                    subTitle="This will remove all recent searches and cannot be undone."
                    onClose={this.handleDeleteDialogClose}
                    onConfirm={this.handleRemoveRecentSearches}
                />
                <CruxSnackBar
                    open={handlerMessage && !!handler.message}
                    onClose={() => dispatch(resetHandler())}
                    severity='error'
                    message={handlerMessage && handler.message}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    usrDetail: state.clapi.get('usrDetail'),
    suggestions: state.clapi.get('parcelSuggestions'),
    legalSuggestions: state.clapi.get('legalSuggestions'),
    isRecent: state.clapi.get('isRecent'),
    recentSearches: state.msg.get('recentParcelSearches'),
    clUsrId: state.claud.get('session')?.clusrId,
    handler: state.msg.get('handler'),
    activeView: state.searchResults.get('activeView'),
});
export default withRouter(connect(mapStateToProps)(ParcelSearch));

