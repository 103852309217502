import { isAU } from './crux';
import professional from '../../assets/img/ssr/professional.png';
import clerical from '../../assets/img/ssr/clerical.png';
import trades from '../../assets/img/ssr/trades.png';
import labourer from '../../assets/img/ssr/labourer.png';
import notStated from '../../assets/img/ssr/not-stated.png';
import machinery from '../../assets/img/ssr/machinery.png';
import managers from '../../assets/img/ssr/managers.png';
import communityPersonal from '../../assets/img/ssr/community-personal.png';
import salesWorker from '../../assets/img/ssr/sales-worker.png';
import legislators from '../../assets/img/ssr/legislators.png';
import agriculturalAndFisheries from '../../assets/img/ssr/agricultural-and-fisheries.png';
import elementary from '../../assets/img/ssr/elementary.png';
import technicians from '../../assets/img/ssr/technicians.png';
import serviceAndSales from '../../assets/img/ssr/service.png';

const HEADER_OPTIONS = {
    id: 'ssrHeader',
    name: 'SSR Header',
    items: [
        {
            name: 'Header',
            type: 'checkbox',
            id: 'header',
            toolTipText: '',
            withBg: true,
            disabledDrag: true,
        },
        {
            name: 'Display Cover Image',
            type: 'checkbox',
            id: 'displayCoverImage',
            toolTipText: '',
            withBg: true,
            disabledDrag: true,
        },
    ],
};

const FOOTER_OPTIONS = {
    id: 'ssrFooter',
    name: 'SSR Footer',
    items: [
        {
            name: 'Footer',
            type: 'checkbox',
            id: 'footer',
            toolTipText: '',
            withBg: true,
        },
        {
            name: 'Disclaimer',
            type: 'readonly',
            id: 'disclaimer',
            toolTipText: '',
            withBg: true,
        },
    ],
};

const SORTABLE_OPTIONS_AU = [
    {
        name: 'Area Profile',
        type: 'checkbox',
        id: 'areaProfile',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Market Trends',
        type: 'checkbox',
        id: 'marketTrends',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Median Value',
        type: 'checkbox',
        id: 'medianValue',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Sales Per Annum',
        type: 'checkbox',
        id: 'salesPerAnnum',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Sales By Price',
        type: 'checkbox',
        id: 'salesByPrice',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Change in Median Value',
        type: 'checkbox',
        id: 'changeInMedianValue',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'For Sale Statistics',
        type: 'checkbox',
        id: 'forSaleStatistics',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Rental Statistics',
        type: 'checkbox',
        id: 'rentalStatistics',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Household Structure',
        type: 'checkbox',
        id: 'householdStructure',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Population Age',
        type: 'checkbox',
        id: 'populationAge',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Household Income',
        type: 'checkbox',
        id: 'householdIncome',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Education',
        type: 'checkbox',
        id: 'education',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Occupation',
        type: 'checkbox',
        id: 'occupation',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
];

const SORTABLE_OPTIONS_NZ = [
    {
        name: 'Area Profile',
        type: 'checkbox',
        id: 'areaProfile',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Market Trends',
        type: 'checkbox',
        id: 'marketTrends',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Median Value',
        type: 'checkbox',
        id: 'medianValue',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Sales Per Annum',
        type: 'checkbox',
        id: 'salesPerAnnum',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Sales Price/CV Ratio',
        type: 'checkbox',
        id: 'salesPriceCvRatio',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Change in Median Value',
        type: 'checkbox',
        id: 'changeInMedianValue',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'For Sale Statistics',
        type: 'checkbox',
        id: 'forSaleStatistics',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Rental Statistics',
        type: 'checkbox',
        id: 'rentalStatistics',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Household Structure',
        type: 'checkbox',
        id: 'householdStructure',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Population Age',
        type: 'checkbox',
        id: 'populationAge',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Household Income',
        type: 'checkbox',
        id: 'householdIncome',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Education',
        type: 'checkbox',
        id: 'education',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
    {
        name: 'Occupation',
        type: 'checkbox',
        id: 'occupation',
        toolTipText: '',
        withBg: true,
        draggable: true,
    },
];

const SUBURB_STATS_REPORT_OPTIONS_AU = {
    headerOption: HEADER_OPTIONS,
    sortableOptions: {
        id: 'ssrSortableOptions',
        name: 'SSR Sortable Options',
        items: SORTABLE_OPTIONS_AU,
    },
    footerOptions: FOOTER_OPTIONS,
};

const SUBURB_STATS_REPORT_OPTIONS_NZ = {
    headerOption: HEADER_OPTIONS,
    sortableOptions: {
        id: 'ssrSortableOptions',
        name: 'SSR Sortable Options',
        items: SORTABLE_OPTIONS_NZ,
    },
    footerOptions: FOOTER_OPTIONS,
};

const TOGGLE_IDS_AU = {
    DISPLAY_COVER_IMAGE: 'displayCoverImage',
    HEADER: 'header',
    FOOTER: 'footer',
    DISCLAIMER: 'disclaimer',
    AREA_PROFILE: 'areaProfile',
    MARKET_TRENDS: 'marketTrends',
    MEDIAN_VALUE: 'medianValue',
    SALES_PER_ANNUM: 'salesPerAnnum',
    SALES_BY_PRICE: 'salesByPrice',
    CHANGE_IN_MEDIAN_VALUE: 'changeInMedianValue',
    FOR_SALE_STATISTICS: 'forSaleStatistics',
    RENTAL_STATISTICS: 'rentalStatistics',
    HOUSEHOLD_STRUCTURE: 'householdStructure',
    POPULATION_AGE: 'populationAge',
    HOUSEHOLD_INCOME: 'householdIncome',
    EDUCATION: 'education',
    OCCUPATION: 'occupation',
};

const TOGGLE_IDS_NZ = {
    DISPLAY_COVER_IMAGE: 'displayCoverImage',
    HEADER: 'header',
    FOOTER: 'footer',
    DISCLAIMER: 'disclaimer',
    AREA_PROFILE: 'areaProfile',
    MARKET_TRENDS: 'marketTrends',
    MEDIAN_VALUE: 'medianValue',
    SALES_PER_ANNUM: 'salesPerAnnum',
    SALES_PRICE_CV_RATIO: 'salesPriceCvRatio',
    CHANGE_IN_MEDIAN_VALUE: 'changeInMedianValue',
    FOR_SALE_STATISTICS: 'forSaleStatistics',
    RENTAL_STATISTICS: 'rentalStatistics',
    HOUSEHOLD_STRUCTURE: 'householdStructure',
    POPULATION_AGE: 'populationAge',
    HOUSEHOLD_INCOME: 'householdIncome',
    EDUCATION: 'education',
    OCCUPATION: 'occupation',
};

const PROPERTY_TYPES = {
    houses: '1',
    units: '2',
    land: '3',
    apartments: '4',
    flats: '5',
    residential: '6',
};

const PROPERTY_TYPES_AU = [
    PROPERTY_TYPES.houses,
    PROPERTY_TYPES.units,
    PROPERTY_TYPES.land
];

const PROPERTY_TYPES_NZ = [
    PROPERTY_TYPES.residential,
    PROPERTY_TYPES.land
];

const PROPERTY_TYPE_NAME = {
    [PROPERTY_TYPES.houses]: 'Houses',
    [PROPERTY_TYPES.residential]: 'Residential dwellings',
    [PROPERTY_TYPES.land]: 'Land',
    [PROPERTY_TYPES.units]: 'Units',
};

const PROPERTY_TYPE_NAME_ALIAS = {
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.residential]]: 'Residential',
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.land]]: 'Land',
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.houses]]: 'House',
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.units]]: 'Unit',
};

const COUNTRY_PROPERTY_TYPES = isAU ? [
    {
        name: 'Houses',
        id: '1',
    },
    {
        name: 'Units',
        id: '2',
    },
    {
        name: 'Land',
        id: '3',
    },
] : [
    {
        name: 'Residential dwellings',
        id: '6',
    },
    {
        name: 'Land',
        id: '3',
    },
]

const COUNTRY_PROPERTY_TYPE_SEARCH_NAMES = isAU ? ['House', 'Unit', 'Land'] : ['Residential', 'Land'];

const METRIC_TYPE_ID = {
    salesPriceCvRatio: 82,
    totalValueOfSales: 66,
    medianAskingRent: isAU ? 49 : 77,
    rentalRateObservations: isAU ? 50 : 78,
    changeInRentalRate: isAU ? 47 : 79,
    valueBasedRentalYield: isAU ? 10: 80,
    changeInMedianValue: isAU ? 88 : 84,
    medianValue: isAU ? 11 : 73,
    numberSold: 37,
    averageTenurePeriod: 4,
    numberOfNewListing: 36,
    totalPropertiesListed: 64,
    medianDaysOnMarket: isAU ? 32 : 75,
};

const METRIC_TYPE_GROUP_ID = {
    HOUSEHOLD_STRUCTURE: isAU ? 107 : 118,
    POPULATION_AGE: isAU ? 100 : 113,
    HOUSEHOLD_INCOME: isAU ? 105 : 117,
    EDUCATION: isAU ? 103 : 114,
    SALES_BY_PRICE: 1,
    OCCUPATION: isAU ? 119 : 115,
};

const PROFESSIONAL = 'Professional';
const CLERICAL = 'Clerical';
const TRADES = 'Trades';
const LABOURER = 'Labourer';
const NOT_STATED = 'Not Stated';
const MACHINERY_OPERATORS_AND_DRIVERS = 'Machinery Operators and Drivers';
const MANAGERS = 'Managers';
const COMMUNITY_PERSONAL = 'Community and Personal Service';
const SALES_WORKERS = 'Sales Workers';
const AGRICULTURAL_AND_FISHERY = 'Agricultural and Fishery';
const PLANT_AND_MACHINERY = 'Plant and Machinery Operator';
const LEGISLATORS_ADMIN_AND_MANAGERS = 'Legislators, Admin and Managers';
const SERVICE_AND_SALES = 'Service and Sales';
const TECHNICIANS = 'Technicians';
const ELEMENTARY = 'Elementary';

const OCCUPATION_LABEL_AU = {
    [PROFESSIONAL]: 'Professional',
    [CLERICAL]: 'Clerical',
    [TRADES]: 'Trades',
    [LABOURER]: 'Labourer',
    [NOT_STATED]: 'Not Stated',
    [MACHINERY_OPERATORS_AND_DRIVERS]: 'Machinery Operators and Drivers',
    [MANAGERS]: 'Managers',
    [COMMUNITY_PERSONAL]: 'Community and Personal Service Workers',
    [SALES_WORKERS]: 'Sales Workers',
};

const OCCUPATION_LABEL_NZ = {
    [PROFESSIONAL]: 'Professional',
    [CLERICAL]: 'Clerical',
    [TRADES]: 'Trades',
    [AGRICULTURAL_AND_FISHERY]: 'Agricultural and Fishery',
    [PLANT_AND_MACHINERY]: 'Plant and Machinery Operator',
    [LEGISLATORS_ADMIN_AND_MANAGERS]: 'Legislators, Admin and Managers',
    [SERVICE_AND_SALES]: 'Service and Sales',
    [TECHNICIANS]: 'Technicians',
    [ELEMENTARY]: 'Elementary Occupations',
};

const OCCUPATION_IMAGE_AU = {
    [PROFESSIONAL]: professional,
    [CLERICAL]: clerical,
    [TRADES]: trades,
    [LABOURER]: labourer,
    [NOT_STATED]: notStated,
    [MACHINERY_OPERATORS_AND_DRIVERS]: machinery,
    [MANAGERS]: managers,
    [OCCUPATION_LABEL_AU[COMMUNITY_PERSONAL]]: communityPersonal,
    [SALES_WORKERS]: salesWorker,
};

const OCCUPATION_IMAGE_NZ = {
    [PROFESSIONAL]: professional,
    [CLERICAL]: clerical,
    [TRADES]: trades,
    [AGRICULTURAL_AND_FISHERY]: agriculturalAndFisheries,
    [PLANT_AND_MACHINERY]: machinery,
    [LEGISLATORS_ADMIN_AND_MANAGERS]: legislators,
    [SERVICE_AND_SALES]: serviceAndSales,
    [TECHNICIANS]: technicians,
    [OCCUPATION_LABEL_NZ[ELEMENTARY]]: elementary,
};

const LOCATION_TYPE_ID = {
    LOCALITY: '8',
    COUNCIL_AREA: isAU ? '3' : '9',
};

const UPPER_QUARTILE = 'Upper Quartile (12 Months)';
const MEDIAN_QUARTILE = 'Median Sale Price (12 Months)';
const LOWER_QUARTILE = 'Lower Quartile (12 Months)';
const PROPERTIES_SOLD = 'Properties Sold (12 Months)';
const AVERAGE_TENURE = 'Average Tenure Period (Years)';
const MEDIAN_ASKING_RENT = 'Median Asking Rent (12 Months)';
const NEW_SALE_LISTING = 'New Sale Listings (12 Months)';
const DAYS_ON_MARKET = 'Days on Market (12 Months)';
const RENTAL_RATE_OBSERVATION = 'Rental Rate Observations (12 Months)';

const MARKET_TRENDS_METRIC_TYPES_CARDS_AU = [
    { id: 37, type: PROPERTIES_SOLD },
    { id: 4, type: AVERAGE_TENURE },
    { id: 49, type: MEDIAN_ASKING_RENT },
    { id: 36, type: NEW_SALE_LISTING },
    { id: 32, type: DAYS_ON_MARKET },
    { id: 50, type: RENTAL_RATE_OBSERVATION },
];

const MARKET_TRENDS_METRIC_TYPES_QUARTILE_AU = [
    { id: 43, type: UPPER_QUARTILE },
    { id: 21, type: MEDIAN_QUARTILE },
    { id: 42, type: LOWER_QUARTILE },
];

const MARKET_TRENDS_METRIC_TYPES_CARDS_NZ = [
    { id: 37, type: PROPERTIES_SOLD },
    { id: 4, type: AVERAGE_TENURE },
    { id: 77, type: MEDIAN_ASKING_RENT },
    { id: 36, type: NEW_SALE_LISTING },
    { id: 75, type: DAYS_ON_MARKET },
    { id: 78, type: RENTAL_RATE_OBSERVATION },
];

const MARKET_TRENDS_METRIC_TYPES_QUARTILE_NZ = [
    { id: 43, type: UPPER_QUARTILE },
    { id: 21, type: MEDIAN_QUARTILE },
    { id: 42, type: LOWER_QUARTILE },
];


const RENTAL_STATISTICS_METRIC_TYPES = [
    METRIC_TYPE_ID.medianAskingRent,
    METRIC_TYPE_ID.rentalRateObservations,
    METRIC_TYPE_ID.changeInRentalRate,
    METRIC_TYPE_ID.valueBasedRentalYield,
];

const OPTION_STATISTIC_REQUEST = [
    'areaProfile',
    'marketTrends',
    'salesPerAnnum',
    'medianValue',
    'salesByPrice',
    'changeInMedianValue',
    'forSaleStatistics',
    'rentalStatistics',
    'salesPriceCvRatio',
];

const OPTION_CENSUS_REQUEST = [
    'householdStructure',
    'populationAge',
    'householdIncome',
    'education',
    'occupation',
];

const PROPERTY_TYPE_MAP = {
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.houses]]: {
        id: PROPERTY_TYPES.houses,
        label: PROPERTY_TYPE_NAME_ALIAS[PROPERTY_TYPE_NAME[PROPERTY_TYPES.houses]],
    },
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.units]]: {
        id: PROPERTY_TYPES.units,
        label: PROPERTY_TYPE_NAME_ALIAS[PROPERTY_TYPE_NAME[PROPERTY_TYPES.units]],
    },
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.land]]: {
        id: PROPERTY_TYPES.land,
        label: PROPERTY_TYPE_NAME[PROPERTY_TYPES.land],
    },
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.residential]]: {
        id: PROPERTY_TYPES.residential,
        label: PROPERTY_TYPE_NAME_ALIAS[PROPERTY_TYPE_NAME[PROPERTY_TYPES.residential]],
    },
};

const PROPERTY_TYPES_ARRAY = isAU ?
    [PROPERTY_TYPE_NAME[PROPERTY_TYPES.houses],
        PROPERTY_TYPE_NAME[PROPERTY_TYPES.units],
        PROPERTY_TYPE_NAME[PROPERTY_TYPES.land]]
    : [PROPERTY_TYPE_NAME[PROPERTY_TYPES.residential],
        PROPERTY_TYPE_NAME[PROPERTY_TYPES.land]]

export default {
    SUBURB_STATS_REPORT_OPTIONS_AU,
    SUBURB_STATS_REPORT_OPTIONS_NZ,
    PROPERTY_TYPES,
    COUNTRY_PROPERTY_TYPES,
    COUNTRY_PROPERTY_TYPE_SEARCH_NAMES,
    METRIC_TYPE_ID,
    METRIC_TYPE_GROUP_ID,
    LOCATION_TYPE_ID,
    TOGGLE_IDS_NZ,
    TOGGLE_IDS_AU,
    OCCUPATION_IMAGE_AU,
    OCCUPATION_IMAGE_NZ,
    PROPERTY_TYPE_NAME,
    PROPERTY_TYPES_AU,
    PROPERTY_TYPES_NZ,
    PROPERTY_TYPE_NAME_ALIAS,
    MARKET_TRENDS_METRIC_TYPES_CARDS_AU,
    MARKET_TRENDS_METRIC_TYPES_QUARTILE_AU,
    MARKET_TRENDS_METRIC_TYPES_CARDS_NZ,
    MARKET_TRENDS_METRIC_TYPES_QUARTILE_NZ,
    OCCUPATION_LABEL_AU,
    OCCUPATION_LABEL_NZ,
    RENTAL_STATISTICS_METRIC_TYPES,
    OPTION_CENSUS_REQUEST,
    OPTION_STATISTIC_REQUEST,
    PROPERTY_TYPE_MAP,
    PROPERTY_TYPES_ARRAY,
};
