import methods from '../constants/methods';
import API from './index';
import CLAPI from './clapi';
import { isAU } from '../constants/crux';
import { UriBuilder } from '../helpers/UriBuilder';

const getMapLayerImage = (mapLayerUrl, abortOptions) => {
    const requestOptions = {
        path: `/api/map${mapLayerUrl}`,
        isProxy: true,
        timeout: API.getTimeouts().large
    };
    const headerOptions = {
        method: methods.GET.method,
        headers: {
            Accept: '*/*',
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions, abortOptions);
};

const getPropertiesByCoordinates = (coordinates) => {
    const resourceUrl = isAU ? '/geometry/parcelOverlay/parcelAll' : '/overlays/propertyParcel/mapServer/0/query';

    const url = new UriBuilder('/query')
        .addParam('resourceUrl', resourceUrl)
        .addParam('clAppAccountUserGuid', CLAPI.getSelectedClAppAccountUserGuid())
        .addParam('geometryType', 'esriGeometryPoint')
        .addParam('geometry', `${coordinates.lon},${coordinates.lat}`)
        .addParam('outFields', `property_id`)
        .addParam('returnGeometry', `false`)
        .build();

    return getMapLayerImage(url);
};

const getMapTileImage = (mapTileUrl) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/nearmap${mapTileUrl}/?${appAccountUserGuid}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.GET.method,
        headers: {
            Accept: '*/*',
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getNearmapCoverage = (polygon) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/nearmap/coverage/${polygon}/?${appAccountUserGuid}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getMapLayerImage,
    getMapTileImage,
    getNearmapCoverage,
    getPropertiesByCoordinates,
};
