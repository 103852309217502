/**
 * Mixpanel generate properties
 */
import SEGMENT from '../constants/segment';
import { EMAIL_DIGEST_OPTIONS } from '../constants/savedSearch';
import { REPORT_TYPES } from './Localization';

const {
    PAGE,
    PAGE_TAB,
    SEARCH_TYPE,
    SEARCH_STRING,
    SEARCH_TAB,
    PROPERTY_ID,
    PROPERTY_TYPE,
    ENTRY_POINT,
    PANEL,
    NOTE,
    UPDATE,
    CHARACTER_COUNT,
    REPORT_SECTIONS_INCLUDED,
    REPORT_SUB_SECTIONS_INCLUDED,
    PROPERTY_DETAIL,
    PROPERTY_NOTES_PANEL,
    PROPERTY_NOTES_MODAL,
    PROPERTY_ADDRESS,
    PROPERTY_POSTCODE,
    PROPERTY_STATE,
    PROPERTY_SUBURB,
    PDF,
    OBJECT_CONTEXT,
    PANEL_TAB,
    LAYERS,
    LAYER,
    SELECTED_FIELDS,
    INTEGRATION,
    FORM_TYPE,
    FORM,
    TEMPLATE,
    DISTANCE,
    TARGET_SUBURB,
    INCLUDE_HISTORIC,
    MAP_TYPE,
    MAP_DATE,
    BASE,
    TAB,
    UPDATE_CORELOGIC,
    RESULT_VIEW,
    SORT_BY,
    USER_NAME,
    CHANGE_PASSWORD,
    REPORT_FORMAT,
    REPORT_TYPE,
    MARKET_INSIGHTS,
    PORTAL_SIDEBAR,
    DIGITAL_PROPERTY_REPORT,
    SECTION,
    RESULT,
    CHANGE_TYPE,
    MANAGE_USERS,
    MY_ACCOUNT,
    SECTION_NAME,
    SUB_SECTION_NAME,
    MANAGE_BRANDING,
    TITLE_REPORT,
    REPORT_PREVIEW,
    CUSTOM_PRINT_PREVIEW,
    TYPED,
    EXACT_MATCH,
    SAVED_SEARCHES,
    FAVOURITES,
    NAME,
    NOTIFICATIONS,
    PUSH_NOTIFICATIONS,
    MULTI_LOCALITY,
    EMAIL_DIGEST,
    FOR_SALE,
    FOR_RENT,
    AGENT_ADVISED_SALE,
    PER_PAGE,
    LIST_PROPERTIES,
    NUMBER_OF_LIST_PROPERTIES,
    REPORT_OPTION_INCLUDE_AVM,
    NUMBER_OF_PROPERTIES,
} = SEGMENT.EVENT_PROPERTIES;

const {
    VIEW_AGENCY_CAMPAIGN,
    VIEW_PROPERTY_CAMPAIGN,
    VIEW_DEVELOPMENT_DETAILS,
    CHANGE_SECURITY_QUESTION,
    PROPERTY_SEARCH,
    LAUNCH_RADIUS_SEARCH,
    DOWNLOAD_MAP,
    UPDATE_PROPERTY_DATA,
    DELETE_ALL,
    SWITCH_TABS,
    SHOW_MORE,
    VIEW_MY_DETAILS,
    VIEW_SAVED_SEARCHES,
    UPDATE_PROPERTY_LOCATION,
    SWITCH_RESULT_VIEW,
    CHANGE_SORT_ORDER,
    UPDATE_WORK_DETAILS,
    UPDATE_PERSONAL_DETAILS,
    RESET_PASSWORD,
    VIEW_CHANGE_PASSWORD,
    RETURN_HOME,
    RELOAD_PAGE,
    VIEW_PRINT_PAGE,
    ADD_SECTION_TO_REPORT,
    REMOVE_SECTION_FROM_REPORT,
    GENERATE_REPORT,
    UPDATE_PROPERTY_NOTE,
    VIEW_NEWS_FEED,
    VIEW_NOTIFICATIONS,
    MARKET_INSIGHTS_AGREEMENT,
    VIEW_TITLE_INFORMATION,
    SHOW_HISTORICAL_VALUATIONS,
    VIEW_PROPERTY_DETAIL,
    SWITCH_ACCOUNT,
    SWITCH_RESULT_TAB,
    EXPORT_TO_CSV,
    VIEW_TRAINING,
    YOUR_FEEDBACK,
    COPY_REPORT_LINK,
    SHARE_REPORT,
    RESET_FILTERS,
    EDIT_ACCOUNT_DETAILS,
    VIEW_ACCOUNT_DETAILS,
    MY_DETAILS,
    CONFIGURE_SAVED_SEARCH,
    CHANGE_SECTION_ORDER,
    CHANGE_PER_PAGE,
} = SEGMENT.EVENT_NAMES;

export const createMixpanelProperties = (eventName, {
    page,
    pageTab,
    panel,
    name,
    searchString,
    propertyId,
    numberOfProperties,
    searchTab,
    entryPoint,
    selectedFields,
    integration,
    formType,
    form,
    template,
}) => ({
    eventName,
    properties: [
        ...(page ? [{ label: PAGE, value: page }] : []),
        ...(pageTab ? [{ label: PAGE_TAB, value: pageTab }] : []),
        ...(panel ? [{ label: PANEL, value: panel }] : []),
        ...(name ? [{ label: NAME, value: name }] : []),
        ...(searchString ? [{ label: SEARCH_STRING, value: searchString }] : []),
        ...(propertyId ? [{ label: PROPERTY_ID, value: propertyId }] : []),
        ...(numberOfProperties ? [{ label: NUMBER_OF_PROPERTIES, value: numberOfProperties }] : []),
        ...(searchTab ? [{ label: SEARCH_TAB, value: searchTab }] : []),
        ...(entryPoint ? [{ label: ENTRY_POINT, value: entryPoint }] : []),
        ...(selectedFields ? [{ label: SELECTED_FIELDS, value: selectedFields }] : []),
        ...(integration ? [{ label: INTEGRATION, value: integration }] : []),
        ...(formType ? [{ label: FORM_TYPE, value: formType }] : []),
        ...(form ? [{ label: FORM, value: form }] : []),
        ...(template ? [{ label: TEMPLATE, value: template }] : []),
    ],
});

const addResultsView = activeView => activeView
    ? [{ label: 'Result View', value: activeView }]
    : [];

const showMoreResult = ({
    page, searchType, searchTab, resultView,
}) => ({
    eventName: 'Show More Results',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
    ],
});

const advanceFiltersClick = ({
    page, searchTab , resultView, searchString, searchType, propertyId,
}) => ({
    eventName: 'Advanced Filters',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
        { label: SEARCH_TYPE, value: searchType },
        ...(searchType?.toLowerCase() !== 'radius' ? [{ label: SEARCH_STRING, value: searchString }] : [{ label: PROPERTY_ID, value: propertyId }]),
    ],
});

const showMoreResultMapbubble = ({ page, panel }) => ({
    eventName: 'Show More Results',
    properties: [
        { label: PAGE, value: page },
        { label: PANEL, value: panel },
    ],
});

const openSeeOwnersModal = ({
    page, searchType, searchTab, propertyId, panel, tab, resultView,
}) => ({
    eventName: 'Show More Owners',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: PANEL_TAB, value: tab },
        { label: RESULT_VIEW, value: resultView },
    ],
});

const viewPropertyCampaign = ({ page, propertyId, panel }) => ({
    eventName: VIEW_PROPERTY_CAMPAIGN,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
    ],
});

const viewAgencyCampaign = ({ page, propertyId, panel }) => ({
    eventName: VIEW_AGENCY_CAMPAIGN,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
    ],
});

const viewDevelopmentApplication = ({ page, propertyId, panel }) => ({
    eventName: VIEW_DEVELOPMENT_DETAILS,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
    ],
});

const viewCampaignModal = ({
    eventName,
    page,
    propertyId,
    panel,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
    ],
});

const updateMapLayer = ({
    eventName,
    page,
    propertyId,
    panel,
    layer,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: LAYER, value: layer },
    ],
});

const openRadiusSearch = ({
    page, entryPoint, propertyId, searchTab,
}) => ({
    eventName: LAUNCH_RADIUS_SEARCH,
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TAB, value: searchTab },
        { label: ENTRY_POINT, value: entryPoint },
        { label: PROPERTY_ID, value: propertyId },
    ],
});

const radiusSearch = ({
    page, searchType, entryPoint, propertyId, distance, targetSuburb, includeHistoric, activeView,
}) => ({
    eventName: PROPERTY_SEARCH,
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: ENTRY_POINT, value: entryPoint },
        { label: PROPERTY_ID, value: propertyId },
        { label: DISTANCE, value: distance },
        { label: TARGET_SUBURB, value: targetSuburb },
        { label: INCLUDE_HISTORIC, value: includeHistoric },
        ...addResultsView(activeView),
    ],
});

const updatePropertyLocation = ({
    page, searchType, entryPoint, propertyId, panel, updateCorelogic,
}) => ({
    eventName: UPDATE_PROPERTY_LOCATION,
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: ENTRY_POINT, value: entryPoint },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: UPDATE_CORELOGIC, value: updateCorelogic },
    ],
});

const mapDrawingTools = ({
    eventName, page, propertyId, panel,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
    ],
});

const deleteMapDrawing = ({
    page,
    propertyId,
    panel,
}) => ({
    eventName: DELETE_ALL,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
    ],
});

const updatePropertyData = ({ page, propertyId, entryPoint }) => ({
    eventName: UPDATE_PROPERTY_DATA,
    properties: [
        { label: PAGE, value: page },
        { label: ENTRY_POINT, value: entryPoint },
        { label: PROPERTY_ID, value: propertyId },
    ],
});

const viewMyDetails = ({ page, panel }) => ({
    eventName: VIEW_MY_DETAILS,
    properties: [
        { label: PAGE, value: page },
        { label: PANEL, value: panel },
    ],
});

const viewSavedSearches = ({ page, panel }) => ({
    eventName: VIEW_SAVED_SEARCHES,
    properties: [
        { label: PAGE, value: page },
        { label: PANEL, value: panel },
    ],
});

const changePassword = ({ page, panel }) => ({
    eventName: VIEW_CHANGE_PASSWORD,
    properties: [
        { label: PAGE, value: page },
        { label: PANEL, value: panel },
    ],
});

const switchAccount = ({ page }) => ({
    eventName: SWITCH_ACCOUNT,
    properties: [
        { label: PAGE, value: page},
    ],
});

const downloadMap = ({
    page,
    propertyId,
    panel,
    mapType,
    layers = BASE,
}) => ({
    eventName: DOWNLOAD_MAP,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: MAP_TYPE, value: mapType },
        { label: LAYERS, value: layers.length === 0 ? BASE : layers },
    ],
});

const switchTab = ({
    page,
    propertyId,
    panel,
    tab,
}) => ({
    eventName: SWITCH_TABS,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: TAB, value: tab },
    ],
});

const showMore = ({
    page,
    propertyId,
    panel,
    tab,
}) => ({
    eventName: SHOW_MORE,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: TAB, value: tab },
    ],
});

const switchView = ({
    page,
    searchType,
    searchTab,
    resultView,
}) => ({
    eventName: SWITCH_RESULT_VIEW,
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
    ],
});

const changeSortOrder = ({
    page,
    entryPoint,
    searchTab,
    sortBy,
    resultView,
}) => ({
    eventName: CHANGE_SORT_ORDER,
    properties: [
        { label: PAGE, value: page },
        { label: ENTRY_POINT, value: entryPoint },
        { label: SEARCH_TAB, value: searchTab },
        { label: SORT_BY, value: sortBy },
        { label: RESULT_VIEW, value: resultView },
    ],
});

const changePerPage = ({
    page,
    searchType,
    searchTab,
    perPage,
    name,
}) => ({
    eventName: CHANGE_PER_PAGE,
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: PER_PAGE, value: perPage },
        ...(name ? [{ label: NAME, value: name }] : []),
    ],
});

const updateWorkDetails = ({ page }) => ({
    eventName: UPDATE_WORK_DETAILS,
    properties: [
        { label: PAGE, value: page },
    ],
});

const updatePersonalDetails = ({
    page,
}) => ({
    eventName: UPDATE_PERSONAL_DETAILS,
    properties: [
        { label: PAGE, value: page },
    ],
});

const resetPassword = ({
    page,
    tab,
    userName,
}) => ({
    eventName: RESET_PASSWORD,
    properties: [
        { label: PAGE, value: page },
        { label: PAGE_TAB, value: tab },
        { label: USER_NAME, value: userName },
    ],
});

const updateSecurityQuestion = ({ page }) => ({
    eventName: CHANGE_SECURITY_QUESTION,
    properties: [
        { label: PAGE, value: page },
        { label: PAGE_TAB, value: CHANGE_PASSWORD },
    ],
});

const updatePropertyNotes = ({
    propertyId,
    note, update, characterCount,
}) => ({
    eventName: UPDATE_PROPERTY_NOTE,
    properties: [
        { label: PAGE, value: PROPERTY_DETAIL },
        { label: ENTRY_POINT, value: PROPERTY_NOTES_PANEL },
        { label: PANEL, value: PROPERTY_NOTES_MODAL },
        { label: PROPERTY_ID, value: propertyId },
        { label: NOTE, value: note },
        { label: UPDATE, value: update },
        { label: CHARACTER_COUNT, value: characterCount },
    ],
});

const updateValuation = ({
    eventName,
    page,
    entryPoint,
    panel,
    reportFormat,
    propertyId,
    objectContext,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: page },
        { label: ENTRY_POINT, value: entryPoint },
        { label: PANEL, value: panel },
        reportFormat,
        { label: PROPERTY_ID, value: propertyId },
        { label: OBJECT_CONTEXT, value: objectContext },
    ],
});

const openPropertyListReport = ({
    pageContext,
    searchTab,
    searchType,
    view,
    name,
}, eventName) => ({
    eventName,
    properties: [
        { label: PAGE, value: pageContext },
        { label: SEARCH_TAB, value: searchTab },
        { label: SEARCH_TYPE, value: searchType },
        { label: RESULT_VIEW, value: view },
        ...(name ? [{ label: NAME, value: name }] : []),
    ],
});

const exportToCSV = ({
    pageContext,
    searchTab,
    searchType,
    exportedProperties,
    searchString,
    exportType,
    name,
    excludeDoNotCall,
    excludeDoNotMail,
    selectedFields,
    isMarketingExport = false,
}) => {
    const properties = [
        {label: PAGE, value: pageContext},
        {label: SEARCH_TAB, value: searchTab},
        {label: SEARCH_TYPE, value: searchType},
        {label: 'Exported Properties', value: exportedProperties},
        {label: 'Number of Exported Properties', value: exportedProperties?.length},
        {label: 'Export Type', value: exportType},
        ...(name ? [{label: NAME, value: name}] : []),
        ...(isMarketingExport ? [{label: 'Exclude Do Not Call', value: excludeDoNotCall}] : []),
        ...(isMarketingExport ? [{label: 'Exclude Do Not Mail', value: excludeDoNotMail}] : []),
        {label: 'Selected Fields', value: selectedFields},
    ];
    if (searchString) {
        let parseSearchString = searchString;
        try {
            parseSearchString = JSON.parse(searchString);
        } catch (e) {
            // cannot parse searchString to JSON
        }

        properties.push({
            label: SEARCH_STRING,
            value: ['Address', 'Parcel', 'Legal Description', 'Title']
                .includes(searchType) ? searchString : parseSearchString
        });
    }
    return {
        eventName: EXPORT_TO_CSV,
        properties,
    };
};

const generatePropertyListReport = ({
    pageContext,
    reportFormat,
    reportType,
    view,
    includedSections,
    includedSubSection,
}) => ({
    eventName: GENERATE_REPORT,
    properties: [
        { label: PAGE, value: pageContext },
        { label: REPORT_FORMAT, value: reportFormat },
        { label: REPORT_TYPE, value: reportType },
        { label: RESULT_VIEW, value: view },
        { label: REPORT_SECTIONS_INCLUDED, value: includedSections },
        { label: REPORT_SUB_SECTIONS_INCLUDED, value: includedSubSection },
    ],
});

const generateSuburbStatisticsReport = ({
    suburb,
    propertyTypes,
    reportSections,
}) => ({
    eventName: GENERATE_REPORT,
    properties: [
        { label: PAGE, value: 'Suburb Statistics Report' },
        { label: REPORT_FORMAT, value: PDF },
        { label: REPORT_TYPE, value: 'Suburb Statistics Report' },
        { label: OBJECT_CONTEXT, value: suburb },
        { label: 'Property Types Included', value: propertyTypes },
        { label: 'Report Sections Included', value: reportSections },
    ],
});

const returnHome = ({ page }) => ({
    eventName: RETURN_HOME,
    properties: [
        { label: PAGE, value: page },
    ],
});

const reloadPage = ({ page }) => ({
    eventName: RELOAD_PAGE,
    properties: [
        { label: PAGE, value: page },
    ],
});

const viewNewsFeed = ({ page, panel, objectContext }) => ({
    eventName: VIEW_NEWS_FEED,
    properties: [
        { label: PAGE, value: page },
        { label: PANEL, value: panel },
        { label: OBJECT_CONTEXT, value: objectContext },
    ],
});

const viewNotifications = ({ page }) => ({
    eventName: VIEW_NOTIFICATIONS,
    properties: [
        { label: PAGE, value: page },
    ],
});

const launch = (event, page) => ({
    eventName: event,
    properties: [
        {
            label: PAGE,
            value: page,
        },
    ],
});

const acceptMarketInsightsAgreement = objectContext => ({
    eventName: MARKET_INSIGHTS_AGREEMENT,
    properties: [
        { label: PAGE, value: MARKET_INSIGHTS },
        { label: OBJECT_CONTEXT, value: objectContext },
        { label: ENTRY_POINT, value: PORTAL_SIDEBAR },
    ],
});

const viewTitleInformation = ({
    page,
    propertyId,
    panel,
    tab,
}) => ({
    eventName: VIEW_TITLE_INFORMATION,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: TAB, value: tab },
    ],
});

const showHistoricalValuations = ({ page, propertyId, panel }) => ({
    eventName: SHOW_HISTORICAL_VALUATIONS,
    properties: [
        { label: PAGE, value: page },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
    ],
});

const viewPropertyDetailPage = ({
    propertyId,
    propertyType,
    entryPoint,
    propertyAddress,
    propertyPostcode,
    propertyState,
    propertySuburb,
}) => ({
    eventName: VIEW_PROPERTY_DETAIL,
    properties: [
        { label: PAGE, value: PROPERTY_DETAIL },
        { label: PROPERTY_ID, value: propertyId },
        { label: PROPERTY_TYPE, value: propertyType },
        { label: ENTRY_POINT, value: entryPoint },
        { label: PROPERTY_ADDRESS, value: propertyAddress },
        { label: PROPERTY_POSTCODE, value: propertyPostcode },
        { label: PROPERTY_STATE, value: propertyState },
        { label: PROPERTY_SUBURB, value: propertySuburb },
    ],
});

const switchResultTab = ({
    page,
    searchType,
    searchTab,
}) => ({
    eventName: SWITCH_RESULT_TAB,
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
    ],
});

const viewTraining = ({
    page,
}) => ({
    eventName: VIEW_TRAINING,
    properties: [
        { label: PAGE, value: page },
    ],
});

const yourFeedback = ({
    page,
}) => ({
    eventName: YOUR_FEEDBACK,
    properties: [
        { label: PAGE, value: page },
    ],
});

const generateReport = ({
    pageContext,
    reportFormat,
    reportType,
    propertyId,
    entryPoint,
}) => ({
    eventName: GENERATE_REPORT,
    properties: [
        { label: PAGE, value: pageContext },
        { label: ENTRY_POINT, value: entryPoint },
        { label: REPORT_FORMAT, value: reportFormat },
        { label: REPORT_TYPE, value: reportType },
        { label: PROPERTY_ID, value: propertyId },
    ],
});

const generateDpprReport = ({
    pageContext,
    reportFormat,
    reportType,
    propertyId,
    entryPoint,
    includeAvm,
}) => ({
    eventName: GENERATE_REPORT,
    properties: [
        {label: PAGE, value: pageContext},
        {label: ENTRY_POINT, value: entryPoint},
        {label: REPORT_FORMAT, value: reportFormat},
        {label: REPORT_TYPE, value: reportType},
        {label: PROPERTY_ID, value: propertyId},
        {label: REPORT_OPTION_INCLUDE_AVM, value: includeAvm},
    ],
});

const viewPrintPage = ({ propertyId }) => ({
    eventName: VIEW_PRINT_PAGE,
    properties: [
        { label: PAGE, value: PROPERTY_DETAIL },
        { label: PROPERTY_ID, value: propertyId },
    ],
});

const addRemoveReportSection = ({ sectionName, reportType, isChecked, page }) => ({
    eventName: isChecked ? ADD_SECTION_TO_REPORT : REMOVE_SECTION_FROM_REPORT,
    properties: [
        { label: PAGE, value: page },
        { label: REPORT_TYPE, value: reportType },
        { label: SECTION_NAME, value: sectionName },
    ],
});

const changeSectionOrder = ({ sectionName, reportType, page }) => ({
    eventName: CHANGE_SECTION_ORDER,
    properties: [
        { label: PAGE, value: page },
        { label: REPORT_TYPE, value: reportType },
        { label: SECTION_NAME, value: sectionName },
    ],
});

const generateCustomPrintReport = ({
    reportType,
    propertyId,
    reportSections,
}) => ({
    eventName: GENERATE_REPORT,
    properties: [
        { label: PAGE, value: CUSTOM_PRINT_PREVIEW },
        { label: ENTRY_POINT, value: PROPERTY_DETAIL },
        { label: REPORT_FORMAT, value: PDF },
        { label: REPORT_TYPE, value: reportType },
        { label: PROPERTY_ID, value: propertyId },
        { label: REPORT_SECTIONS_INCLUDED, value: reportSections },
    ],
});

const generateTitleDetailReport = ({
                                       eventName,
                                       propertyId,
                                       entryPoint,
                                   }) => ({
    eventName,
    properties: [
        { label: PAGE, value: REPORT_PREVIEW },
        { label: ENTRY_POINT, value: entryPoint },
        { label: REPORT_TYPE, value: TITLE_REPORT },
        { label: PROPERTY_ID, value: propertyId },
    ],
});

const dpprLinkEvent = ({
    eventName,
    pageContext,
    propertyId,
    entryPoint,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: pageContext },
        { label: PROPERTY_ID, value: propertyId },
        { label: REPORT_TYPE, value: DIGITAL_PROPERTY_REPORT },
        { label: ENTRY_POINT, value: entryPoint },
    ],
});

const resetFilters = ({ statusType, activeView }) => ({
    eventName: RESET_FILTERS,
    properties: [
        { label: PAGE, value: 'Search' },
        { label: SEARCH_TAB, value: statusType },
        { label: RESULT_VIEW, value: activeView },
    ],
});

const copyReportLink = eventProperties =>
    dpprLinkEvent({ ...eventProperties, eventName: COPY_REPORT_LINK });

const shareReportLink = eventProperties =>
    dpprLinkEvent({ ...eventProperties, eventName: SHARE_REPORT });

const trackNameSearch = ({
    pageContext, searchString, searchType,
    entryPoint, isCurrentOwner, exactSearch, activeView,
}) => ({
    eventName: PROPERTY_SEARCH,
    properties: [
        { label: PAGE, value: pageContext },
        { label: SEARCH_STRING, value: searchString },
        { label: SEARCH_TYPE, value: searchType },
        { label: ENTRY_POINT, value: entryPoint },
        { label: 'Current Owner', value: isCurrentOwner },
        { label: EXACT_MATCH, value: exactSearch },
        ...addResultsView(activeView),
    ],
});

const buildingSearch = ({
    pageContext, searchString, exactSearch, activeView, entryPoint = TYPED,
}) => ({
    eventName: PROPERTY_SEARCH,
    properties: [
        { label: PAGE, value: pageContext },
        { label: SEARCH_STRING, value: searchString },
        { label: SEARCH_TYPE, value: 'Building Name' },
        { label: ENTRY_POINT, value: entryPoint },
        { label: EXACT_MATCH, value: exactSearch },
        ...addResultsView(activeView),
    ],
});

const accountDetailsProperties = () => ([
    { label: PAGE, value: 'My Account' },
    { label: PAGE_TAB, value: 'Account Details' },
]);

const viewAccountDetails = () => ({
    eventName: VIEW_ACCOUNT_DETAILS,
    properties: accountDetailsProperties(),
});

const viewAccountDetailsFromUserDropdown = () => ({
    eventName: VIEW_ACCOUNT_DETAILS,
    properties: [
        ...accountDetailsProperties(),
        { label: PANEL, value: SEGMENT.EVENT_PROPERTIES.USER_PROFILE },
    ],
});

const viewManageUsers = (isAdmin = false) => ({
    eventName: 'View Manage Users',
    properties: [
        { label: PAGE, value: 'My Account' },
        { label: PAGE_TAB, value: 'Manage Users' },
        { label: 'Account Admin', value: isAdmin },
    ],
});

const viewExpandedMap = ({
    page, searchType, searchTab, resultView,
}) => ({
    eventName: 'View Expanded Map',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
    ],
});

const viewPropertyBubbleMap = ({
    page, searchType, searchTab, resultView, objectContext
}) => ({
    eventName: 'View Property Bubble',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
        { label: OBJECT_CONTEXT, value: objectContext },
    ],
});

const selectProperty = ({
    page, searchType, searchTab, resultView, objectContext
}) => ({
    eventName: 'Select Property',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
        { label: OBJECT_CONTEXT, value: objectContext },
    ],
});

const viewIntegrations = () => ({
    eventName: 'View Integrations',
    properties: [
        { label: PAGE, value: 'My Account' },
        { label: PAGE_TAB, value: 'Integrations' },
        { label: PANEL, value: SEGMENT.EVENT_PROPERTIES.USER_PROFILE },
    ],
});

const changeMapViewSearchResult = ({
   page, searchType, searchTab, resultView, mapType
}) => ({
    eventName: 'Change Map View',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TYPE, value: searchType },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
        { label: MAP_TYPE, value: mapType },
    ],
});

const viewManageUsersFromUserDropdown = (isAdmin = false) => ({
    eventName: 'View Manage Users',
    properties: [
        { label: PAGE, value: 'My Account' },
        { label: PAGE_TAB, value: 'Manage Users' },
        { label: PANEL, value: SEGMENT.EVENT_PROPERTIES.USER_PROFILE },
        { label: 'Account Admin', value: isAdmin },
    ],
});

const viewManageBranding = (isAdmin = false) => ({
    eventName: 'View Manage Branding',
    properties: [
        { label: PAGE, value: 'My Account' },
        { label: PAGE_TAB, value: 'Manage Branding' },
        { label: 'Account Admin', value: isAdmin },
    ],
});

const viewManageBrandingFromUserDropdown = () => ({
    eventName: 'View Manage Branding',
    properties: [
        { label: PAGE, value: 'My Account' },
        { label: PAGE_TAB, value: 'Manage Branding' },
        { label: PANEL, value: SEGMENT.EVENT_PROPERTIES.USER_PROFILE },
    ],
});

const editAccountDetails = ({ entryPoint }) => ({
    eventName: EDIT_ACCOUNT_DETAILS,
    properties: [
        ...accountDetailsProperties(),
        { label: ENTRY_POINT, value: entryPoint },
    ],
});

const updateAccountDetails = ({
    section,
    objectContext,
    result,
    eventName,
    entryPoint,
}) => {
    const properties = [
        ...accountDetailsProperties(),
        { label: SECTION, value: section },
        { label: OBJECT_CONTEXT, value: objectContext },
    ];

    if (result) {
        properties.push({ label: RESULT, value: result });
    }

    if (entryPoint) {
        properties.push({ label: ENTRY_POINT, value: entryPoint });
    }

    return { eventName, properties };
};

const manualEditAddress = ({
    eventName,
    objectContext,
}) => ({
    eventName,
    properties: [
        ...accountDetailsProperties(),
        { label: OBJECT_CONTEXT, value: objectContext },
    ],
});

const manageUserEvent = ({
    eventName, changeType, objectContext, result,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: 'My Account' },
        { label: PAGE_TAB, value: 'Manage Users' },
        { label: CHANGE_TYPE, value: changeType },
        { label: OBJECT_CONTEXT, value: objectContext },
        { label: RESULT, value: result },
    ],
});

const manageUserChangeSortOrder = ({ sortBy }) => ({
    eventName: CHANGE_SORT_ORDER,
    properties: [
        { label: PAGE, value: MY_ACCOUNT },
        { label: PAGE_TAB, value: MANAGE_USERS },
        { label: SORT_BY, value: sortBy },
    ],
});

const manageUserRemoveUser = (userName, result) => ({
    eventName: 'Remove User',
    properties: [
        { label: PAGE, value: MY_ACCOUNT },
        { label: PAGE_TAB, value: MANAGE_USERS },
        { label: CHANGE_TYPE, value: 'Remove User' },
        { label: OBJECT_CONTEXT, value: userName },
        { label: RESULT, value: result },
    ],
});

const updateReportSection = (reportType, section, subSection, isChecked) => {
    const properties = [
        { label: PAGE, value: `${reportType}` },
        { label: REPORT_TYPE, value: reportType },
        { label: SECTION_NAME, value: section }
    ];

    subSection && properties.push({ label: SUB_SECTION_NAME, value: subSection });

    return {
        eventName: isChecked ? 'Add Section to Report' : 'Remove Section from Report',
        properties
    };
};

const manageBranding = (themeName, result, type) => ({
    eventName: type,
    properties: [
        { label: PAGE, value: MY_ACCOUNT },
        { label: PAGE_TAB, value: MANAGE_BRANDING },
        { label: CHANGE_TYPE, value: type },
        { label: OBJECT_CONTEXT, value: themeName },
        { label: RESULT, value: result },
    ],
});

const updateProfileImage = result => ({
    eventName: 'Update Profile Image',
    properties: [
        { label: PAGE, value: MY_ACCOUNT },
        { label: PAGE_TAB, value: MY_DETAILS },
        { label: CHANGE_TYPE, value: 'Upload profile image' },
        { label: RESULT, value: result },
    ],
});

const changeMapView = ({
    propertyId, panel, mapType,
}) => ({
    eventName: 'Change Map View',
    properties: [
        { label: PAGE, value: PROPERTY_DETAIL },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: MAP_TYPE, value: mapType },
    ],
});

const changeMapDate = ({
    propertyId, panel, mapType, mapDate,
}) => ({
    eventName: 'Change Map Date',
    properties: [
        { label: PAGE, value: PROPERTY_DETAIL },
        { label: PROPERTY_ID, value: propertyId },
        { label: PANEL, value: panel },
        { label: MAP_TYPE, value: mapType },
        { label: MAP_DATE, value: mapDate },
    ],
});

const launchOTH = pageContext => ({
    eventName: 'Launch OTH',
    properties: [
        { label: PAGE, value: pageContext },
        { label: ENTRY_POINT, value: 'Portal Sidebar' },
    ],
});

const trackRollValuationSearch = ({
    pageContext, searchString, searchType,
    entryPoint, activeView,
}) => ({
    eventName: PROPERTY_SEARCH,
    properties: [
        { label: PAGE, value: pageContext },
        { label: SEARCH_STRING, value: searchString },
        { label: SEARCH_TYPE, value: searchType },
        { label: ENTRY_POINT, value: entryPoint },
        ...addResultsView(activeView),
    ],
});

const saveSearch = ({
    pageContext,
    searchString,
    tab = SAVED_SEARCHES,
    searchName,
    notificationsEnabled,
    forSale,
    forRent,
    agentAdvised,
    multiple,
    emailDigest,
}) => ({
    eventName: 'Create New Saved Search',
    properties: [
        { label: PAGE, value: pageContext },
        { label: SEARCH_STRING, value: searchString },
        { label: PAGE_TAB, value: tab },
        { label: NAME, value: searchName },
        { label: NOTIFICATIONS, value: notificationsEnabled },
        { label: EMAIL_DIGEST, value: emailDigest },
        { label: FOR_SALE, value: forSale },
        { label: FOR_RENT, value: forRent },
        { label: AGENT_ADVISED_SALE, value: agentAdvised },
        { label: MULTI_LOCALITY, value: multiple },
    ],
});

const removeSavedSearch = ({
    searchName,
    pageContext = FAVOURITES,
    tab = SAVED_SEARCHES,
}) => ({
    eventName: 'Delete Saved Search',
    properties: [
        { label: PAGE, value: pageContext },
        { label: PAGE_TAB, value: tab },
        { label: NAME, value: searchName },
    ],
});

const getEmailDigestValue = (emailEnabled, emailPeriod) => (
    !emailEnabled
        ? 'Off'
        : EMAIL_DIGEST_OPTIONS.find(period => emailPeriod === period.key).label
);

const updateSavedSearch = ({
    pageContext,
    tab,
    searchName,
    notificationsEnabled,
    pushEnabled,
    emailDigest,
    forSale,
    forRent,
    agentAdvised,
}) => ({
    eventName: CONFIGURE_SAVED_SEARCH,
    properties: [
        { label: PAGE, value: pageContext },
        { label: PAGE_TAB, value: tab },
        { label: NAME, value: searchName },
        { label: NOTIFICATIONS, value: notificationsEnabled },
        { label: PUSH_NOTIFICATIONS, value: pushEnabled },
        { label: EMAIL_DIGEST, value: emailDigest },
        { label: FOR_SALE, value: forSale },
        { label: FOR_RENT, value: forRent },
        { label: AGENT_ADVISED_SALE, value: agentAdvised },
    ],
});

const openSavedSearch = ({
    searchName, searchString, notifications,
    forSale, forRent, agentAdvised,
    emailDigest, pushNotifications,
    multiple,
}) => ({
    eventName: 'Open Saved Search',
    properties: [
        { label: PAGE, value: FAVOURITES },
        { label: PAGE_TAB, value: SAVED_SEARCHES },
        { label: NAME, value: searchName },
        { label: SEARCH_STRING, value: searchString },
        { label: NOTIFICATIONS, value: notifications },
        { label: FOR_SALE, value: forSale },
        { label: FOR_RENT, value: forRent },
        { label: AGENT_ADVISED_SALE, value: agentAdvised },
        { label: EMAIL_DIGEST, value: emailDigest },
        { label: PUSH_NOTIFICATIONS, value: pushNotifications },
        { label: MULTI_LOCALITY, value: multiple },
    ],
});

const viewNotificationBell = pageContext => ({
    eventName: 'View Notifications',
    properties: [
        { label: PAGE, value: pageContext },
        { label: PANEL, value: 'Header' },
    ],
});

const removeRecentSearch = ({
    pageContext,
    objectContext,
}) => ({
    eventName: 'Clear All Recent Search',
    properties: [
        { label: PAGE, value: pageContext },
        { label: OBJECT_CONTEXT, value: objectContext },
    ],
});

const suburbStatisticReport = ({
   pageContext,
   objectContext,
   entryPoint,
}) => ({
    eventName: 'Launch Suburb Statistics Report',
    properties: [
        { label: PAGE, value: pageContext },
        { label: ENTRY_POINT, value: entryPoint },
        { label: REPORT_TYPE, value: REPORT_TYPES.suburbStats.label },
        { label: OBJECT_CONTEXT, value: objectContext },
    ],
});

const navigateToNZTitlesFromUserPanel = ({ pageContext }) => ({
    eventName: SEGMENT.EVENT_NAMES.LAUNCH_TITLE_DOCUMENT_SEARCH,
    properties: [
        {
            label: PAGE,
            value: pageContext,
        },
        {
            label: ENTRY_POINT,
            value: SEGMENT.EVENT_PROPERTIES.USER_PROFILE,
        },
    ],
});

/*
* Open Watch List
* Open Saved List
* Delete Saved List
* Configure Saved List
* Open Watch List
* Open Saved List
*/
const recordSavedList = ({
    eventName,
    page = 'Favourites',
    name,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: page },
        { label: PAGE_TAB, value: 'Saved List' },
        { label: NAME, value: name },
    ],
});

const createSavedList = ({
    savedListName,
    pageContext,
    searchTab,
}) => ({
    eventName: 'Create New Saved List',
    properties: [
        { label: PAGE, value: pageContext },
        { label: NAME, value: savedListName },
        ...(searchTab ? [{ label: SEARCH_TAB, value: searchTab }] : []),
    ],
});

const addOrRemoveToList = ({
    eventName,
    pageContext,
    searchTab,
    properties,
    entryPoint,
}) => ({
    eventName,
    properties: [
        { label: PAGE, value: pageContext },
        { label: ENTRY_POINT, value: entryPoint },
        ...(searchTab ? [{ label: SEARCH_TAB, value: searchTab }] : []),
        { label: LIST_PROPERTIES, value: properties },
        { label: NUMBER_OF_LIST_PROPERTIES, value: properties.length },
    ],
});

const integrations = ({ eventName, name, state }) => {
    const properties = [
        { label: PAGE, value: 'My Account' },
        { label: PAGE_TAB, value: 'Integrations' },
        { label: 'Integration Type', value: name },
    ];
    if (state) {
        properties.push({ label: 'Object Context', value: state });
    }
    return {
        eventName,
        properties,
    }
};

const searchFilter = ({
    searchTab,
    resultView,
    key,
    page,
}) => ({
    eventName: 'Search Filter',
    properties: [
        { label: PAGE, value: page },
        { label: SEARCH_TAB, value: searchTab },
        { label: RESULT_VIEW, value: resultView },
        { label: SEARCH_STRING, value: key },
    ],
});
const addNewTheme = (themeName, result) => manageBranding(themeName, result, 'Add Theme');
const editTheme = (themeName, result) => manageBranding(themeName, result, 'Edit Theme');
const deleteTheme = (themeName, result) => manageBranding(themeName, result, 'Remove Theme');
const activateTheme = (themeName, result) => manageBranding(themeName, result, 'Activate Theme');
const uploadLogo = (themeName, result) => manageBranding(themeName, result, 'Upload Logo');

export default {
    viewPrintPage,
    addRemoveReportSection,
    showMoreResult,
    showMoreResultMapbubble,
    updatePropertyNotes,
    openSeeOwnersModal,
    viewPropertyCampaign,
    viewAgencyCampaign,
    viewDevelopmentApplication,
    viewCampaignModal,
    updateMapLayer,
    radiusSearch,
    openRadiusSearch,
    mapDrawingTools,
    updatePropertyData,
    buildingSearch,
    downloadMap,
    viewMyDetails,
    viewSavedSearches,
    deleteMapDrawing,
    switchTab,
    showMore,
    updatePropertyLocation,
    switchView,
    changeSortOrder,
    updateWorkDetails,
    updatePersonalDetails,
    generateSuburbStatisticsReport,
    resetPassword,
    updateValuation,
    updateSecurityQuestion,
    changePassword,
    openPropertyListReport,
    returnHome,
    reloadPage,
    generatePropertyListReport,
    viewNewsFeed,
    viewNotifications,
    launch,
    acceptMarketInsightsAgreement,
    viewTitleInformation,
    showHistoricalValuations,
    switchResultTab,
    viewPropertyDetailPage,
    exportToCSV,
    viewTraining,
    yourFeedback,
    generateReport,
    generateDpprReport,
    copyReportLink,
    shareReportLink,
    resetFilters,
    switchAccount,
    trackNameSearch,
    generateCustomPrintReport,
    editAccountDetails,
    updateAccountDetails,
    viewAccountDetails,
    viewAccountDetailsFromUserDropdown,
    manualEditAddress,
    manageUserChangeSortOrder,
    viewManageUsers,
    viewManageUsersFromUserDropdown,
    viewManageBranding,
    viewManageBrandingFromUserDropdown,
    viewIntegrations,
    manageUserEvent,
    manageUserRemoveUser,
    updateReportSection,
    deleteTheme,
    addNewTheme,
    editTheme,
    activateTheme,
    uploadLogo,
    updateProfileImage,
    launchOTH,
    changeMapView,
    generateTitleDetailReport,
    changeMapDate,
    trackRollValuationSearch,
    saveSearch,
    removeSavedSearch,
    openSavedSearch,
    viewNotificationBell,
    updateSavedSearch,
    removeRecentSearch,
    getEmailDigestValue,
    suburbStatisticReport,
    changeSectionOrder,
    changePerPage,
    navigateToNZTitlesFromUserPanel,
    recordSavedList,
    createSavedList,
    addOrRemoveToList,
    integrations,
    advanceFiltersClick,
    searchFilter,
    viewExpandedMap,
    viewPropertyBubbleMap,
    selectProperty,
    changeMapViewSearchResult,
};
