import { call, put, takeLatest, select, take } from 'redux-saga/effects';
import UserPreferences from '../api/userPreferences';
import * as WatchListAction from '../actions/watchList';
import * as ClapiAction from '../actions/clapi';
import {
    getRapidSearchResult,
} from 'js/sagas/searchResults';
import { cruxAppError } from '../actions/errorHandler';
import ErrorMsg from '../constants/errorMsg';
import { ErrorType } from '../constants/redirect';
import Bugsnag from '../bugsnag';
import { selectSelectedAccount } from '../selectors/linkedAccount';
import { UPDATE_SAVED_LIST_FILTERS_LIMIT } from '../actions/clapi';
import getSearchFiltersLimit from '../selectors/getSearchFiltersLimit';
import { GET_SAVED_LIST_INFO_MULTIPLE, GET_SAVED_LIST_INFO_MULTIPLE_SUCCESS } from '../actions/watchList';
import EntitlementsHelper from '../helpers/Entitlements';
import RapidSearchConstant from '../constants/rapidSearch';

export const getListContent = state => state.watchList.get('listContent');

const getType = (type) => type === 'saved'
    ? 'getPropertiesOnSavedList' :
    'getPropertiesOnWatchList';

const featureAllowed = (state) => {
    return EntitlementsHelper.hasSavedListRole(state.clapi.get('usrDetail'));
};

const _getSavedList = () => function* (successType, failType, action) {
    try {
        const page = action?.payload?.params?.page || 0;
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const savedList = yield call(
            UserPreferences.getSavedLists,
            selectedClAppAccountUserGuid,
            action?.payload?.params,
        );
        yield put({
            type: successType,
            payload: { ...savedList, page },
        });
    } catch (error) {
        yield put({ type: failType });
    }
};

const _getWatchListCount = () => function* () {
    try {
        const state = yield select()

        // will not proceed if user is not entitled or launch darkly flag is off
        if (!featureAllowed(state)) return;

        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const watchListResponse = yield call(
            UserPreferences.getPropertiesCountOnWatchList,
            selectedClAppAccountUserGuid,
        );
        yield put({
            type: WatchListAction.GET_WATCHLIST_COUNT_SUCCESS,
            payload: watchListResponse,
        });
    } catch (error) {
        yield put({ type: WatchListAction.GET_WATCHLIST_COUNT_FAIL });
    }
};

const _getPropertiesOnSavedOrWatchList = () => function* (action) {
    try {
        const {
            type,
            savedListId,
            params,
        } = action.payload;
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const watchListResponse = yield call(
            UserPreferences[getType(type)],
            selectedClAppAccountUserGuid,
            savedListId,
            params,
        );

        yield put({
            type: params?.page > 0 ? WatchListAction.GET_MORE_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS :
                WatchListAction.GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS,
            payload: watchListResponse,
        });
    } catch (error) {
        yield put({ type: WatchListAction.GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL });
        yield put(cruxAppError(ErrorMsg.UNAVAILABLE, ErrorType.PAGE_ERROR));
    }
};

const updateSavedOrWatchListLimitFn = () => function* (action) {
    const listContent = yield select(getListContent);
    const { savedListId } = listContent;

    const payload = {
        params: {
            page: 0,
            size: action.payload, // payload is the page limit
        },
        type: !!savedListId ? 'saved' : 'watch',
        savedListId,
        filters: {
            sort: RapidSearchConstant.SEARCH_DEFAULT_SORT.ADDRESS,
        },
    };

    yield call(
        getRapidPropertiesOnSavedOrWatchList,
        WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS,
        WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL,
        ClapiAction.GET_SAVED_LIST_RESULT_SUCCESS,
        { payload },
    );
};

const showMoreSavedOrWatchListLimitFn = () => function* () {
    const limit = yield select(getSearchFiltersLimit);
    const listContent = yield select(getListContent);
    const { savedListId, page = 0 } = listContent;

    const payload = {
        params: {
            page: page + 1,
            size: limit,
        },
        type: !!savedListId ? 'saved' : 'watch',
        savedListId,
        filters: {},
    };

    yield call(
        getRapidPropertiesOnSavedOrWatchList,
        WatchListAction.GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS,
        WatchListAction.GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL,
        ClapiAction.SHOW_MORE_SUCCESS,
        { payload },
    );
};

const getSavedListInfoMultipleFn = () => function* (action) {
    try {
        const state = yield select();

        // will not proceed if user is not entitled or launch darkly flag is off
        if (!featureAllowed(state)) return;

        const sub = selectSelectedAccount(state)?.selectedClAppAccountUserGuid;
        const savedListsInfo = yield call(
            UserPreferences.getSavedPropertyInfo,
            sub,
            action.payload,
            true
        );

        yield put({
            type: GET_SAVED_LIST_INFO_MULTIPLE_SUCCESS,
            payload: savedListsInfo,
        });
    } catch (error) {
        yield put({ type: WatchListAction.GET_SAVED_LIST_INFO_MULTIPLE_FAIL });
    }
}

const _getRapidPropertiesOnSavedOrWatchList = () => function* (successType, failType, rapidType, action) {
    try {
        const { params, filters, ...rest } = action.payload;
        const page = params?.page ?? 0;
        const limit = params?.size ?? 20;
        yield put(WatchListAction.getPropertiesOnSavedOrWatchList({
            ...rest,
            params: {
                ...params,
                page,
            },
        }));

        yield take(page > 0 ? WatchListAction.GET_MORE_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS
            : WatchListAction.GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS);

        const listContentNew = yield select(getListContent);
        const propertyIds =  page
            ? listContentNew?.propertyIds?.slice(page * limit)
            : listContentNew?.propertyIds;

        yield put({
            type: ClapiAction.SET_SAVED_LIST_FILTERS,
            payload: {
                ...filters,
                propertyIds,
            },
        });

        if (propertyIds?.length) {
            yield call(
                getRapidSearchResult,
                rapidType,
                ClapiAction.GET_SAVED_LIST_RESULT_FAILED,
            );

            yield put({
                type: successType,
                payload: page,
            });
        } else {
            yield put({
                type: rapidType,
                payload: {
                    data: [],
                    metaData: { totalElements: 0 },
                },
            });
        }
    } catch (error) {
        Bugsnag.notify(error);
        yield put(cruxAppError(ErrorMsg.UNAVAILABLE, ErrorType.PAGE_ERROR));
        yield put({ type: failType });
    }
};

export const getSavedList = _getSavedList();
export const getWatchListCount = _getWatchListCount();
export const getPropertiesOnSavedOrWatchList = _getPropertiesOnSavedOrWatchList();
export const getRapidPropertiesOnSavedOrWatchList = _getRapidPropertiesOnSavedOrWatchList();
export const updateSavedOrWatchListLimit = updateSavedOrWatchListLimitFn();
export const showMoreSavedOrWatchListLimit = showMoreSavedOrWatchListLimitFn();
export const getSavedListInfoMultiple = getSavedListInfoMultipleFn();

export function* getSavedListhWatcher() {
    yield takeLatest(
        WatchListAction.GET_SAVED_LIST,
        getSavedList,
        WatchListAction.GET_SAVED_LIST_SUCCESS,
        WatchListAction.GET_SAVED_LIST_FAIL,
    );
}

export function* getMoreSavedListWatcher() {
    yield takeLatest(
        WatchListAction.GET_MORE_SAVED_LIST,
        getSavedList,
        WatchListAction.GET_MORE_SAVED_LIST_SUCCESS,
        WatchListAction.GET_MORE_SAVED_LIST_FAIL,
    );
}

export function* searchSavedListWatcher() {
    yield takeLatest(
        WatchListAction.SEARCH_SAVED_LIST,
        getSavedList,
        WatchListAction.SEARCH_SAVED_LIST_SUCCESS,
        WatchListAction.SEARCH_SAVED_LIST_FAIL,
    );
}

export function* getWatchListCountWatcher() {
    yield takeLatest(WatchListAction.GET_WATCHLIST_COUNT, getWatchListCount);
}

export function* getPropertiesOnSavedOrWatchListWatcher() {
    yield takeLatest(WatchListAction
        .GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST, getPropertiesOnSavedOrWatchList);
}

export function* getRapidPropertiesOnSavedOrWatchListWatcher() {
    yield takeLatest(
        WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST,
        getRapidPropertiesOnSavedOrWatchList,
        WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS,
        WatchListAction.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL,
        ClapiAction.GET_SAVED_LIST_RESULT_SUCCESS
    );
}

export function* getMoreRapidPropertiesOnSavedOrWatchListWatcher() {
    yield takeLatest(
        WatchListAction.GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST,
        showMoreSavedOrWatchListLimit,
    );
}

export function* updateSavedOrWatchListLimitWatcher() {
    yield takeLatest(
        UPDATE_SAVED_LIST_FILTERS_LIMIT,
        updateSavedOrWatchListLimit
    );
}

export function* getSavedListInfoMultipleWatcher() {
    yield takeLatest(
        GET_SAVED_LIST_INFO_MULTIPLE,
        getSavedListInfoMultiple,
    );
}

export default [
    getSavedListhWatcher(),
    getWatchListCountWatcher(),
    getMoreSavedListWatcher(),
    searchSavedListWatcher(),
    getPropertiesOnSavedOrWatchListWatcher(),
    getRapidPropertiesOnSavedOrWatchListWatcher(),
    getMoreRapidPropertiesOnSavedOrWatchListWatcher(),
    updateSavedOrWatchListLimitWatcher(),
    getSavedListInfoMultipleWatcher(),
];
