import { Autocomplete, styled } from '@mui/material';
import Colors from '../../../../constants/colors';

export const SearchBarContainer = styled('div')({
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
});
export const SuggestHeaderContainer = styled('div')({
    marginTop: '10px',
    '& h3': {
        marginTop: '5px',
    },
    '& .historic-checkbox': {
        marginLeft: '20px',
        '& .MuiFormControlLabel-root': {
            marginRight: '8px',
        },
        '& > label': {
            '& span:not(.Mui-checked)': {
                color: Colors.SECONDARY.MAIN,
            },
        },
        '& .tool-tip-component': {
            '& .icon-container': {
                marginBottom: '4px',
                color: Colors.SECONDARY.MAIN,
            },
        },
    },
});

export const StyledAutoComplete = styled(Autocomplete, {
    shouldForwardProp: (prop) => !['isSearchBarActive', 'isError', 'minWidth']
        .includes(prop),
})((props) => {
    const tenureErrorStyles = typeof isError !== 'undefined' && !props.isError
        ? ({
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            border: `1px solid ${Colors.ERROR.TEXT}`,
        })
        : {};
    return {
        flexGrow: 1,
        marginRight: '9px',
        minWidth: props.minWidth || '626px',
        '& .MuiAutocomplete-tag': {
            maxWidth: props.isSearchBarActive ? "auto" : '200px',
            overflow: 'hidden',
        },
        '& .MuiInputBase-root': {
            fontSize: '12px',
            backgroundColor: 'white',
            minHeight: '40px',
            maxWidth: '957px',
            letterSpacing: '0.4px',
            '& input': {
                '&::placeholder': {
                    color: Colors.PLACE_HOLDER_TEXT,
                    opacity: 1,
                }
            },
        },
        '& .MuiChip-root': {
            height: '26px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: '3px',
            paddingBottom: '3px',
            borderRadius: '4px',
            ...tenureErrorStyles,
            'div:not(.tooltip-popper) .field-error': {
                position: 'relative',
            },
        },
        '& .Mui-disabled': {
            backgroundColor: Colors.DISABLED,
        },
    }
});

export const StyledOption = styled('li')({
    fontFamily: '"Poppins", sans-serif',
    color: Colors.SECONDARY.MAIN,
    fontSize: '13px',
    letterSpacing: '.3px',
    lineHeight: '1px',
});

export const StyledNoRecentSearchOption = styled('li')({
    fontFamily: '"Poppins", sans-serif',
    color: Colors.SECONDARY.MAIN,
    fontSize: '14px',
    letterSpacing: '.3px',
    lineHeight: '1px',
    '&.MuiAutocomplete-option.Mui-focused': {
        backgroundColor: Colors.WHITE,
    },
});

export const SuggestionIcon = styled('img')({
    width: '20px',
    height: '20px',
    display: 'block',
    marginRight: '15px',
});

export const SuggestionHeader = styled('div')({
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
});

export const SuggestionHeaderText = styled('h3')({
    fontSize: '12px',
    fontWeight: '400',
    color: Colors.BLACK_60_OPAQUE,
    letterSpacing: '1px',
    marginLeft: '16px',
    textTransform: 'uppercase',
});

export const StyledButton = styled('button')({
    position: 'relative',
    border: 'none',
    background: 'transparent',
    marginRight: '7px',
    marginTop: '-7px',
    '& svg': {
        color: Colors.BLACK_54_OPAQUE,
    },
})

export const EndAdornmentContainer = styled('span')({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
});

export const StyledOptionText = styled('span')({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 'normal',
});

export default {};
