import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SuggestionTypes from 'js/constants/suggestionTypes';
import {
    getAddressCollective,
    CLEAR_ADDRESS_COLLECTIVE,
} from 'js/actions/clapi';
import { routeCodes } from 'js/constants/routes';
import useRedirection from 'js/hooks/multiLocality/useRedirection';
import { STATUS_TYPE_OPTIONS_ADDRESS } from 'js/constants/rapidSearch';

function useAddressSuggestionType(props) {
    const dispatch = useDispatch();
    const address = useSelector(state => state.rapid.get('address'));
    const history = useHistory();

    const redirect = useRedirection({
        searches: [props.optionSelected],
        statusType: STATUS_TYPE_OPTIONS_ADDRESS[0].value,
        isHistoric: props.isHistoric,
        trackSearchEvent: props.trackSearchEvent,
        entryPoint: props.entryPoint,
    });

    const clearAddressCollective = () => dispatch({
        type: CLEAR_ADDRESS_COLLECTIVE,
    });

    const redirectPdp = (propertyId) => {
        clearAddressCollective();
        props.trackSearchEvent && props.trackSearchEvent(SuggestionTypes.ADDRESS, true);
        history.push({
            pathname: routeCodes.PROPERTY.path(props.optionSelected?.suggestion, propertyId),
            search: props.historic && `?includeHistoric=${props.historic}`,
            state: {
                suggestionType: props.optionSelected?.suggestionType,
                entryPoint: props.entryPoint,
                isActiveProperty: props.optionSelected?.isActiveProperty,
            },
        });
    };

    useEffect(() => {
        if (props.optionSelected?.suggestionType === SuggestionTypes.ADDRESS) {
            // making sure that the address is not the same
            if (props.optionSelected.suggestion !== props.searchParams?.rawSuggestions) {
                dispatch(getAddressCollective(props.optionSelected, props.historic));
            }
        }
    }, [props.optionSelected]);

    useEffect(() => {
        if (address?.metadata?.totalElements === 1 && address?.data?.length === 1) {
            const propertyId = address?.data[0].id;
            redirectPdp(propertyId);
        } else if (address?.metadata) {
            clearAddressCollective();
            redirect();
        }
    }, [address]);
}

useAddressSuggestionType.propTypes = {
    entryPoint: PropTypes.string.isRequired,
    optionSelected: PropTypes.shape({
        suggestionType: PropTypes.string,
        suggestion: PropTypes.string,
        isActiveProperty: PropTypes.bool,
    }),
    historic: PropTypes.bool,
    trackSearchEvent: PropTypes.func,
}

export default useAddressSuggestionType;
