import { NZ_FLOOD_LAYER } from '../../../../constants/map';


const FLOOD_LAYER_COLOR = '#69a1cd';
const FLOOD_LAYER_STYLE = {
    fillOpacity: 0.8,
    fillColor: FLOOD_LAYER_COLOR,
    strokeColor: FLOOD_LAYER_COLOR,
    strokeWeight: 0,
};

const ZOOM_TO_GEO_PRECISION_MAP = {
    // zoomLevel: geometryPrecision
    11: 3,
    12: 4,
    13: 4,
    14: 5,
    15: 6,
    16: 8,
}

const clearCurrentGeoJson = (mapInstance) => {
    mapInstance?.data?.forEach(layer => {
        const layerId = layer.getId();
        // using the ids generated from getGeoJsonWithFeatureIds()
        if (layerId?.startsWith(NZ_FLOOD_LAYER)) {
            mapInstance.data?.remove(layer);
        }
    });
}

const handleAucklandFloodLayer = (params) => {
    clearCurrentGeoJson(params.mapInstance);
    params.mapInstance?.data?.addGeoJson(params?.geoJson);
    params.mapInstance?.data?.setStyle(FLOOD_LAYER_STYLE);
}

const getGeometryPrecisionByZoomLevel = (zoomLevel = 18) => {
    return ZOOM_TO_GEO_PRECISION_MAP[zoomLevel] || null;
};

const getGeoJsonWithFeatureIds = (geoJson) => ({
    ...geoJson,
    features: geoJson.features?.map((currentFeature, index) => {
        return {
            ...currentFeature,
            // used for look-ups when clearing a geo-json
            id: `${NZ_FLOOD_LAYER}-${index}`,
        };
    }),
})

export default {
    handleAucklandFloodLayer,
    getGeometryPrecisionByZoomLevel,
    getGeoJsonWithFeatureIds,
}
