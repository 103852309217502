import moment from 'moment';
import SearchFilterDecoder from 'js/helpers/search/SearchFilterDecoder';
import urlKeys from 'js/constants/rapidSearch/urlKeys';
import DateFilter from 'js/constants/rapidSearch/dateFilter';
import SuggestionTypes from 'js/constants/suggestionTypes';
import OTM from 'js/constants/otm';
import Entitlements from 'js/helpers/Entitlements';
import ExcludeVICFeatureFlag from 'js/model/launchDarkly/ExcludeVICFeatureFlag';
import AddressHelper from 'js/helpers/Address';
import Store from 'js/store';
import AdvancedFilters from 'js/constants/rapidSearch/advancedFilters';

const extract = (search = '') => Object.fromEntries(new URLSearchParams(search));

const tenureDataParser = (key, value, source) => {
    if (value.holdPeriodHouse) source.holdPeriodHouse = value.holdPeriodHouse;
    if (value.holdPeriodUnit) source.holdPeriodUnit = value.holdPeriodUnit;
};

const dateRangeParser = ({ min, max }) => {
    const minDate = moment(min);
    const maxDate = moment(max);
    const today = moment();

    const monthDifference = maxDate.diff(minDate, 'months');
    const definedMonths = DateFilter.DATE_DIFF_LABEL[monthDifference];
    const isMaxToday = maxDate.isSame(today, 'day');
    return isMaxToday && definedMonths ? definedMonths : `${min} - ${max}`;
}

const dateParser = (key, value, source, option = 'dateFilterSelectOption') => {
    source[key] = value;
    source[option] = dateRangeParser(value);
};

const salesLastCampaignStartDateParser = (key, value, source) =>
    dateParser(key, value, source, 'salesLastCampaignStartDateOption');

const rentalLastCampaignStartDateParser = (key, value, source) =>
    dateParser(key, value, source, 'rentalLastCampaignStartDateOption');
const salesLastSaleSettlementDateParser = (key, value, source) =>
    dateParser(key, value, source, 'salesLastSaleSettlementDateOption');

const unimprovedCapitalValueDateParser = (key, value, source) =>
    dateParser(key, value, source, 'unimprovedCapitalValueDateOption');

const valuationAssessmentDateParser = (key, value, source) =>
    dateParser(key, value, source, 'valuationAssessmentDateOption');

// Use this object if you want to transpose the key and value based on the value received
// See dateParser() adding another field using it own original field
const parser = {
    tenureData: tenureDataParser,
    salesLastSaleContractDate: dateParser,
    salesLastCampaignEndDate: dateParser,
    rentalLastCampaignEndDate: dateParser,
    salesLastCampaignStartDate: salesLastCampaignStartDateParser,
    rentalLastCampaignStartDate: rentalLastCampaignStartDateParser,
    salesLastSaleSettlementDate: salesLastSaleSettlementDateParser,
    unimprovedCapitalValueDate: unimprovedCapitalValueDateParser,
    valuationAssessmentDate: valuationAssessmentDateParser,
};

// Handle ADVANCED_FILTERS entitlements
// Will strip off the params if not entitled
const formatterWithEntitlementsCheck = (formatter, params, key) => {
    const usrDetail = Store?.store?.getState()?.clapi?.get('usrDetail');
    const entitled = Entitlements.hasAdvancedFiltersRole(usrDetail);
    if (entitled) {
        return formatter(params[key])
    }
    return AdvancedFilters.includes(key) ? null : formatter(params[key])
};
const parseUrlParams = (search = '', searchType = 'address') => {
    const paramsObject = extract(search);
    const statusType = SearchFilterDecoder.statusType(paramsObject.statusType);
    const filters = Object.keys(paramsObject).reduce((acc, key) => {
        const formatter = SearchFilterDecoder[searchType][statusType][key]; // getting right formatter
        const filterKey = urlKeys[key] || key; // Check and get custom key
        // Get right value
        const value = formatter && formatterWithEntitlementsCheck(formatter, paramsObject, key);

        // Only add the value if it's defined or non-null
        if (value !== undefined && value !== null && value !== '') {
            if (parser[filterKey]) {
                parser[filterKey](filterKey, value, acc);
            } else {
                acc[filterKey] = value;
            }
        }
        return acc;
    }, {});

    if (filters.active === undefined) {
        filters.active = true;
    }

    if (!filters.statusType) {
        filters.statusType = OTM.ALL;
    }
    return filters;
};

const isTenureAllowed = (featureFlag, isVic, hasProspectorRole) => {
    if (featureFlag.hasValue()) {
        if (featureFlag.isTrue()) return true;
        if (featureFlag.isEntitlements()) return hasProspectorRole;
        return !(featureFlag.isFalse() || (featureFlag.isExcludeVICEntitlements() && isVic));
    }
    return false;
};

const invalidTenureSearch = (params, tenureFlag, hasProspectorRole) => {
    const { averageHoldPeriod, statusType, sType, suggestions } = params;
    const isVic = AddressHelper.isVictoria(suggestions);
    const featureFlag = new ExcludeVICFeatureFlag(tenureFlag);

    const tenureAllowed = isTenureAllowed(featureFlag, isVic, hasProspectorRole);

    const hasMultipleSuggestions = suggestions?.split('|')?.length > 1;
    const isInvalidType = !sType
        || [SuggestionTypes.STREET, SuggestionTypes.ADDRESS].includes(sType)

    return (hasMultipleSuggestions || !averageHoldPeriod || isInvalidType || !tenureAllowed)
        && statusType === OTM.TENURE;
};

const invalidAgedOrWithdrawnSearch = (statusType, hasProspectorRole) =>
    [OTM.AGED, OTM.WITHDRAWN].includes(statusType) && !hasProspectorRole;


const specialSearch = (statusType) =>
    [OTM.AGED, OTM.WITHDRAWN, OTM.TENURE].includes(statusType);

const extractStatusTypeRegularSearch = (statusType) =>
    specialSearch(statusType) ? OTM.ALL : statusType;

const extractStatusTypeSpecialSearch = (params, usrDetails, tenureFlag) => {
    const hasProspectorRole = Entitlements.hasProspectorRole(usrDetails);
    if (invalidTenureSearch(params, tenureFlag, hasProspectorRole)
        || invalidAgedOrWithdrawnSearch(params.statusType, hasProspectorRole)) {
        return OTM.ALL;
    }
    return params.statusType;
}

const extractStatusTypeSpecialSearchWithoutTenure = (statusType, usrDetails) => {
    const hasProspectorRole = Entitlements.hasProspectorRole(usrDetails);
    return invalidAgedOrWithdrawnSearch(statusType, hasProspectorRole) ? OTM.ALL : statusType;
}

export default {
    extract,
    parseUrlParams,
    extractStatusTypeRegularSearch,
    extractStatusTypeSpecialSearch,
    extractStatusTypeSpecialSearchWithoutTenure,
};
