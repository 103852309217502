export const SYNC_MEMBERSHIP_STATUS = 'SYNC_MEMBERSHIP_STATUS';
export const SYNC_MEMBERSHIP_STATUS_SUCCESS = 'SYNC_MEMBERSHIP_STATUS_SUCCESS';
export const SYNC_MEMBERSHIP_STATUS_FAIL = 'SYNC_MEMBERSHIP_STATUS_FAIL';
export const CONNECT_REINZ = 'CONNECT_REINZ';
export const CONNECT_REINZ_SUCCESS = 'CONNECT_REINZ_SUCCESS';
export const CANCEL_REINZ = 'CANCEL_REINZ';
export const CANCEL_REINZ_SUCCESS = 'CANCEL_REINZ_SUCCESS';
export const CANCEL_REINZ_FAIL = 'CANCEL_REINZ_FAIL';

export const SET_REINZ_INTEGRATION_STATUS = 'SET_REINZ_INTEGRATION_STATUS';

export const syncReinzMembershipStatus = () => ({
    type: SYNC_MEMBERSHIP_STATUS,
});

export const addReinzMembership = (payload) => ({
    type: CONNECT_REINZ,
    payload,
});

export const connectionSuccess = (payload) => ({
    type: CONNECT_REINZ_SUCCESS,
    payload,
});

export const cancelReinzMembership = () => ({
    type: CANCEL_REINZ,
});

export const cancelSuccess = (payload) => ({
    type: CANCEL_REINZ_SUCCESS,
    payload,
});

export const cancelFail= () => ({
    type: CANCEL_REINZ_FAIL,
});

export const setReinzIntegrationStatus = (payload) => ({
    type: SET_REINZ_INTEGRATION_STATUS,
    payload,
});