import moment from 'moment';
import {
    SUBURB_STAT_DISPLAY_OPTIONS, SUBURB_STAT_OPTIONS_SORT, SUBURB_STATS_TOGGLE_IDS,
    SUBURB_STATS_REPORT_OPTIONS, LGA_TITLE, LGA_NAME_NODE,
    SSR_PROPERTY_TYPE_LIST, MARKET_TRENDS_METRIC_TYPES_CARDS, MARKET_TRENDS_METRIC_TYPES_QUARTILES, PROPERTY_TYPE_IDS,
} from '../Localization';
import SuburbStatConst from '../../constants/suburbStatReport';
import SuburbStatBuilder from './SuburbStatBuilder'
import Commons from '../Commons';
import { isAU, isNZ } from '../../constants/crux';
import SuburbStatReport from '../../constants/suburbStatReport';
import { PANEL_API_ERROR } from '../../constants/tooltipText';
import STATES from '../../constants/states';

const YEAR_FORMAT = 'YYYY';
const MONTH_FORMAT = 'MMM YYYY';
const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';

const getInitialCheckList = (userSettings) => {
    const printOptions = Commons.getUserSetting(userSettings, SUBURB_STAT_DISPLAY_OPTIONS);
    const checkList = {};

    // Iterating through checklist object and getting initial value based on user settings value
    Object.values(SUBURB_STATS_TOGGLE_IDS).forEach((value) => {
        checkList[value] = typeof printOptions[value] === 'undefined' || printOptions[value];
    });
    return checkList;
};

const getInitialSort = (userSettings) => {
    const userSettingsValues = Commons
        .getUserSetting(userSettings, SUBURB_STAT_OPTIONS_SORT, []);
    const { items } = SUBURB_STATS_REPORT_OPTIONS.sortableOptions;
    // Removes user setting that is not part of of the
    // ReportConst.SUBURB_STATS_REPORT_OPTIONS.sortableOptions.items
    // We need to remove it or it will mess up the ordering since we rely on index
    const filteredUserSettings =
        userSettingsValues.filter((sortedItem) => {
            return items.find((item) => sortedItem.id === item.id)
        });
    const newOptions = [];
    const newSortOrder =  items.reduce((a, c) => {
        const index = filteredUserSettings.findIndex(sortedItem => sortedItem.id === c.id);
        // Collecting objects that is not currently in saved sort order
        if (index < 0) {
            newOptions.push(c);
        } else {
            a[index] = c;
        }

        return a;
    }, []);

    // Appending collected unlisted object insider sorted
    return [...newSortOrder, ...newOptions];
};

const rentalStatisticsParamsBuilder = (locationId, councilAreaIds = []) => {
    let paramsArr = [];
    const fromDate = moment().subtract(1, 'years').format(YEAR_MONTH_DAY_FORMAT);
    const toDate = moment().format(YEAR_MONTH_DAY_FORMAT);
    PROPERTY_TYPE_IDS.forEach((propertyTypeId) => {
        SuburbStatReport.RENTAL_STATISTICS_METRIC_TYPES.forEach((metricTypeId) => {
            paramsArr.push({
                locationId,
                locationTypeId: '8',
                propertyTypeId,
                metricTypeId,
            }, {
                locationId,
                locationTypeId: '8',
                propertyTypeId,
                fromDate,
                toDate,
                metricTypeId,
                interval: '1',
            });
        });
    });
    councilAreaIds.forEach(councilAreaId => {
        PROPERTY_TYPE_IDS.forEach((propertyTypeId) => {
            SuburbStatReport.RENTAL_STATISTICS_METRIC_TYPES.forEach((metricTypeId) => {
                if (!(isAU && metricTypeId === SuburbStatReport.METRIC_TYPE_ID.valueBasedRentalYield)) {
                    paramsArr.push({
                        locationId: councilAreaId,
                        locationTypeId: isAU ? '3' : '9',
                        propertyTypeId,
                        fromDate,
                        toDate,
                        metricTypeId,
                        interval: '1',
                    });
                }
            });
        });
    });
    return paramsArr;
}

const getSuburbName_NZ = (suburbAddress, councilNames) => councilNames.reduce((a, council) => {
    if (suburbAddress?.includes(council)) {
        a = suburbAddress.replace(council, '').trim();
        if (a.includes(',')) {
            a = a.replace(',', '').trim();
        }
    }
    return a;
}, '-');

const getSuburbName_AU = (suburbAddress = '') => {
    const suburbPartials = suburbAddress.split(' ');
    for (const state of STATES) {
        const indexOfState = suburbPartials.indexOf(state);
        if (indexOfState !== -1) {
            suburbPartials.splice(indexOfState, Infinity)
            break;
        }
    }

    return suburbPartials.join(' ');
};

const getSuburbName = isAU ? getSuburbName_AU : getSuburbName_NZ;

const convertPrice = (price) => {
    let formattedPrice = `$${price}`;
    if (price > 999 && price <= 999999) {
        formattedPrice = `$${Math.trunc(price / 1000)}K`;
    } else if (price >= 1000000) {
        formattedPrice = `$${(price / 1000000).toFixed(2)}M`;
    } else if (price >= 1000000000) {
        formattedPrice= `$${(price / 1000000000).toFixed(2)}B`;
    }
    return formattedPrice;
}

const buildSalesByPrice = (locationId, councilAreaIds = []) => {
    const propertyTypes = ['1', '2', '3'];
    const fromDate = moment().subtract(1, 'years').format(YEAR_MONTH_DAY_FORMAT);
    const toDate = moment().format(YEAR_MONTH_DAY_FORMAT);
    return (
        propertyTypes.flatMap((propertyTypeId) => ([
            {
                locationId,
                propertyTypeId,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
                metricTypeId: SuburbStatConst.METRIC_TYPE_ID.totalValueOfSales,
            },
            {
                locationId,
                propertyTypeId,
                fromDate,
                toDate,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
                metricTypeGroupId: SuburbStatConst.METRIC_TYPE_GROUP_ID.SALES_BY_PRICE,
                interval: '12',
            },
            ...(councilAreaIds.map(councilAreaId => ({
                locationId: councilAreaId,
                propertyTypeId,
                fromDate,
                toDate,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.COUNCIL_AREA,
                metricTypeGroupId: SuburbStatConst.METRIC_TYPE_GROUP_ID.SALES_BY_PRICE,
                interval: '12',
            }))),
        ]))
    );
}

const buildSalesPerAnnum = (locationId, councilAreaIds = []) => {
    const propertyTypes = isAU ? ['1', '2', '3'] : ['3', '6'];
    const fromDate = moment().subtract(10, 'years').format(YEAR_MONTH_DAY_FORMAT);
    const toDate = moment().format(YEAR_MONTH_DAY_FORMAT);
    return (
        propertyTypes.flatMap((propertyTypeId) => ([
            {
                locationId,
                propertyTypeId,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
                metricTypeId: SuburbStatConst.METRIC_TYPE_ID.numberSold,
            },
            {
                locationId,
                propertyTypeId,
                fromDate,
                toDate,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
                metricTypeId: SuburbStatConst.METRIC_TYPE_ID.numberSold,
                interval: '12',
            },
            ...(councilAreaIds.map(councilAreaId => ({
                locationId: councilAreaId,
                propertyTypeId,
                fromDate,
                toDate,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.COUNCIL_AREA,
                metricTypeId: SuburbStatConst.METRIC_TYPE_ID.numberSold,
                interval: '12',
            }))),
        ]))
    );
}

const buildForSaleStatistics = (locationId, councilAreaIds = []) => {
    const propertyTypes = isAU ? ['1', '2', '3'] : ['3', '6'];
    const fromDate = moment().subtract(12, 'months').format(YEAR_MONTH_DAY_FORMAT);
    const toDate = moment().format(YEAR_MONTH_DAY_FORMAT);
    const { numberOfNewListing, totalPropertiesListed, medianDaysOnMarket } = SuburbStatConst.METRIC_TYPE_ID;
    const forSaleMetrics = {
        numberOfNewListing,
        totalPropertiesListed,
        medianDaysOnMarket,
    }
    return (
        Object.values(forSaleMetrics).flatMap(metric => (
            propertyTypes.flatMap((propertyTypeId) => ([
                {
                    locationId,
                    propertyTypeId,
                    locationTypeId: '8',
                    metricTypeId: metric,
                },
                {
                    locationId,
                    propertyTypeId,
                    fromDate,
                    toDate,
                    locationTypeId: '8',
                    metricTypeId: metric,
                    interval: '1',
                },
                ...(councilAreaIds.map(councilAreaId => ({
                    locationId: councilAreaId,
                    propertyTypeId,
                    fromDate,
                    toDate,
                    locationTypeId: isAU ? '3' : '9',
                    metricTypeId: metric,
                    interval: '1',
                }))),
            ]))
        ))
    );
}
const marketTrendsBuilder = (localityId) => [
        ...MARKET_TRENDS_METRIC_TYPES_CARDS,
        ...MARKET_TRENDS_METRIC_TYPES_QUARTILES,
    ]
    .map(({ id }) => SSR_PROPERTY_TYPE_LIST
        .map((propertyType) => ({
            locationId: localityId,
            locationTypeId: '8',
            propertyTypeId: propertyType,
            metricTypeId: id
        })))
    .flat();

const buildChangeInMedianValueStatistics = (locationId, councilAreaIds) => {
    const fromDate = moment().subtract(10, 'years').format(YEAR_MONTH_DAY_FORMAT);
    const toDate = moment().format(YEAR_MONTH_DAY_FORMAT);
    const interval = '12';

    return SuburbStatConst.COUNTRY_PROPERTY_TYPES.flatMap(propertyType => ([
            {
                propertyTypeId: propertyType.id,
                metricTypeId: SuburbStatConst.METRIC_TYPE_ID.changeInMedianValue,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
                locationId,
            },
            {
                propertyTypeId: propertyType.id,
                metricTypeId: SuburbStatConst.METRIC_TYPE_ID.changeInMedianValue,
                locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
                locationId,
                fromDate,
                toDate,
                interval,
            },
            ...(isNZ ? councilAreaIds?.map(councilAreaId => ({
                    propertyTypeId: propertyType.id,
                    metricTypeId: SuburbStatConst.METRIC_TYPE_ID.changeInMedianValue,
                    locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.COUNCIL_AREA,
                    locationId: councilAreaId,
                    fromDate,
                    toDate,
                    interval,
                }
            )): []),
        ])
    );
}

const buildStatisticsParams = (locationId, locationTypeId, propertyTypeId, metricTypeId) => ({
    locationId, locationTypeId, propertyTypeId, metricTypeId,
});

const buildStatisticsRangeParams = (
    locationId,
    locationTypeId,
    propertyTypeId,
    metricTypeId,
    fromDate,
    toDate,
    interval,
) => ({
    ...buildStatisticsParams(locationId, locationTypeId, propertyTypeId, metricTypeId),
    fromDate, toDate, interval,
});

const buildMedianValueStatistics = (locationId, councilAreaIds = []) => {
    const fromDate = moment().subtract(12, 'months').format(YEAR_MONTH_DAY_FORMAT);
    const fromDate10Yrs = moment().startOf('year').subtract(9, 'y').format(YEAR_MONTH_DAY_FORMAT);
    const toDate = moment().format(YEAR_MONTH_DAY_FORMAT);
    const toDate10Yrs = moment().format(YEAR_MONTH_DAY_FORMAT);
    const { medianValue, numberSold } = SuburbStatConst.METRIC_TYPE_ID;
    const { LOCALITY: locality, COUNCIL_AREA: councilArea } = SuburbStatConst.LOCATION_TYPE_ID;

    return SuburbStatConst.COUNTRY_PROPERTY_TYPES.flatMap(({ id: propertyTypeId }) => ([
        buildStatisticsParams(locationId, locality, propertyTypeId, medianValue),
        buildStatisticsRangeParams(locationId, locality, propertyTypeId, medianValue, fromDate, toDate, '1'),
        ...(isAU ? [] : councilAreaIds.map(councilAreaId =>
            buildStatisticsRangeParams(councilAreaId, councilArea, propertyTypeId, medianValue, fromDate, toDate, '1'))),
        buildStatisticsParams(locationId, locality, propertyTypeId, numberSold),
        buildStatisticsRangeParams(locationId, locality, propertyTypeId, numberSold, fromDate, toDate, '1'),
        buildStatisticsRangeParams(locationId, locality, propertyTypeId, medianValue, fromDate10Yrs, toDate10Yrs, '12'),
        ...(isAU ? [] :councilAreaIds.map(councilAreaId =>
            buildStatisticsRangeParams(councilAreaId, councilArea, propertyTypeId, medianValue, fromDate10Yrs, toDate10Yrs, '12'))),
    ]));
}

const buildAreaProfile = (locationId) => (SuburbStatConst.COUNTRY_PROPERTY_TYPES.flatMap(propertyType => ([
    {
        propertyTypeId: propertyType.id,
        metricTypeId: SuburbStatConst.METRIC_TYPE_ID.averageTenurePeriod,
        locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
        locationId,
    },
    {
        propertyTypeId: propertyType.id,
        metricTypeId: SuburbStatConst.METRIC_TYPE_ID.numberOfNewListing,
        locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
        locationId,
    },
    {
        propertyTypeId: propertyType.id,
        metricTypeId: SuburbStatConst.METRIC_TYPE_ID.medianValue,
        locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
        locationId,
    },
    {
        propertyTypeId: propertyType.id,
        metricTypeId: SuburbStatConst.METRIC_TYPE_ID.totalPropertiesListed,
        locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
        locationId,
    },
])));

const statsBuilder = (locationId, councilAreaIds) => ({
    ...(isAU ? { salesByPrice: buildSalesByPrice(locationId, councilAreaIds) }
        : { salesPriceCvRatio: SuburbStatBuilder.salePriceCvRatioBuilder(locationId, councilAreaIds) }),
    areaProfile: buildAreaProfile(locationId),
    salesPerAnnum: buildSalesPerAnnum(locationId, councilAreaIds),
    medianValue: buildMedianValueStatistics(locationId, councilAreaIds),
    forSaleStatistics: buildForSaleStatistics(locationId, councilAreaIds),
    rentalStatistics: rentalStatisticsParamsBuilder(locationId, councilAreaIds),
    changeInMedianValue: buildChangeInMedianValueStatistics(locationId, councilAreaIds),
    marketTrends: marketTrendsBuilder(locationId),
})

const buildCensusStatsRequest = (localityId, councilAreaIds, metricTypeGroupId) => {
    const partialRequestBody = [{
        locationId: localityId,
        locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.LOCALITY,
        metricTypeGroupId,
    }];

    councilAreaIds?.forEach((councilAreaId) => {
        partialRequestBody.push({
            locationId: councilAreaId,
            locationTypeId: SuburbStatConst.LOCATION_TYPE_ID.COUNCIL_AREA,
            metricTypeGroupId,
        })
    });

    return partialRequestBody;
};


const censusBuilder = (localityId, councilAreaIds) => {
    const _buildCensusStatsRequest = (metricTypeId) => buildCensusStatsRequest(
        localityId,
        councilAreaIds,
        metricTypeId,
    );

    const {
        HOUSEHOLD_INCOME,
        POPULATION_AGE,
        HOUSEHOLD_STRUCTURE,
        EDUCATION,
        OCCUPATION,
    } = SuburbStatConst.METRIC_TYPE_GROUP_ID;

    return {
        householdIncome: _buildCensusStatsRequest(HOUSEHOLD_INCOME),
        populationAge: _buildCensusStatsRequest(POPULATION_AGE),
        householdStructure: _buildCensusStatsRequest(HOUSEHOLD_STRUCTURE),
        education: buildCensusStatsRequest(localityId, councilAreaIds, EDUCATION),
        occupation: _buildCensusStatsRequest(OCCUPATION),
    };
};

const byLocality = statistic => statistic.locationType === 'Locality';
const byLGA = statistic => statistic.locationType === LGA_TITLE;

const parseLocalityCouncilAreaDataTable = (statistics) => {
    const _statistics = JSON.parse(JSON.stringify(statistics));
    const joinCouncilAreaByMetricTypeId = statistic => Object.assign(statistic, {
        councilAreaList: _statistics
            .filter(_statistic => _statistic.locationType === LGA_TITLE
                && _statistic.metricTypeId === statistic.metricTypeId)
    });
    return _statistics.filter(byLocality)
        .map(joinCouncilAreaByMetricTypeId);
};

const isLocationTypeLocality = statistic => statistic.locationType === 'Locality';
const isLocationTypeCouncilArea = statistic => (isAU ?
    statistic.locationType === 'Council Area' : statistic.locationType === 'Territorial Authority');

const getLGARowCount = (statistics) => {
    const LGAnames = Array.from(new Set(statistics.filter(byLGA).map(lga => lga[LGA_NAME_NODE])));
    let lgaSize = 0;
    LGAnames.forEach(lgaName => {
        const size = statistics.filter(stat => stat[LGA_NAME_NODE] === lgaName).length;
        if (size > lgaSize) {
            lgaSize = size;
        }
    });
    return lgaSize;
}
const buildLocalityAndLGADataTable = (statistics, suburbName = '') => {
    const _statistics = JSON.parse(JSON.stringify(statistics));
    const generated = [];
    const lgaSize = getLGARowCount(_statistics);
    const locality = _statistics.filter(byLocality);
    const lga = _statistics.filter(byLGA);
    const totalElements = locality.length > lgaSize ? locality.length : lgaSize;
    const localityName = getSuburbName(suburbName, lga.map(_lga => _lga[LGA_NAME_NODE]));
    for (let i = 0; i <= totalElements; i += 1) {
        const byMetricTypeOrderId = _lga => _lga.metricTypeOrderId === (i + 1);
        const metricType = _statistics.find(byMetricTypeOrderId);
        if (metricType) {
            const metricTypeId = locality.find(byMetricTypeOrderId)?.metricTypeId || lga.find(byMetricTypeOrderId)?.metricTypeId;
            const metricTypeGroupId = locality.find(byMetricTypeOrderId)?.metricTypeGroupId || lga.find(byMetricTypeOrderId)?.metricTypeGroupId;
            generated.push({
                localityName: locality?.at(0)?.localityName || localityName,
                locationType: 'Locality',
                metricTypeShort: locality.find(byMetricTypeOrderId)?.metricTypeShort || lga.find(byMetricTypeOrderId)?.metricTypeShort,
                metricTypeDescription: locality.at(0)?.metricTypeDescription || lga.at(0)?.metricTypeDescription,
                seriesDataList: locality.find(byMetricTypeOrderId)?.seriesDataList || [],
                metricTypeId,
                metricTypeGroupId,
            });
        }
    }

    return parseLocalityCouncilAreaDataTable(generated.concat(lga));
};

const formatDollarInTable = (value) => {
    if (!value) {
        return '-';
    }
    return `$${Commons.numberWithCommas(value.toFixed(0))}`;
};
const toPercent = (text) => text ? `${text}%` : '-';
const byCheckedPropertyType = type => type.at(0) !== 'all' && !!type.at(1);
const allPropertyTypeChecked = type => {
    if (type.at(0) === 'all') {
        return true;
    }
    return !!type.at(1);
};
const allPropertyTypeUnchecked = type => {
    if (type.at(0) === 'all') {
        return true;
    }
    return !type.at(1);
};

const convertDigitIntoPercentage = (digit) => digit ? `${(digit * 100).toFixed(1)}%`: '-';

const convertDateTimeIntoMonthYear = (dataTime) => moment(dataTime).format(MONTH_FORMAT);


const getMetricsByType = (data, type) => [
    ...MARKET_TRENDS_METRIC_TYPES_CARDS,
    ...MARKET_TRENDS_METRIC_TYPES_QUARTILES,
].reduce((a, c) => {
    const filtered = data
        .filter((datum) =>
            datum?.metricTypeId === c.id
            && datum?.propertyType === type
            && datum?.locationType === 'Locality'
            && datum?.interval === undefined)
    if (filtered.length && filtered[0]?.seriesDataList[0]?.value) {
        a.push(...filtered);
    }
    return a;
}, [])

const getQuartile = (stats) =>  MARKET_TRENDS_METRIC_TYPES_QUARTILES
    .reduce((a, c) => {
        const quartile = stats.find(_stat => _stat.metricTypeId === c.id);
        return [...a, {
            value: quartile?.seriesDataList?.length ? quartile.seriesDataList[0].value : '',
        }];
    }, []);

const getMarketTrendsCardsData = (stats) => MARKET_TRENDS_METRIC_TYPES_CARDS
    .reduce((a, c) => {
        const stat = stats.find(_stat => _stat.metricTypeId === c.id);
        const label =  c.type.split('(', 2);
        let metric = stat?.seriesDataList?.length
            ? Math.round(((stat.seriesDataList[0].value) + Number.EPSILON) * 10) / 10
            : '';
        if (c.id === MARKET_TRENDS_METRIC_TYPES_CARDS[1].id && metric) {
            metric = metric.toFixed(1);
        }
        if (c.id === MARKET_TRENDS_METRIC_TYPES_CARDS[2].id && metric) {
            metric = `$${metric}/w`;
        }
        return [...a, {
            metric,
            label: label[0].trim(),
            subLabel: label[1] ? `(${label[1]}` : '',
            id: Commons.replaceSpaceWithDash(c.type),
        }];
    }, []);

const findErrorNodesWithInterval = (key, errorsNode) => {
    const keyId = key.metricTypeId || key.metricTypeGroupId;
    return errorsNode.find(errorNode =>
        parseInt(errorNode.metricTypeId || errorNode.metricTypeGroupId) === (keyId)
        && errorNode.fromDate === key.fromDate
        && errorNode.toDate === key.toDate
        && errorNode.interval === key.interval
        && errorNode.locationTypeId === key.locationTypeId)
};

const findErrorNodesWithoutInterval = (key, errorsNode) => errorsNode
    .find(errorNode => parseInt(errorNode.metricTypeId) === key.metricTypeId
        && errorNode.propertyTypeId === key.propertyTypeId);

const getStatisticsErrorsLength = (metricTypes, errorsNode) => metricTypes.reduce((a, c) => {
    const foundNodes = c?.interval
        ? findErrorNodesWithInterval(c, errorsNode)
        : findErrorNodesWithoutInterval(c, errorsNode);

    if (foundNodes) {
        a.push(foundNodes);
    }

    return a;
}, []).length;

const getCensusErrorsLength = (metricTypes, errorsNode) => metricTypes.reduce((a, c) => {
    const foundNodes = errorsNode
        .find(errorNode => parseInt(errorNode.metricTypeGroupId) === c.metricTypeGroupId
            && errorNode.locationId === c.locationId
            && errorNode.locationTypeId === c.locationTypeId);

    if (foundNodes) {
        a.push(foundNodes);
    }

    return a;
}, []).length;

const validateStatsError = ({
    response,
    allRequestErrorSetter,
    sectionErrorSetter,
    seriesRequestObject,
    validator,
}) => {
    if (!response?.errors.length) return;
    const errorSections = Object
        .keys(seriesRequestObject)
        .reduce((a, c) => {
            let accumulator = a;
            const metricsErrorCount = validator(seriesRequestObject[c], response.errors);

            // checking if series request list per section is existing in the errors list
            if (metricsErrorCount === seriesRequestObject[c].length) {
                accumulator = {
                    // building checkboxStatus format
                    // ie. { areaProfile: {error message}, marketTrends: {error message} }
                    formattedError: {
                        ...a.formattedError,
                        [c]: PANEL_API_ERROR,
                    },
                    // Counting error nodes to compare later if it is equal to
                    // seriesRequestList count and we can trigger the stats error
                    totalErrorNodes: a.totalErrorNodes + metricsErrorCount,
                }
            }
            // merging total metricTypesPerSection overall length so we
            // don't have another loop just to get that
            accumulator = {
                ...accumulator,
                totalNodes: a.totalNodes + seriesRequestObject[c].length
            };
            return accumulator;
        }, { formattedError: {}, totalNodes: 0, totalErrorNodes: 0 });

    if (errorSections.totalNodes === errorSections.totalErrorNodes) {
        allRequestErrorSetter();
    }

    if (!Commons.isObjectEmpty(errorSections.formattedError)) {
        sectionErrorSetter(errorSections.formattedError);
    }
}

const validateCensus = ({
    response,
    allRequestErrorSetter,
    sectionErrorSetter,
    seriesRequestObject,
}) => {
    validateStatsError({
        response,
        allRequestErrorSetter,
        sectionErrorSetter,
        seriesRequestObject,
        validator: getCensusErrorsLength,
    });
};

const validateStatistics = ({
    response,
    allRequestErrorSetter,
    sectionErrorSetter,
    seriesRequestObject,
}) => {
    validateStatsError({
        response,
        allRequestErrorSetter,
        sectionErrorSetter,
        seriesRequestObject,
        validator: getStatisticsErrorsLength,
    });
};

const byIntervalListNotEmpty = (stats) => {
    const intervalSeriesList = stats?.filter(stat => stat.hasOwnProperty('interval'));
    if (intervalSeriesList?.length
        && intervalSeriesList.every(stat => !stat.seriesDataList.length)) {
        return [];
    }
    return stats;
}

const getLocalityState = (stats) => stats
    .find(stat => stat.locationType === 'Locality');

const findStatUsingDate = (stat, date, dateFormat) => stat
    ?.seriesDataList
    ?.find(series => moment(series.dateTime).format(dateFormat) === `${date}`)
    ?.value;

const getTimeSeries = ({
    stats,
    councilAreas,
    formatter,
    noDataHandler = (val) => val !== '-',
}) => {
    const timeSeries = {
        content: [],
        hasData: false,
    };
    const baseSeries = stats?.find(stat => stat?.seriesDataList?.length)?.seriesDataList;
    if (baseSeries?.length) {
        const baseMonth = moment(baseSeries[0].dateTime).format('MMM');
        const yearNow = new Date().getFullYear();

        // Generate range of date time
        timeSeries.content = Array
            .from({ length: 10 }, (v, i) => {
                const currentYear = (yearNow - 10) + i + 1;
                // Find locality by property type
                const localityStat = getLocalityState(stats);
                // Find series by date time
                const localitySeries = formatter(findStatUsingDate(
                    localityStat,
                    currentYear,
                    YEAR_FORMAT,
                ));
                if (!timeSeries.hasData && noDataHandler(localitySeries)) {
                    timeSeries.hasData = true;
                }

                const statLga = councilAreas.map((councilArea) => {
                    const stat = stats
                        .find(stat => stat.locationType === LGA_TITLE
                            && stat[LGA_NAME_NODE] === councilArea);
                    return formatter(findStatUsingDate(stat, currentYear, YEAR_FORMAT));
                });

                if (!timeSeries.hasData && statLga.some(val => noDataHandler(val))) {
                    timeSeries.hasData = true;
                }

                return [`${baseMonth} ${currentYear}`, localitySeries, ...statLga]
            });
    }
    return timeSeries;
};

const getTimeSeriesMonthly = ({
    stats,
    councilAreas,
    formatter,
    toDate = moment().format(YEAR_MONTH_DAY_FORMAT),
    noDataHandler = (val) => val !== '-',
}) => {
    const timeSeries = {
        content: [],
        hasData: false,
    };

    let dateFromIteration = moment(toDate, YEAR_MONTH_DAY_FORMAT);

    timeSeries.content = Array
        .from({ length: 12 }, () => {
            dateFromIteration = dateFromIteration
                .subtract('1', 'months');

            const formattedDate = dateFromIteration.format(MONTH_FORMAT);

            const localityStat = getLocalityState(stats);

            const localitySeries = formatter(findStatUsingDate(
                localityStat,
                formattedDate,
                MONTH_FORMAT,
            ));
            if (!timeSeries.hasData && noDataHandler(localitySeries)) {
                timeSeries.hasData = true;
            }

            const statLga = councilAreas.map((councilArea) => {
                const stat = stats
                    .find(stat => stat.locationType === LGA_TITLE
                        && stat[LGA_NAME_NODE] === councilArea);
                return formatter(findStatUsingDate(stat, formattedDate, MONTH_FORMAT));
            });

            if (!timeSeries.hasData && statLga.some(val => noDataHandler(val))) {
                timeSeries.hasData = true;
            }

            return [formattedDate, localitySeries, ...statLga];
        }).reverse();

    return timeSeries;
};

export default {
    getSuburbName,
    getInitialCheckList,
    getInitialSort,
    statsBuilder,
    censusBuilder,
    parseLocalityCouncilAreaDataTable,
    convertPrice,
    buildLocalityAndLGADataTable,
    toPercent,
    isLocationTypeLocality,
    isLocationTypeCouncilArea,
    convertDigitIntoPercentage,
    convertDateTimeIntoMonthYear,
    getQuartile,
    getMetricsByType,
    getMarketTrendsCardsData,
    formatDollarInTable,
    validateStatistics,
    validateCensus,
    filters: {
        byCheckedPropertyType,
        allPropertyTypeChecked,
        allPropertyTypeUnchecked,
        byIntervalListNotEmpty,
    },
    getTimeSeries,
    getTimeSeriesMonthly,
};
