import { Component } from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPropertyProfile } from 'js/actions/pdfReports';
import Reports from 'js/constants/reports';
import PDFViewer from 'js/components/reports/PDFViewer';
import { entryPoints } from 'js/constants/entryPoint';

@withProps({
    rentalAvmEstimateReportId: Reports.REPORT_ID.rentalAvmEstimateReport,
})
class RentalEstimateReport extends Component {
    static propTypes = {
        propertyId: PropTypes.number,
        propertyAddress: PropTypes.string,
        pageContext: PropTypes.string,
        dispatch: PropTypes.func,
        rentalAvmEstimateReportId: PropTypes.number,
        trigger: PropTypes.object,
        entryPoint: PropTypes.string,
    };

    static defaultProps = {
        entryPoint: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            filename: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.propertyId !== prevState.id) {
            const dateNow = moment().format('DD-MM-YYYY');
            return {
                id: nextProps.propertyId,
                filename: `Rental_Valuation_Estimate_AVM_${nextProps.propertyAddress && nextProps.propertyAddress.replace(/\s/g, '_')}_${dateNow}`,
            };
        }
        return null;
    }

    handler = () => {
        const {
            pageContext, dispatch, rentalAvmEstimateReportId,
        } = this.props;
        const { id } = this.state;
        dispatch(getPropertyProfile(
            id,
            pageContext,
            Reports.RENTAL_ESTIMATE,
            rentalAvmEstimateReportId,
            this.props.entryPoint,
        ));
    };

    render() {
        const { entryPoint, propertyId } = this.props;
        const finalEntryPoint = (entryPoint !== entryPoints.REPORTS_MENU && entryPoint !== entryPoints.VALUATION_ESTIMATE_PANEL) ? entryPoint : null;
        return (
            <PDFViewer
                propertyId={propertyId}
                trigger={this.props.trigger}
                title={this.props.propertyAddress}
                filename={this.state.filename}
                triggerHandler={this.handler}
                reportType={Reports.RENTAL_ESTIMATE}
                entryPoint={finalEntryPoint}
            />
        );
    }
}
const ConnectedRentalEstimateReport = connect()(RentalEstimateReport);
ConnectedRentalEstimateReport.displayName = 'RentalEstimateReport';
export default ConnectedRentalEstimateReport;
