import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { routeCodes, getRoute } from '../../../../constants/routes';
import Option from '../../../../constants/options/dropdownOptions';
import { getAssetDomain } from '../../../../constants/assets';
import TOOLTIP from '../../../../constants/tooltipText';
import SearchValidation from '../SearchValidation';
import Search from '../../../../constants/rapidSearch';
import SEGMENT from '../../../../constants/segment';
import { isAU } from '../../../../constants/crux';
import { trackVolumeFolioSearch } from '../../../../actions/segment';
import { conjunctUrlParams } from '../../../../helpers/search/ParamsBuilder';
import { invalidateCruxAppError } from '../../../../actions/errorHandler';
import FieldTooltip from '../FieldTooltip';
import LocalStorageHelper, { SEARCH_BAR_TOOLTIP_KEY } from '../../../../helpers/LocalStorageHelper';
import Commons from '../../../../helpers/Commons';
import ApiHelper from '../../../../helpers/ApiHelper';
import { saveSearchType } from '../../../../actions/componentAccess';
import { cruxLoader } from '../../../../actions/crux';
import StyledSearchButton from '../../../common/styledMUI/StyledSearchButton';
import SearchField from '../../../common/textFields/SearchField';
import StateSelect from '../StateSelect';
import saveAndGetUserSetting from '../../../../helpers/userPreferences/saveAndGetUserSetting';
import { SELECTED_STATE_VOLUME_FOLIO } from '../../../../helpers/Localization';
import UrlParamsExtractor from '../../../../helpers/search/UrlParamsExtractor';
import ListPropertyPreview from '../../../../constants/listPropertyPreview';

export class VolumeFolioSearch extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        navSearch: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedSearchOption: Option.ADDRESS,
            volumeNumber: '',
            folioNumber: '',
            addressState: '',
            route: null,
            isVolumeNumberFieldError: false,
            volumeNumberFieldErrorMessage: '',
            isStateFieldError: false,
            stateFieldErrorMessage: '',
            addressSuburb: '',
            anchorEl: [],
            open: [],
            isViewed: [],
            queryString: '',
            width: null,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentDidUpdate(prevProps) {
        const { route } = this.props;
        if (route.id !== routeCodes.VOLUME_FOLIO_SEARCH_RESULT.id &&
            prevProps.route !== route) {
            this.clearAllFields();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        this.clearAllFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            isStateFieldError: prevState.isStateFieldError,
            isVolumeNumberFieldError: prevState.isVolumeNumberFieldError,
        };
        const { location: { search } = {} } = nextProps;

        // Field Validations
        // Revert field to normal state upon receiving valid input
        if (prevState.isVolumeNumberFieldError && prevState.volumeNumber !== '') {
            nextState.isVolumeNumberFieldError = false;
        }
        if (prevState.isStateFieldError && prevState.addressState !== '') {
            nextState.isStateFieldError = false;
        }

        // Pre-populating nav search bar if name search has been performed
        let formattedState = {};
        const routePath = getRoute(nextProps.match.path);
        if (search && search !== prevState.queryString &&
            routePath.id === routeCodes.VOLUME_FOLIO_SEARCH_RESULT.id) {
            nextState.queryString = search;
            const {
                folioNumber = '',
                volumeNumber = '',
                addressState = '',
                addressSuburb = '',
            } = UrlParamsExtractor
                .parseUrlParams(search, ListPropertyPreview.SEARCH_TYPE.VOLUME_FOLIO) || {};
            formattedState = {
                folioNumber, volumeNumber, addressState, addressSuburb,
            };
        }

        return { ...nextState, ...formattedState };
    }

    clearAllFields = () => {
        this.setState({
            volumeNumber: '',
            folioNumber: '',
            addressState: '',
            addressSuburb: '',
        });
    }

    validateVolumeNumber(volumeNumber) {
        // Validate whether volume number is empty or not
        if (volumeNumber === '') {
            this.setState({
                isVolumeNumberFieldError: true,
                volumeNumberFieldErrorMessage: Search.ERROR_MESSAGE.volumeNumberRequired,
            });
        }
    }

    validateAddressState(addressState) {
        // Validate whether selectedStateOption field is empty or not
        if (addressState === '') {
            this.setState({
                isStateFieldError: true,
                stateFieldErrorMessage: Search.ERROR_MESSAGE.stateRequired,
            });
        }
    }

    constructQueryParams = () => {
        const {
            folioNumber, volumeNumber,
            addressState, addressSuburb,
        } = this.state;

        const formattedParams = {
            folioNumber,
            volumeNumber,
            addressState,
            addressSuburb,
        };

        return conjunctUrlParams(formattedParams);
    };

    trackEvent = (goToPdp) => {
        const { dispatch, route: { pageContext } = {}, activeView } = this.props;
        const {
            volumeNumber, folioNumber,
            addressState, addressSuburb,
        } = this.state;

        const _activeView = !goToPdp && activeView;
        dispatch(trackVolumeFolioSearch({
            pageContext,
            searchString: [volumeNumber, folioNumber, addressState, addressSuburb]
                .filter(Boolean)
                .join(', '),
            entryPoint: SEGMENT.EVENT_PROPERTIES.TYPED,
            searchType: SEGMENT.EVENT_PROPERTIES.VOLUME_FOLIO,
            activeView: _activeView,
        }));
    };

    redirectPage = (uri) => {
        const { history, dispatch } = this.props;
        history.push(uri, { entryPoint: SEGMENT.EVENT_PROPERTIES.SEARCH });
        const goToPdp = uri.includes('property');
        this.trackEvent(goToPdp);
        if (uri.includes('property')) {
            this.clearAllFields();
            dispatch(saveSearchType(Option.VOLUME_FOLIO));
        }
    };

    volumeFolioSearchSubmit = () => {
        const {
            volumeNumber, folioNumber,
            addressState, addressSuburb,
        } = this.state;

        const { dispatch } = this.props;
        const listingParams = {
            volumeNumber,
            folioNumber,
            state: addressState,
            addressSuburb,
            pathName: routeCodes.VOLUME_FOLIO_SEARCH_RESULT.path,
            dispatch,
            searchParams: this.constructQueryParams(),
        };

        this.validateVolumeNumber(volumeNumber);
        this.validateAddressState(addressState);
        // volumeNumber and addressState should not be empty before proceeding
        if (volumeNumber !== '' && addressState !== '' && isAU) {
            if (addressState) {
                saveAndGetUserSetting(dispatch, {
                    name: SELECTED_STATE_VOLUME_FOLIO,
                    value: addressState,
                });
            }
            dispatch(cruxLoader());
            dispatch(invalidateCruxAppError());
            ApiHelper.getRedirectUrl(listingParams, this.redirectPage);
        }
    }

    handleFocusPopper = (event, index) => {
        if (Commons.get(LocalStorageHelper.get(SEARCH_BAR_TOOLTIP_KEY), `is-${ListPropertyPreview.SEARCH_TYPE.VOLUME_FOLIO}-${index}-viewed`)) return;
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: true,
            },
        }));
    };

    handleClosePopper = (event, index) => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: false,
            },
            isViewed: {
                ...state.isViewed,
                [index]: true,
            },
        }));
    };

    handleClickAway = (event, index) => {
        setTimeout(() => {
            this.setState(state => ({
                open: {
                    ...state.open,
                    [index]: false,
                },
            }));
        }, 150);
    };

    handleScroll = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    }

    handleStateChange = (type) => {
        this.setState({ addressState: type });
    }

    render() {
        const { navSearch, location } = this.props;
        const {
            isVolumeNumberFieldError, volumeNumberFieldErrorMessage, isStateFieldError,
            stateFieldErrorMessage, addressState, anchorEl, open, isViewed, width,
        } = this.state;
        const {
            VOLUME, FOLIO, STATE, SUBURB,
        } = TOOLTIP.TEXT.VOLUME_FOLIO_SEARCH;

        const inputProps = [
            {
                field: 'volumeNumber',
                placeholder: 'Enter a volume *',
                onChange: event => this.setState({ volumeNumber: event.target.value }),
                tooltipTxt: VOLUME,
                validation: {
                    message: volumeNumberFieldErrorMessage,
                    onError: isVolumeNumberFieldError,
                },
            },
            {
                field: 'folioNumber',
                placeholder: 'Enter a folio',
                onChange: event => this.setState({ folioNumber: event.target.value }),
                tooltipTxt: FOLIO,
            },
            {
                field: 'addressState',
            },
            {
                field: 'addressSuburb',
                placeholder: 'Enter a Suburb',
                onChange: event => this.setState({ addressSuburb: event.target.value }),
                tooltipTxt: SUBURB,
            },
        ];

        return (
            <div className={navSearch ? 'search-inputs more-actions' : 'search-inputs'}>
                <div className="volume-folio-search-primary-fields">
                    <Grid container className="grid-container">
                        {
                            inputProps.map(({
                                field, placeholder, onChange, tooltipTxt, validation
                            }, index) => {
                                if (field !== 'addressState') {
                                    return (
                                        <Grid key={`grid-${index}-${field}`} item className="item" xs={3}>
                                            <div
                                                className={`form-input-search-field ${field}-field ${(validation && validation.onError) && ' error'}`}
                                            >
                                                <SearchField
                                                    value={this.state[field]}
                                                    type="text"
                                                    className="form-control"
                                                    id={field}
                                                    placeholder={placeholder}
                                                    onChange={onChange}
                                                    onFocus={event => this.handleFocusPopper(event, index)}
                                                    autoComplete="off"
                                                    onBlur={event => this.handleClickAway(event, index)}
                                                    error={!!validation?.onError}
                                                    color="primary"
                                                />
                                                <FieldTooltip
                                                    id={index}
                                                    key={`volume-folio-popper-${index}-${field}`}
                                                    className={`volume-folio-search-tooltip ${field}-tooltip${navSearch ? ' shrink' : ''}`}
                                                    open={open[index] && !isViewed[index]}
                                                    anchorEl={anchorEl[index] || null}
                                                    handleClose={event => this.handleClosePopper(event, index)}
                                                    tooltipTxt={tooltipTxt}
                                                    searchType={ListPropertyPreview.SEARCH_TYPE.VOLUME_FOLIO}
                                                    width={width}
                                                />

                                                {
                                                    validation &&
                                                    <SearchValidation
                                                        message={validation.message}
                                                        onError={validation.onError}
                                                    />
                                                }
                                            </div>
                                        </Grid>
                                    );
                                }
                                return (
                                    isAU &&
                                    <StateSelect
                                        key={`${index}-${field}`}
                                        addressState={addressState}
                                        options={Option.STATE_OPTIONS_VOLUME_FOLIO_SEARCH}
                                        toolTipText={STATE}
                                        showToolTip
                                        isStateFieldError={isStateFieldError}
                                        stateFieldErrorMessage={stateFieldErrorMessage}
                                        handleStateChange={this.handleStateChange}
                                        userSettingKey={SELECTED_STATE_VOLUME_FOLIO}
                                    />
                                );
                            })
                        }
                    </Grid>
                    <StyledSearchButton
                        data-testid="name-search-button"
                        className="button-primary name-search-btn"
                        onClick={this.volumeFolioSearchSubmit}
                        disabled={location.search === `?${this.constructQueryParams()}`}
                    >
                        <img
                            src={getAssetDomain('search_icon_white.png')}
                            className="search-icon"
                            alt="volume/folio search"
                        />
                    </StyledSearchButton>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    activeView: state.searchResults.get('activeView'),
}))(withRouter(VolumeFolioSearch))
