const advancedFilters = [
    'rentalOccupancy',
    'keywords',
    'feeCode',
    'factoryArea',
    'mezzanineArea',
    'officeShowroomArea',
    'otherArea',
    'shopArea',
    'showroomArea',
    'warehouseArea',
    'workshopArea',
    'distanceToHospital',
    'distanceToRiverFrontage',
    'distanceToSchool',
    'distanceToSeaRiverCanal',
    'distanceToShops',
    'boatRampMooringSpace',
    'fenced',
    'grannyFlatSelfContained',
    'lockupGarages',
    'swimmingPool',
    'treesOnLand',
    'noOfTennisCourts',
    'wallMaterial',
    'materialsInExternalWalls',
    'roofMaterial',
    'materialsInRoof',
    'canalFrontage',
    'coastFrontage',
    'golfFrontage',
    'highExposure',
    'lakeFrontage',
    'riverFrontage',
    'scenicView',
    'waterFrontage',
    'airConditioned',
    'ductedHeating',
    'builtInWardrobes',
    'ductedVacuum',
    'fireplace',
    'openPlan',
    'polishedFloors',
    'renovated',
    'diningRooms',
    'familyRumpusRooms',
    'floorLevelsInside',
    'kitchens',
    'loungeRooms',
    'loungeDiningRoomsCombined',
    'noOfStudyRooms',
    'toilets',
    'heritageOtherClassification',
    'currentUnimprovedLandValue',
    'unimprovedCapitalValue',
    'unimprovedCapitalValueDate',
    'valuationLandValue',
    'valuationImprovementsValue',
    'valuationCapitalValue',
    'valuationAssessmentDate',
    'allWeatherAccessToLand',
    'allWeatherRoadsToFarm',
    'permanentWater',
    'sealedRoadsToProperty',
    'housesOnFarm',
    'irrigatedArea',
    'damsBores',
    'paddocksYards',
    'accessAvailable24Hrs',
    'accessSecurityInstalled',
    'closeToPublicTransport',
    'liftInstalled',
    'onMainRoad',
    'sewage',
    'townSewerageAvailable',
    'townWaterAvailable',
    'water',
    'buildingArea',
    'equivalentBuildingArea',
    'floorLevelOfUnit',
    'floorLevelsInsideUnit',
    'flats',
    'streetFrontage',
    'totalFloorsInBuilding',
    'yearBuildingRefurbished',
    'yearEstablished',
    'freeStandingBuilding',
    'latestConsumerSaleEstimate',
    'latestConsumerSaleConfidence',
];

export default advancedFilters;
