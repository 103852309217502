import Commons from './Commons';
import { UriBuilder } from './UriBuilder';
import { SUGGEST_FILTERS } from './Localization';
import RapidSearchParamsBuilder from './search/RapidSearchParamsBuilder';
import { isNZ } from '../constants/crux';
import SUGGESTION_TYPES from '../constants/suggestionTypes';
import ClapiApi from '../api/clapi';
import { routeCodes } from '../constants/routes';
import { cruxAppError } from '../actions/errorHandler';
import ErrorMsg from '../constants/errorMsg';
import { ErrorType } from '../constants/redirect';
import { cruxUnloader } from '../actions/crux';
import {
    AND_OPERATOR,
    SORT_PARAM,
    RESULTS_FORMAT,
    RESULTS_FORMAT_SORT,
    RADIUS_SEARCH_FORMAT,
    LISTING_DATA_PARAM,
    VOLUME_FOLIO_DATA_PARAM,
    TENURE_DATA_PARAM,
    OR_OPERATOR,
    IS_FOR_SALE,
    DEFAULT_RESULTS_FORMAT,
    IS_ACTIVE_PARAM,
    OFFSET_PARAM,
    LIMIT_PARAM,
    METADATA_PARAM,
    DISTINCT_FIELDS_PARAM,
    RADIUS_PARAM,
    TYPE_PARAM,
    VOLUME_PARAM,
    FOLIO_PARAM,
    EXCLUDE_PROPERTY,
} from '../constants/batchSearch/batchSearch';
import RapidBatchSearch from './search/batch/RapidBatchSearch';
import SearchBuilder from './search/batch/SearchBuilder';
import OTM from '../constants/otm';

const buildAddressSearchParam = (suggestionType, searchString) => {
    const addressParams = searchString.split(',');
    const clonedAddressSearchObj = {};
    let idx = 0;
    if (suggestionType === SUGGESTION_TYPES.STREET) {
        idx = 1;
        clonedAddressSearchObj.addressStreet = addressParams[0] && addressParams[0].trim();
    }
    clonedAddressSearchObj.addressSuburb = addressParams[idx] && addressParams[idx].trim();
    clonedAddressSearchObj.addressTown = addressParams[1 + idx] && addressParams[1 + idx].trim();
    return clonedAddressSearchObj;
};

const buildSuggestionRapidSearchFilter = (suggestion) => {
    const { suggestion: searchString, suggestionType } = suggestion || {};
    if (isNZ) {
        if (suggestionType === SUGGESTION_TYPES.STREET) {
            if (searchString && !searchString.includes(',')) {
                return { [SUGGEST_FILTERS.streetOnly]: searchString };
            }
            return buildAddressSearchParam(suggestionType, searchString);
        } else if (suggestionType === SUGGESTION_TYPES.LOCALITY) {
            return buildAddressSearchParam(suggestionType, searchString);
        }
    }
    // default return
    return searchString && suggestionType ?
        { [SUGGEST_FILTERS[suggestionType]]: searchString } : {};
};

const buildMultiLocalitySuggestion = (search) => ({
    [SUGGEST_FILTERS[search?.sType]]: search?.suggestions,
});


const buildRapidSearchQueryParams = (searchFilters) => {
    const filters = JSON.parse(JSON.stringify(searchFilters));
    const uri = new UriBuilder('');

    if (filters.radius) filters.radius = parseFloat(filters.radius);
    delete filters.suburbOnly;

    const fieldNames = Object.getOwnPropertyNames(filters);
    // Construct url query params
    RapidSearchParamsBuilder(
        uri,
        fieldNames,
        filters,
    );

    return uri;
};

const flatten = (filters, ownerCompanySearch, volumeFolioSearch) =>
    (filters &&
    (JSON.stringify(ownerCompanySearch) !== '{}'
        || JSON.stringify(volumeFolioSearch) !== '{}')
        ? filters.flat()
        : filters);

const buildResultsFormat = (params, builder = RapidBatchSearch.batchParamBuilder) => {
    return [
        { param: OFFSET_PARAM },
        { param: LIMIT_PARAM },
        { param: SORT_PARAM, formatter: RESULTS_FORMAT_SORT },
        { param: METADATA_PARAM },
        { param: DISTINCT_FIELDS_PARAM },
    ].reduce((resultsFormat, field) => {
        if (field.param in params && !!params[field.param]) {
            return builder(
                field.formatter ?? RESULTS_FORMAT,
                { resultsFormat, param: field.param, paramValue: params[field.param] },
            );
        }
        return resultsFormat;
    }, DEFAULT_RESULTS_FORMAT);
}

// TODO: Find a way to break out specific filters by search type,
//  this method caters a lot of things already. We might need to cater multiple property id as
//  parameter in the future, it will be easier to add another filter if we break things out.
const buildRequest = (searchFilters) => {
    const {
        statusType,
        propertyIds,
        offset,
        limit,
        sort,
        metadata,
        distinctFields,
        radius,
        lat,
        lon,
        tenureData,
        volumeNumber,
        folioNumber,
        volumeFolioData,
        listingData,
        ownerFirstName,
        ownerLastName,
        companyName,
        isCurrentOwner,
        exactSearch,
        propertyId,
        addressBuildingName,
        ...params
    } = JSON.parse(JSON.stringify(searchFilters));
    let outerFilters = [];
    const filters = [];

    // suburbOnly param is not needed because we can determine if we are targeting
    // for suburb only using the addressSuburb & addressPostcodeState params
    delete params.suburbOnly;
    let ownerCompanySearch = {};
    let volumeFolioSearch = {};
    const builder = statusType === OTM.WITHDRAWN
        ? RapidBatchSearch.batchWithdrawnParamBuilder
        : RapidBatchSearch.batchParamBuilder;
    Object.entries(params).forEach(([param, paramValue]) => {
        const handleFilter = (value) => filters.push(builder(param, { param, paramValue: value }));

        if (typeof paramValue === 'string' && paramValue.includes('|')) {
            // Handle multiple values
            const multiParams = paramValue.split('|');
            filters.push({
                operation: OR_OPERATOR,
                filters: multiParams.map(multiParam => builder(param, { param, paramValue: multiParam })),
            });
        } else if (IS_FOR_SALE.includes(param) && paramValue === false) {
            handleFilter(paramValue);
        } else if (IS_ACTIVE_PARAM.includes(param)) {
            if (typeof paramValue === 'string' && paramValue.includes('false')) {
                handleFilter(paramValue);
            }
        } else if (paramValue && !(param === TYPE_PARAM && statusType === OTM.TENURE)) {
            if (!Array.isArray(paramValue) || paramValue.length) {
                handleFilter(paramValue);
            }
        }
    });

    if ('companyName' in searchFilters
        || 'ownerLastName' in searchFilters) {
        ownerCompanySearch = true;
        filters.push(builder(
            LISTING_DATA_PARAM,
            {
                paramValue: listingData ?? {
                    ownerFirstName,
                    ownerLastName,
                    companyName,
                    isCurrentOwner,
                    exactSearch,
                }
            },
        ));
    } else if (VOLUME_PARAM in searchFilters
        || FOLIO_PARAM in searchFilters) {
        volumeFolioSearch = true;
        filters.push(builder(
            VOLUME_FOLIO_DATA_PARAM,
            {
                paramValue: volumeFolioData ?? {
                    volumeNumber,
                    folioNumber,
                }
            },
        ));
    } else if (RADIUS_PARAM in searchFilters) {
        filters.push(builder(
            RADIUS_SEARCH_FORMAT,
            {
                radiusParams: {
                    radius: !!radius && parseFloat(radius),
                    lat: !!lat && parseFloat(lat),
                    lon: !!lon && parseFloat(lon),
                }
            },
        ));
        filters.push(builder(
            EXCLUDE_PROPERTY,
            {
                propertyId,
            },
        ));
    }
    if (statusType === OTM.WITHDRAWN) {
        filters.push(SearchBuilder.buildWithdrawnCompare());
    } else if (statusType === OTM.TENURE) {
        filters.push(builder(
            TENURE_DATA_PARAM,
            { paramValue: { ...tenureData, type: searchFilters.type } },
        ));
    }

    if (propertyIds?.length) {
        buildBatchSearchParamsUsingIds(propertyIds)?.requests[0]?.filters
            ?.forEach(_filter => filters.push(_filter));
    }

    if ('addressBuildingName' in searchFilters) {
        filters.push(SearchBuilder.buildingNameFilterBuilder({ addressBuildingName, exactSearch }));
    }

    outerFilters.push({
        operation: AND_OPERATOR,
        filters: flatten(filters, ownerCompanySearch, volumeFolioSearch),
    });

    return {
        resultsFormat: buildResultsFormat({ offset, limit, sort, metadata, distinctFields }, builder),
        filters: outerFilters,
    };
};

const buildBatchSearchParams = searchFilters => ({
    requests: [buildRequest(searchFilters)],
});

const buildBatchSearchParamsUsingIds = idList => ({
    requests: [
        {
            resultsFormat: {
                offset: 0,
                limit: 20,
            },
            filters: [
                {
                    operation: 'or',
                    filters: idList.map(id => ({
                        field: 'id',
                        operation: 'equals',
                        value: id,
                    })),
                },
                {
                    ...SearchBuilder.isActiveFilterBuilder({ paramValue: 'true,false' }),
                },
            ],
        },
    ],
});

const buildBatchSearchMultiPageParams = ({ startPage, endPage, filters, propertyIds }) => {
    const requests = [];

    for (let offset = startPage; offset <= endPage; offset += 1) {
        requests.push(buildRequest({
            ...filters,
            propertyIds,
            offset,
        }));
    }

    return {
        requests,
    };
};

const addParamFromPath = (p, name, value) => value && p.addParam(name, value);

const getRedirectUrl = (listingParams, redirectPage) => {
    let listingData;
    const {
        isCurrentOwner, state, addressSuburb, ownerName,
        volumeNumber, folioNumber, dispatch, pathName, searchParams,
        holdPeriodHouse, holdPeriodUnit, type, exactSearch,
    } = listingParams;
    const { firstName = '', lastName = '' } = ownerName || {};
    const companyName = firstName && Commons.capitalizeFirstLetter(firstName);
    const isNameSearch = lastName && lastName !== '';
    if (isNameSearch) {
        listingData = {
            isCurrentOwner,
            exactSearch,
            ownerFirstName: firstName && Commons.capitalizeFirstLetter(firstName),
            ownerLastName: lastName && Commons.capitalizeFirstLetter(lastName),
            companyName: '',
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    } else {
        listingData = {
            exactSearch,
            isCurrentOwner,
            ownerFirstName: '',
            ownerLastName: '',
            companyName,
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    }

    let volumeFolioData;
    const isVolumeFolioSearch = volumeNumber && volumeNumber !== '';
    if (isVolumeFolioSearch) {
        volumeFolioData = {
            volumeNumber,
            folioNumber,
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    }

    let tenureData;
    const isTenureSearch = (holdPeriodHouse || holdPeriodUnit)
        && (holdPeriodHouse !== '' || holdPeriodUnit !== '');
    if (isTenureSearch) {
        tenureData = {
            holdPeriodHouse,
            holdPeriodUnit,
            type,
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    }

    const _listingData = listingData ? listingData : tenureData;
    const data = volumeFolioData ? volumeFolioData : _listingData;
    const uri = `${pathName}?${searchParams}`;
    passUriToRedirectCallback(data, dispatch, uri, redirectPage);
};

const passUriToRedirectCallback = (data, dispatch, uri, redirectCallback) => {
    // eslint-disable-next-line max-len
    ClapiApi.getBatchSearch(data).then((result) => {
        const totalElements = Commons.get(result, 'metadata.totalElements');
        const [firstData] = Commons.get(result, 'data');
        const isPdpPath = totalElements === 1;
        let uriToUse;
        if (isPdpPath) {
            uriToUse = routeCodes.PROPERTY.path(firstData.addressComplete, firstData.id);
        } else {
            uriToUse = uri;
        }
        redirectCallback(uriToUse, isPdpPath);
    }).catch(() => {
        dispatch(cruxAppError(ErrorMsg.UNAVAILABLE, ErrorType.PAGE_ERROR));
    }).finally(() => {
        dispatch(cruxUnloader());
    });
}

export default {
    buildRapidSearchQueryParams,
    buildSuggestionRapidSearchFilter,
    buildBatchSearchParams,
    buildBatchSearchMultiPageParams,
    addParamFromPath,
    getRedirectUrl,
    buildBatchSearchParamsUsingIds,
    passUriToRedirectCallback,
    buildMultiLocalitySuggestion,
};
