import BlockUi from '../blockUi/BlockUi';
import { useSelector } from 'react-redux';
import { useLoadScript } from '@react-google-maps/api';

const MAP_LIBRARIES = ['places', 'drawing', 'geometry'];
export default function withMap(WrappedComponent) {
    const ComponentWrapper = (props) => {
        const googleMapsApiKey = useSelector(state => state.config?.get('googleMapsApi')?.apiKey);
        const { isLoaded } = useLoadScript({
            googleMapsApiKey,
            libraries: MAP_LIBRARIES,
            googleMapsClientId: 'gme-corelogicsolutions',
            version: 3.57,
            channel: 'au_rppcrux_test',
        });
        return (
            <BlockUi
                renderChildren={false}
                blocking={!isLoaded}
                containerProps={{
                    className: 'with-map-block-ui',
                    position: 'relative',
                    containerCss: { minHeight: '50vh' }
                }}
                backdropProps={{ style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.3);',
                    }}}
            >
                <WrappedComponent {...props} />
            </BlockUi>
        );
    };

    return ComponentWrapper;
}
