import React from 'react';
import PropTypes from 'prop-types';

import { styled, Tooltip as MUITooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getAssetDomain } from '../../constants/assets';
import BlackTooltip from '../common/MUITooltip';

const StyledBlackTooltip = styled(BlackTooltip)({
    '& .MuiTooltip-tooltip': {
        width: '235px',
    },
});

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)({
    width: '18px',
    height: '20.2px',
    position: 'relative',
    top: '-1px',
});

const Checkbox = ({
    id = '',
    label = '',
    customClass = '',
    checked,
    onChange,
    tooltip,
    tooltipId,
    displayWhenUnEntitled,
    tooltipTitle,
    disabled,
    entitled,
    tooltipClass = 'white',
}) => {
    const _customClass = customClass ? ` ${customClass}` : '';
    const _customDisabledClass = (displayWhenUnEntitled && !entitled) || disabled ? ' custom-checkbox-disabled' : '';
    return (
        <span className={`custom-checkbox${_customClass}${_customDisabledClass}`}>
            <label htmlFor={id}>
                <input
                    id={id}
                    data-testid={id}
                    aria-label={label}
                    checked={checked}
                    type="checkbox"
                    onChange={onChange}
                    disabled={(displayWhenUnEntitled && !entitled) || disabled}
                />
                <span className="check-icon">
                    <img src={getAssetDomain('tick-icon.png')} alt="" />
                </span>

                {
                    (displayWhenUnEntitled && !entitled) || disabled ?
                        <MUITooltip
                            title={tooltipTitle}
                            placement="top"
                            arrow
                        >
                            <span className="checkbox-label">
                                <span dangerouslySetInnerHTML={{ __html: label }} />
                            </span>
                        </MUITooltip> :
                        <span className="checkbox-label">
                            <span dangerouslySetInnerHTML={{ __html: label }} />
                        </span>
                }
            </label>
            {
                tooltip && (
                    <StyledBlackTooltip
                        title={tooltip}
                        id={tooltipId}
                        className={tooltipClass}
                        placement="right"
                        arrow
                    >
                        <StyledInfoOutlinedIcon />
                    </StyledBlackTooltip>
                )
            }
        </span>
    );
};

Checkbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    customClass: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    tooltipId: PropTypes.string,
    displayWhenUnEntitled: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    disabled: PropTypes.bool,
    entitled: PropTypes.bool,
};

export default Checkbox;
