import React, { useEffect, useLayoutEffect, useState } from 'react';
import Colors from '../../../constants/colors';
import PanToolIcon from '@mui/icons-material/PanTool';
import Button from '@mui/material/Button';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PropTypes from 'prop-types';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DRAWING_MODE } from 'js/constants/map';
import SuggestByType from 'js/components/common/SuggestByType';
import { COUNTRY } from 'js/constants/crux';
import ErrorMessages from 'js/constants/errorMsg';
import { clearSuggestion } from 'js/actions/clapi';
import SuggestionTypes from 'js/constants/suggestionTypes';
import MapIcons from 'js/constants/mapIcon';
import MUITooltip from 'js/components/common/MUITooltip';
import { SLAS_PLACEHOLDER } from 'js/helpers/Localization';

const {
    PRIMARY, WHITE, SECONDARY,
    GRAY_BACKGROUND,
} = Colors;

const TOOL_SIZE = 38;

const SEARCH = 'search';

const StyledToolButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'specialIcon',
})((props) => ({
    fontSize: 10,
    minWidth: 0,
    width: TOOL_SIZE,
    height: TOOL_SIZE,
    '&:hover': {
        backgroundColor: props.active ? PRIMARY.MAIN : GRAY_BACKGROUND,
        ...(props.active ? {} : {
            color: props.theme.palette.primary.main,
            ...(props.specialIcon ? {
                '& svg path': {
                    stroke: props.theme.palette.primary.main,
                },
                '& svg rect': {
                    fill: props.theme.palette.primary.main,
                },
            } :  {}),
        }),
    },
    color: props.active ? WHITE : SECONDARY.MAIN,
    backgroundColor: props.active ? PRIMARY.MAIN : WHITE,
    ...(props.specialIcon ? {
        '& svg path': {
            stroke: props.active ? WHITE : SECONDARY.MAIN,
        },
        '& svg rect': {
            fill: props.active ? WHITE : SECONDARY.MAIN,
        },
    } :  {}),
}));

const StyledFullPageMapToolBox = styled('div')({
    position: 'absolute',
    top: 24,
    left: 24,
    zIndex: 99,
    width: TOOL_SIZE,
});

const StyledButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const ToolContainer = styled(({ expanded, ...props }) => (
    <div {...props}>{props.children}</div>
))(({ expanded }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    width: expanded ? 498 : TOOL_SIZE,
    transition: 'width 0.2s ease-in-out',
    '&:not(:last-child)': {
        marginBottom: 8,
    },
    '& .auto-suggest-container': {
        marginRight: 8,
        '& .search-keyword input[type="suburb-postcode-search"]': {
            height: TOOL_SIZE,
            fontSize: 13,
        },
        '& .search-keyword .search-suggestions': {
            top: TOOL_SIZE,
        }
    },
    'input[type=suburb-postcode-search]': {
        paddingRight: '12px !important',
        '&::placeholder': {
            fontSize: 11,
        },
    },
}));

const SEARCH_STATE = {
    hidden: 'hidden',
    visible: 'visible',
};

const ZOOM_BY_LOCATION_TYPE = {
    [SuggestionTypes.LOCALITY]: 12,
    [SuggestionTypes.COUNCIL_AREA]: 7,
    [SuggestionTypes.SUBURB]: 12,
    [SuggestionTypes.STATE]: 7,
    [SuggestionTypes.TERRITORIAL_AUTHORITY]: 7,
    [SuggestionTypes.POSTCODE]: 12,
    defaultZoom: 16,
};

const CreateTerritoryMapToolBox = (props) => {
    const [selectedSuggestion, setSelectedSuggestion] = useState({});
    const [searchState, setSearchState] = useState(SEARCH_STATE.hidden);

    const dispatch = useDispatch();
    const store = useSelector(state => ({
        suggestions: state.clapi.get('suggestions'),
        isSearchSuggestionsLoading: state
            .componentAccess
            .get('searchSuggestions')
            .isLoadingSuggestion,
        searchSuggestionsHasError: state.componentAccess.get('searchSuggestions').hasError,
    }));

    useLayoutEffect(() => {
        if (props.drawingMode === SEARCH) {
            setTimeout(() => {
                setSearchState(SEARCH_STATE.visible);
            }, 200)
        } else if (searchState !== SEARCH_STATE.hidden) {
            setSearchState(SEARCH_STATE.hidden);
        }
    }, [props.drawingMode])

    const clearPath = () => {
        props.setDrawing('');
        props.setPolygonPath([]);
        props.setGooglePolygon(null);
        props.mapCommands.clearRecord();
        if (props?.savedTerritory?.territory?.polygon?.length) {
            props.setEditPolygonDeleted(true);
        }
    };

    useEffect(() => {
        // cleaning up suggestions when unmounting
        return () => {
            if (store.suggestions.length) {
                dispatch(clearSuggestion());
            }
        }
    }, []);
    const isEditMode = !!props?.savedTerritory?.territoryId;

    const tools = [
        {
            icon: PanToolIcon,
            key: '',
            name: 'pan',
            onClick: () => props.setDrawingMode(''),
            tip: 'Pan',
        },
        {
            icon: props.drawingMode === SEARCH ? CloseIcon : SearchOutlinedIcon,
            key: SEARCH,
            name: SEARCH,
            onClick: () => props.drawingMode === SEARCH
                ? props.setDrawingMode('')
                : props.setDrawingMode(SEARCH),
            tip: props.drawingMode === SEARCH ? 'Close' : 'Search',
        },
        {
            icon: MapIcons.PolygonIcon,
            key: DRAWING_MODE.POLYGON,
            name: DRAWING_MODE.POLYGON,
            onClick: () => !props.drawing && props.setDrawingMode(DRAWING_MODE.POLYGON),
            disabled: !!props.polygonPath?.length,
            specialIcon: true,
            tip: 'Draw Shape',
        },
        {
            icon: UndoIcon,
            name: 'undo',
            disabled: (!props.editPolygonDeleted && isEditMode && props.mapCommands.undoList.length === 1) || !props.mapCommands.hasUndo,
            onClick: () => {
                props.mapCommands.undo();
            },
            tip: 'Undo',
        },
        {
            icon: RedoIcon,
            name: 'redo',
            disabled: !props.mapCommands.hasRedo || props.drawingMode === DRAWING_MODE.POLYGON,
            onClick: () => {
                props.mapCommands.redo();
            },
            tip: 'Redo',
        },
        {
            icon: DeleteOutlineOutlinedIcon,
            name: 'delete',
            onClick: () => clearPath(),
            disabled: !props.polygonPath?.length,
            tip: 'Delete Shape',
        },
    ];

    const suggestionSelect = (location) => {
        setSelectedSuggestion(location);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
            address: location.suggestion, componentRestrictions: { country: COUNTRY },
        }, (results, status) => {
            const zoom = ZOOM_BY_LOCATION_TYPE[location.suggestionType]
                ?? ZOOM_BY_LOCATION_TYPE.defaultZoom
            if (status === 'OK') {
                const targetLocation = results[0].geometry.location;
                props.mapInstance.setCenter(targetLocation);
                props.mapInstance.setZoom(zoom);
                props.setMarker(targetLocation);
            } else {
                props.openSnackBar('error', ErrorMessages.FAILED_TO_FETCH);
            }
        });
    };

    return (
        <StyledFullPageMapToolBox>
            <StyledButtonContainer>
                {
                    tools.map(({icon: Icon, key, onClick, disabled, name, specialIcon, tip }) => (
                        <ToolContainer
                            key={name}
                            expanded={props.drawingMode === SEARCH && key === SEARCH}
                        >
                            {
                                key === SEARCH &&
                                    <div
                                        style={{
                                            transition: 'width 0.2s ease-in-out',
                                            width: props.drawingMode === SEARCH ? 460 : 0,
                                            overflow: searchState,
                                        }}
                                    >
                                        <SuggestByType
                                            selectedSuggestion={selectedSuggestion}
                                            setSelectedSuggestion={suggestionSelect}
                                            suggestions={store.suggestions}
                                            isSearchSuggestionsLoading={store.isSearchSuggestionsLoading}
                                            searchSuggestionsHasError={store.searchSuggestionsHasError}
                                            dispatch={dispatch}
                                            name="suburb-postcode"
                                            types={[]}
                                            placeholderText={SLAS_PLACEHOLDER}
                                        />
                                    </div>
                            }
                            <MUITooltip
                                title={tip}
                                arrow
                                placement="right"
                                key={`mui-tooltip-${name}-${props.drawingMode}`}
                            >
                                <span data-testid={`tooltip-trigger-${name}`}>
                                    <StyledToolButton
                                        color="paleSecondary"
                                        variant="contained"
                                        aria-label={name}
                                        active={key === props.drawingMode}
                                        onClick={onClick}
                                        disabled={disabled}
                                        sx={key === DRAWING_MODE.POLYGON ? { padding: 0 } : {}}
                                        specialIcon={specialIcon}
                                    >
                                    <Icon />
                                </StyledToolButton>
                                </span>
                            </MUITooltip>
                        </ToolContainer>
                    ))
                }
            </StyledButtonContainer>
        </StyledFullPageMapToolBox>
    );
}

CreateTerritoryMapToolBox.propTypes = {
    drawingMode: PropTypes.string,
    setDrawingMode: PropTypes.func,
    setPolygonPath: PropTypes.func,
    setGooglePolygon: PropTypes.func,
    setDrawing: PropTypes.func,
    drawing: PropTypes.object,
    mapInstance: PropTypes.object,
    setMarker: PropTypes.func,
    openSnackBar: PropTypes.func,
    mapCommands: PropTypes.shape({
        clearRecord: PropTypes.func,
        hasUndo: PropTypes.bool,
        undo: PropTypes.func,
        hasRedo: PropTypes.bool,
        redo: PropTypes.func,
    }),
};

export default CreateTerritoryMapToolBox;
