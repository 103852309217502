export const NZ_FLOOD_LAYER = 'nz-flood-layer';
export const MAP_STATUS = {
    IDENTIFY: 'IDENTIFY',
};

export const MAP_DISPLAY = {
    MODAL: 'Modal Map',
    MINI: 'Mini Map',
};

export const MAP_TYPE = {
    ROADMAP: {
        id: 'roadmap',
        name: 'Road',
        code: 1005,
    },
    HYBRID: {
        id: 'hybrid',
        name: 'Aerial',
        code: 1005,
    },
};
export const LAYER = {
    targetProperty: [
        {
            id: 'pboundary',
            request: '/overlays/propertyOverlay/propertySelect',
            label: 'Property Boundary',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'parcelb',
            request: '/overlays/parcelOverlay/parcelSelect',
            label: 'Parcel Boundaries',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
    ],
    propertyData: [
        {
            id: 'ls',
            request: '/overlays/propertyLabels/landSize',
            label: 'Land Area',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'lsale',
            request: '/overlays/propertyLabels/lastSaleSummary',
            label: 'Last Sale Price & Date',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'lp',
            request: '/overlays/parcelLabels/lotPlan',
            label: 'Lot Plan',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'lu',
            request: '/overlays/propertyLabels/primaryLandUse',
            label: 'Land Use',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'on',
            request: '/overlays/parcelLabels/lotOwnership',
            label: 'Owner Name',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'pdl',
            request: '/overlays/dimensions/legalLines',
            label: 'Dimensions',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'pb',
            request: '/overlays/propertyOverlay/propertyAll',
            label: 'Property Boundaries',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
    ],
    salesInfo: [
        {
            id: 'sold-6',
            request: '/overlays/sales/soldLastSixMonths',
            label: 'Sold Last 6 Months',
            mapTypeId: '1006',
            customClass: 'red',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'sold-12',
            request: '/overlays/sales/soldLastSixTwelveMonths',
            label: 'Sold Last 6 - 12 Months',
            mapTypeId: '1006',
            customClass: 'lavender',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'sold-24',
            request: '/overlays/sales/soldLastTwelveTwentyFourMonths',
            label: 'Sold Last 12 - 24 Months',
            mapTypeId: '1006',
            customClass: 'pink',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'sold-36',
            request: '/overlays/sales/soldLastTwentyFourThirtySixMonths',
            label: 'Sold Last 24 - 36 Months',
            mapTypeId: '1006',
            customClass: 'orange',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'ms',
            request: '/overlays/sales/multipleSalesLastThreeYears',
            label: 'Multiple Sales Last 3 Years',
            mapTypeId: '1006',
            customClass: 'purple',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
    ],
    recentListingInfo: [
        {
            id: 'otms',
            request: '/overlays/listings/sale',
            label: 'For Sale Last 6 Months',
            mapTypeId: '1006',
            customClass: 'lightblue',
            opacity: {
                roadmap: 0.6,
                hybrid: 1,
            },
        },
        {
            id: 'otmr',
            request: '/overlays/listings/rental',
            label: 'For Rent Last 6 Months',
            mapTypeId: '1006',
            customClass: 'green',
            opacity: {
                roadmap: 0.6,
                hybrid: 1,
            },
        },
    ],
    areaResearch: [
        {
            id: 'et',
            request: '/overlays/easements',
            label: 'Easements',
            mapTypeId: '1006',
            customClass: 'et-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
        },
        {
            id: 'bushfire',
            request: '/overlays/bushfire',
            label: 'Bushfire',
            mapTypeId: '1006',
            customClass: 'bf-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
            checkMapAdvancedRole: true,
        },
        {
            id: 'flood',
            request: '/overlays/flood',
            label: 'Flood',
            mapTypeId: '1006',
            customClass: 'fl-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
            checkMapAdvancedRole: true,
        },
        {
            id: 'heritage',
            request: '/overlays/heritage',
            label: 'Heritage',
            mapTypeId: '1006',
            customClass: 'hr-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
            checkMapAdvancedRole: true,
        },
        {
            id: 'ctr',
            request: '/overlays/contours',
            label: 'Contours',
            mapTypeId: '1006',
            customClass: 'ctr-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
        },
        {
            id: 'lga',
            request: '/overlays/lga',
            label: 'Local Authority',
            mapTypeId: '1006',
            customClass: 'lga-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
        },
        {
            id: 'loc',
            request: '/overlays/locality',
            label: 'Suburb',
            mapTypeId: '1006',
            customClass: 'loc-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
        },
    ],
    baseMap: [
        {
            id: 'esri-satellite',
            request: '/basemaps/satellite',
            label: 'Esri satellite',
            category: 'baseMap',
        },
        {
            id: 'esri-road',
            request: '/basemaps/streets',
            label: 'Esri road',
            category: 'baseMap',
        },
        {
            id: 'nearmap',
            request: '',
            label: 'Nearmap',
            category: 'baseMap',
        },
    ],
    boundary: [
        {
            id: 'property-select-boundary',
            request: '/overlays/propertyOverlay/propertyAll',
            label: 'Target Property Boundary',
            category: 'boundary',
        },
    ],
};

export const LAYER_NZ = {
    propertyData: [
        {
            id: 'ls',
            request: { uri: '/overlays/webParcelAnnotation', param: 'show%3A1' },
            label: 'Land Area',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'lsale',
            request: { uri: '/overlays/webParcelAnnotation', param: 'show%3A5%2C6' },
            label: 'Last Sale Price & Date',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'ld',
            request: { uri: '/overlays/webParcelAnnotation', param: 'show%3A3' },
            label: 'Legal Description',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'vr',
            request: { uri: '/overlays/webParcelAnnotation', param: 'show%3A4' },
            label: 'Valuation Reference',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'owner',
            request: { uri: '/overlays/webParcelAnnotation', param: 'show%3A2' },
            label: 'Owner Name',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'pdl',
            request: { uri: '/overlays/webParcelAnnotation', param: 'show%3A0' },
            label: 'Dimensions',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'pb',
            request: { uri: '/overlays/propertyParcel/mapServer/export', param: 'show%3A1'},
            label: 'Property Boundary',
            mapTypeId: '1006',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
    ],
    salesInfo: [
        {
            id: 'sold-6',
            request: { uri: '/overlays/sales', param: 'show%3A0' },
            label: 'Sold Last 6 Months',
            mapTypeId: '1006',
            customClass: 'red',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'sold-12',
            request: { uri: '/overlays/sales', param: 'show%3A1' },
            label: 'Sold Last 6 - 12 Months',
            mapTypeId: '1006',
            customClass: 'lavender',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'sold-24',
            request: { uri: '/overlays/sales', param: 'show%3A2' },
            label: 'Sold Last 12 - 24 Months',
            mapTypeId: '1006',
            customClass: 'pink',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'sold-36',
            request: { uri: '/overlays/sales', param: 'show%3A3' },
            label: 'Sold Last 24 - 36 Months',
            mapTypeId: '1006',
            customClass: 'orange',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
        {
            id: 'ms',
            request: { uri: '/overlays/sales', param: 'show%3A4' },
            label: 'Multiple Sales Last 3 Years',
            mapTypeId: '1006',
            customClass: 'purple',
            opacity: {
                roadmap: 0.3,
                hybrid: 0.6,
            },
        },
    ],
    recentListingInfo: [
        {
            id: 'otms',
            request: { uri: '/overlays/listings', param: 'show%3A0' },
            label: 'For Sale Last 6 Months',
            mapTypeId: '1006',
            customClass: 'lightblue',
            opacity: {
                roadmap: 0.6,
                hybrid: 1,
            },
        },
        {
            id: 'otmr',
            request: { uri: '/overlays/listings', param: 'show%3A1' },
            label: 'For Rent Last 6 Months',
            mapTypeId: '1006',
            customClass: 'green',
            opacity: {
                roadmap: 0.6,
                hybrid: 1,
            },
            tooltipTitle: 'Returning Soon',
        },
    ],
    areaResearch: [
        {
            id: 'et',
            request: { uri: '/overlays/easements/mapServer', param: '' },
            label: 'Easements',
            mapTypeId: '1006',
            customClass: 'et-bg-nz',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
            checkMapAdvancedRole: true,
        },
        {
            id: 'flood',
            request: { uri: '/overlays/flood/mapServer/export', param: 'show%3A54' },
            label: 'Flood',
            mapTypeId: '1006',
            customClass: 'fl-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
            checkMapAdvancedRole: true,
            showIfFeatureFlagTrue: true,
        },
        {
            id: NZ_FLOOD_LAYER,
            request: {
                publicDomain: 'https://gis.wellingtonwater.co.nz',
                uri: '/server1/rest/services/Modelling/WCC_Combined_100yearCC_Flood_Zones/MapServer/export',
                param: 'show%3A0',
            },
            label: 'Flood',
            mapTypeId: '1006',
            customClass: 'fl-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
            checkMapAdvancedRole: true,
            showIfFeatureFlagTrue: false,
            infoMessage: 'Currently only available for the Wellington & Auckland City Councils',
        },
        {
            id: 'ctr',
            request: { uri: '/basemaps/contour', param: '' },
            label: 'Contours',
            mapTypeId: '1006',
            customClass: 'loc-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
        },
        {
            id: 'bdr',
            request: { uri: '/overlays/baseBoundary', param: '' },
            label: 'Boundaries',
            mapTypeId: '1006',
            customClass: 'bdr-bg',
            opacity: {
                roadmap: 0.8,
                hybrid: 1,
            },
        },
    ],
    baseMap: [
        {
            id: 'corelogic-satellite',
            request: { uri: '/basemaps/aerial/MapServer/export', param: 'show%3A7%2C14%2C15%2C18%2C17%2C10%2C9%2C11%2C12%2C16%2C13%2C21%2C22%2C23%2C24%2C25%2C26%2C42%2C41%2C37%2' },
            label: 'CoreLogic Satellite',
            category: 'baseMap',
        },
        {
            id: 'corelogic-road',
            request: { uri: '/basemaps/streetmap/MapServer/export', param: 'show%3A7%2C14%2C15%2C18%2C17%2C10%2C9%2C11%2C12%2C16%2C13%2C21%2C22%2C23%2C24%2C25%2C26%2C42%2C41%2C37%2' },
            label: 'CoreLogic Road',
            category: 'baseMap',
        },
        {
            id: 'corelogic-hybrid',
            request: { uri: '/basemaps/aerialOverlay/MapServer/export', param: 'show%3A7%2C14%2C15%2C18%2C17%2C10%2C9%2C11%2C12%2C16%2C13%2C21%2C22%2C23%2C24%2C25%2C26%2C42%2C41%2C37%2C39%2C43%2C35%2C40%2C38%2C36%2C45%2C53%2C61%2C68%2C76%2C83%2C90%2C98%2C105%2C113%2C120' },
            label: 'CoreLogic Hybrid',
            category: 'baseMap',
        },
        {
            id: 'nearmap',
            request: '',
            label: 'Nearmap',
            category: 'baseMap',
        },
    ],
    boundary: [
        {
            id: 'Target Property Boundary',
            request: { uri: '/overlays/propertyParcel/mapServer/export', param: ''},
            label: 'Target Property Boundary',
            category: 'boundary',
        },
    ],
};

export const MAP_TOOL_TYPE = {
    PAN: 'pan',
    DISTANCE: 'distance',
    AREA: 'area',
    DELETE: 'delete',
    LAYER: 'layer',
    KEY: 'key',
    DOWNLOAD: 'download',
};

export const DRAWING_MODE = {
    POLYLINE: 'polyline',
    POLYGON: 'polygon',
};

export const DRAWING_MANAGER_OPTIONS = {
    polylineOptions: {
        editable: false,
        geodesic: true,
        strokeColor: '#3399ff',
        strokeOpacity: 1,
        strokeWeight: 3.5,
    },
    polygonOptions: {
        geodesic: true,
        fillColor: '#3399ff',
        fillOpacity: 0.5,
        strokeColor: '#3399ff',
        strokeWeight: 3.5,
    },
};

export const PARCEL_BOUNDARIES = {
    ...LAYER.targetProperty[1],
    layerName: LAYER.targetProperty[1].id,
    order: 1,
    category: 'targetProperty',
}

export const MAP_MARKER_IMAGE_NAME = {
    GENERIC_PIN: 'generic_property_pin_selected.png',
    MULTIPLE_PIN: 'icon_grey_marker_multiple properties.png',
    TARGET_PROPERTY_ACTIVE_PIN: 'target-property-active-pin.png',
    TARGET_PROPERTY_INACTIVE_PIN: 'target-property-inactive-pin.png',
};

export const MAP_UNAVAILABLE_MSG = 'Map is unavailable at the moment. Please try again later.';

export const DOWNLOAD_MAP_FILENAME = 'image.png';
