import Colors from 'js/constants/colors';

export const OTM_CAMPAIGNS = {
    LISTING: 'sales',
    RENTAL: 'rent',
};

export const OTM_ADVERTISEMENT_TYPE = {
    FOR_SALE: 'for-sale-advertisements',
    FOR_RENT_ADVERTISEMENT: 'for-rent-advertisements',
};

const OTM = {
    ALL: 'all',
    RECENT_SALE: 'recentSale',
    FOR_SALE: 'forSale',
    FOR_RENT: 'forRent',
    AGED: 'aged',
    WITHDRAWN: 'withdrawn',
    TENURE: 'tenure',
};

export const COLOR_BY_STATUS_TYPE = {
    all: Colors.SECONDARY.MAIN,
    recentSale: Colors.RECENT_SALE_TAG,
    forSale: Colors.FOR_SALE_TAG,
    forRent: Colors.FOR_RENT_TAG,
    aged: Colors.SECONDARY.MAIN,
    withdrawn: Colors.SECONDARY.MAIN,
    tenure: Colors.SECONDARY.MAIN,
    pending: Colors.PENDING_FILL_COLOR,
}

export default OTM;
