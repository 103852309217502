import Commons from '../Commons';
import OTM from 'js/constants/otm';
import { isAU, isNZ } from 'js/constants/crux';
import RAPID from 'js/constants/rapidSearch';
import SearchFilterHelper from 'js/helpers/search/SearchFilterHelper';
import SuggestionTypes from 'js/constants/suggestionTypes';
import ListPropertyPreview from 'js/constants/listPropertyPreview';

const handleInteger = (num) => {
    const parsedNumber = parseInt(num, 10);
    return Number.isNaN(parsedNumber) ? '' : parsedNumber.toString();
};

const handleFloat = (num) => {
    const parsedNumber = parseFloat(num);
    return Number.isNaN(parsedNumber) ? '' : parsedNumber.toString();
};

const splitRange = range => range.split('-');

const rangeToMinAndMax = (range, handler) => {
    if (range) {
        const [min, max] = splitRange(range);
        return { min: handler(min), max: handler(max) };
    }
    return null;
};

const rangeToMinAndMaxInteger = (range) => rangeToMinAndMax(range, handleInteger);
const rangeToMinAndMaxFloat = (range) => rangeToMinAndMax(range, handleFloat);

const pipeSeperatedToList = value => value
    ? value.split('|').map((item) => item.trim())
    : null;

const area = (range) => {
    const [min, max] = splitRange(range);
    const containsValue = min || max;
    const splitValue = Commons.splitUnitAndValue(decodeURIComponent(containsValue));

    return {
        min: handleFloat(min),
        max: handleFloat(max),
        unit: splitValue.unit || 'm²',
    };
};

const statusType = (value) => {
    const type = Commons.findItemInListIgnoreCase(value, Object.values(OTM));
    return type || OTM.ALL;
};

const handleRentalPeriod = (value) => {
    const lowerCaseValue = value?.toLowerCase() || '';
    const values = isAU ? RAPID.FILTER_PERIOD_VALUES_UNLEASH
        : RAPID.FILTER_PERIOD_VALUES;

    return values.includes(lowerCaseValue) ? lowerCaseValue : values[0];
}

const handleBoolean = value => Commons.parseBoolean(value?.toLowerCase());

const asIs = value => value ? decodeURIComponent(value).trim() : null;

const distance = (value) => {
    const formatted = rangeToMinAndMaxFloat(value) || {};

    return {
        min: formatted.min ? `${formatted.min}km` : '',
        max: formatted.max ? `${formatted.max}km` : '',
    };
};

const averageHoldPeriod = (value) => {
    const formatted = rangeToMinAndMaxInteger(value);
    return {
        house: Number(formatted?.min) || 0,
        unit: Number(formatted?.max) || 0,
    };
}

const tenureData = (value) => {
    const { isRangeFilterEmpty } = SearchFilterHelper;
    const [house, unit] = value.split('|').map((item) => item.trim());

    const formatRange = (range) => {
        const { min, max} = rangeToMinAndMaxInteger(range) || {};
        return { min: Number(min), max: Number(max) };
    };

    const holdPeriodHouse = formatRange(house);
    const holdPeriodUnit = formatRange(unit);
    const isEmptyHouse = isRangeFilterEmpty(holdPeriodHouse);
    const isEmptyUnit = isRangeFilterEmpty(holdPeriodUnit);

    if (isEmptyHouse && isEmptyUnit) return null;

    return {
        ...(isEmptyHouse ? {} : { holdPeriodHouse }),
        ...(isEmptyUnit ? {} : { holdPeriodUnit }),
    };
};

const sType = (suggestionType) =>
    Commons.findItemInListIgnoreCase(suggestionType, Object.values(SuggestionTypes)) || '';

const sort = (values) => {
    return values.split('|').map((value) => {
        const [attr, order] = value.split('-');
        return {
            attr,
            order: RAPID.SORT?.[order] || RAPID.ASCENDING_SORT,
        };
    })
}

const radius = (value) => {
    const intValue = handleFloat(value);
    return intValue ? `${intValue}Km` : '';
}
const handleIsCurrentOwner = isCurrentOwner => isCurrentOwner ? 'true' : 'true, false';

const commonDecoder = {
    beds: rangeToMinAndMaxInteger,
    baths: rangeToMinAndMaxInteger,
    carSpaces: rangeToMinAndMaxInteger,
    type: pipeSeperatedToList,
    landArea: area,
    floorArea: rangeToMinAndMaxFloat,
    yearBuilt: rangeToMinAndMaxInteger,
    subType: pipeSeperatedToList,
    developmentZoneDescription: pipeSeperatedToList,
    landUse: pipeSeperatedToList,
    keywords: pipeSeperatedToList,
    feeCode: asIs,
    factoryArea: area,
    mezzanineArea: area,
    officeShowroomArea: area,
    otherArea: area,
    shopArea: area,
    showroomArea: area,
    warehouseArea: area,
    workshopArea: area,
    distanceToHospital: distance,
    distanceToRiverFrontage: distance,
    distanceToSchool: distance,
    distanceToSeaRiverCanal: distance,
    distanceToShops: distance,
    boatRampMooringSpace: handleBoolean,
    fenced: handleBoolean,
    grannyFlatSelfContained: handleBoolean,
    lockupGarages: rangeToMinAndMaxInteger,
    swimmingPool: handleBoolean,
    treesOnLand: handleBoolean,
    noOfTennisCourts: rangeToMinAndMaxInteger,
    wallMaterial: asIs,
    materialsInExternalWalls: asIs,
    roofMaterial: asIs,
    materialsInRoof: asIs,
    canalFrontage: handleBoolean,
    coastFrontage: handleBoolean,
    golfFrontage: handleBoolean,
    highExposure: handleBoolean,
    lakeFrontage: handleBoolean,
    riverFrontage: handleBoolean,
    scenicView: handleBoolean,
    waterFrontage: handleBoolean,
    airConditioned: handleBoolean,
    ductedHeating: handleBoolean,
    builtInWardrobes: handleBoolean,
    ductedVacuum: handleBoolean,
    fireplace: handleBoolean,
    openPlan: handleBoolean,
    polishedFloors: handleBoolean,
    renovated: handleBoolean,
    diningRooms: rangeToMinAndMaxInteger,
    familyRumpusRooms: rangeToMinAndMaxInteger,
    floorLevelsInside: rangeToMinAndMaxInteger,
    kitchens: rangeToMinAndMaxInteger,
    loungeRooms: rangeToMinAndMaxInteger,
    loungeDiningRoomsCombined: rangeToMinAndMaxInteger,
    noOfStudyRooms: rangeToMinAndMaxInteger,
    toilets: rangeToMinAndMaxInteger,
    heritageOtherClassification: asIs,
    currentUnimprovedLandValue: rangeToMinAndMaxFloat,
    unimprovedCapitalValue: rangeToMinAndMaxFloat,
    unimprovedCapitalValueDate: rangeToMinAndMaxInteger,
    valuationLandValue: rangeToMinAndMaxFloat,
    valuationImprovementsValue: rangeToMinAndMaxFloat,
    valuationCapitalValue: rangeToMinAndMaxFloat,
    valuationAssessmentDate: rangeToMinAndMaxInteger,
    allWeatherAccessToLand: handleBoolean,
    allWeatherRoadsToFarm: handleBoolean,
    permanentWater: handleBoolean,
    sealedRoadsToProperty: handleBoolean,
    housesOnFarm: rangeToMinAndMaxInteger,
    irrigatedArea: rangeToMinAndMaxInteger,
    damsBores: rangeToMinAndMaxInteger,
    paddocksYards: rangeToMinAndMaxInteger,
    accessAvailable24Hrs: handleBoolean,
    accessSecurityInstalled: handleBoolean,
    closeToPublicTransport: handleBoolean,
    liftInstalled: handleBoolean,
    onMainRoad: handleBoolean,
    sewage: handleBoolean,
    townSewerageAvailable: handleBoolean,
    townWaterAvailable: handleBoolean,
    water: handleBoolean,
    buildingArea: area,
    equivalentBuildingArea: area,
    floorLevelOfUnit: rangeToMinAndMaxInteger,
    floorLevelsInsideUnit: rangeToMinAndMaxInteger,
    flats: rangeToMinAndMaxInteger,
    streetFrontage: rangeToMinAndMaxFloat,
    totalFloorsInBuilding: rangeToMinAndMaxInteger,
    yearBuildingRefurbished: rangeToMinAndMaxInteger,
    yearEstablished: rangeToMinAndMaxInteger,
    freeStandingBuilding: handleBoolean,
    statusType,
    sType,
    suggestions: asIs,
    active: handleBoolean,
    sort,
    ...(isAU ? {
        latestConsumerSaleEstimate: rangeToMinAndMaxInteger,
        latestConsumerSaleConfidence: pipeSeperatedToList,
    } : {})
};

const recentSaleDates = {
    salesLastSaleSettlementDate: rangeToMinAndMaxInteger,
    salesLastSaleContractDate: rangeToMinAndMaxInteger,
    salesLastSoldPrice: rangeToMinAndMaxFloat,
};

const forSaleDates = {
    salesLastCampaignStartDate: rangeToMinAndMaxInteger,
    salesLastCampaignEndDate: rangeToMinAndMaxInteger,
    salesLastCampaignLastListedPrice: rangeToMinAndMaxFloat,
};

const tenureFields = {
    averageHoldPeriod,
    tenureData,
};

const radiusFields = {
    radius,
    lat: handleFloat,
    lon: handleFloat,
    propertyId: handleInteger,
    suburbOnly: handleBoolean,
};

const buildingSearchFields = {
    addressBuildingName: asIs,
    propertyId: handleInteger,
    exactSearch: handleBoolean,
    addressSuburb: asIs,
    addressState: asIs,
};

const companySearchFields = {
    companyName: asIs,
    isCurrentOwner: handleIsCurrentOwner,
    exactSearch: handleBoolean,
    addressSuburb: asIs,
    addressState: asIs,
};

const nameSearchFields = {
    isCurrentOwner: handleIsCurrentOwner,
    exactSearch: handleBoolean,
    ownerFirstName: asIs,
    ownerLastName: asIs,
    addressSuburb: asIs,
    addressState: asIs,
};

const volumeFolioSearchFields = {
    folioNumber: asIs,
    volumeNumber: asIs,
    addressSuburb: asIs,
    addressState: asIs,
};

const rollValuationSearchFields = {
    valuationReference: asIs,
    addressSuburb: asIs,
};

const commonStatusTypes = {
    [OTM.ALL]: {
        ...commonDecoder,
        ...recentSaleDates,
        rentalOccupancy: handleBoolean,
    },
    [OTM.RECENT_SALE]: {
        ...commonDecoder,
        ...recentSaleDates,
        salesLastSaleSource: handleBoolean,
        salesLastSaleIsArmsLength: handleBoolean,
        ...(isNZ ? { salesClassification: asIs } : {}),
    },
    [OTM.FOR_SALE]: {
        ...commonDecoder,
        ...forSaleDates,
        isActiveForSaleCampaign: handleBoolean,
        salesLastCampaignListedType: pipeSeperatedToList,
    },
    [OTM.FOR_RENT]: {
        ...commonDecoder,
        isActiveForRentCampaign: handleBoolean,
        rentalLastCampaignStartDate: rangeToMinAndMaxInteger,
        rentalLastCampaignEndDate: rangeToMinAndMaxInteger,
        rentalLastCampaignLastListedPeriod: handleRentalPeriod,
        rentalLastCampaignLastListedPrice: rangeToMinAndMaxFloat,
    },
};

const filterDecoderAddress = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        ...tenureFields,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        ...tenureFields,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        ...tenureFields,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        ...tenureFields,
    },
    [OTM.AGED]: {
        ...commonDecoder,
        ...tenureFields,
        isActiveForSaleCampaign: handleBoolean,
        salesLastCampaignDaysOnMarket: handleInteger,
        salesLastCampaignLastListedPrice: rangeToMinAndMaxFloat,
    },
    [OTM.WITHDRAWN]: {
        ...commonDecoder,
        ...forSaleDates,
        ...tenureFields,
    },
    [OTM.TENURE]: {
        ...commonDecoder,
        ...tenureFields,
    },
};

const filterDecoderRadius = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        ...tenureFields,
        ...radiusFields,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        ...tenureFields,
        ...radiusFields,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        ...tenureFields,
        ...radiusFields,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        ...tenureFields,
        ...radiusFields,
    },
    [OTM.AGED]: {
        ...commonDecoder,
        ...forSaleDates,
        ...radiusFields,
        ...tenureFields,
        isActiveForSaleCampaign: handleBoolean,
        salesLastCampaignDaysOnMarket: handleInteger,
    },
    [OTM.WITHDRAWN]: {
        ...commonDecoder,
        ...forSaleDates,
        ...radiusFields,
        ...tenureFields,
    },
    [OTM.TENURE]: {
        ...commonDecoder,
        ...tenureFields,
    },
};

const filterDecoderBuilding = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        ...buildingSearchFields,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        ...buildingSearchFields,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        ...buildingSearchFields,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        ...buildingSearchFields,
    },
};

const filterDecoderCompany = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        ...companySearchFields,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        ...companySearchFields,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        ...companySearchFields,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        ...companySearchFields,
    },
};

const filterDecoderName = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        ...nameSearchFields,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        ...nameSearchFields,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        ...nameSearchFields,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        ...nameSearchFields,
    },
};

const filterDecoderParcel = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        addressParcelSuburbState: asIs,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        addressParcelSuburbState: asIs,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        addressParcelSuburbState: asIs,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        addressParcelSuburbState: asIs,
    },
};

const filterDecoderLegalDescription = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        legalDescription: asIs,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        legalDescription: asIs,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        legalDescription: asIs,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        legalDescription: asIs,
    },
};

const filterDecoderTitleReference = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        titleReference: asIs,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        titleReference: asIs,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        titleReference: asIs,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        titleReference: asIs,
    },
};

const filterDecoderVolumeFolio = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        ...volumeFolioSearchFields,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        ...volumeFolioSearchFields,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        ...volumeFolioSearchFields,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        ...volumeFolioSearchFields,
    },
};

const filterDecoderRollValuation = {
    [OTM.ALL]: {
        ...commonStatusTypes[OTM.ALL],
        ...rollValuationSearchFields,
    },
    [OTM.RECENT_SALE]: {
        ...commonStatusTypes[OTM.RECENT_SALE],
        ...rollValuationSearchFields,
    },
    [OTM.FOR_SALE]: {
        ...commonStatusTypes[OTM.FOR_SALE],
        ...rollValuationSearchFields,
    },
    [OTM.FOR_RENT]: {
        ...commonStatusTypes[OTM.FOR_RENT],
        ...rollValuationSearchFields,
    },
};
const SearchFilterDecoder = {
    [ListPropertyPreview.SEARCH_TYPE.ADDRESS]: filterDecoderAddress,
    [ListPropertyPreview.SEARCH_TYPE.RADIUS]: filterDecoderRadius,
    [ListPropertyPreview.SEARCH_TYPE.BUILDING]: filterDecoderBuilding,
    [ListPropertyPreview.SEARCH_TYPE.COMPANY]: filterDecoderCompany,
    [ListPropertyPreview.SEARCH_TYPE.NAME]: filterDecoderName,
    [ListPropertyPreview.SEARCH_TYPE.PARCEL]: filterDecoderParcel,
    [ListPropertyPreview.SEARCH_TYPE.LEGAL_DESCRIPTION]: filterDecoderLegalDescription,
    [ListPropertyPreview.SEARCH_TYPE.TITLE_REFERENCE]: filterDecoderTitleReference,
    [ListPropertyPreview.SEARCH_TYPE.VOLUME_FOLIO]: filterDecoderVolumeFolio,
    [ListPropertyPreview.SEARCH_TYPE.ROLL_VALUATION]: filterDecoderRollValuation,
    statusType,
};

export default SearchFilterDecoder;
