import ValidationMessage from '../constants/validationMessage';

const EMAIL_PATTERN = /^[A-Za-z0-9._%'+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,50}$/i;
const PHONE_PATTERN = /^(?=.*\d)[\d () + \s]*$/i;
const DIGIT_PATTERN = /^[\d () + \s]*$/;
const NAME_PATTERN = /^[a-zA-Z\d\s;',.:-]*$/;
const LOWER_UPPER_CASING_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
const SPECIAL_CHARACTERS = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?!.*\s).*$/;
const HAS_NUMBER_PATTERN = /^(?=.*\d)(?!.*\s).*$/;
const MIN_MAX_LENGTH_PATTERN = (min, max) => (`(?=^.{${min},${max}}$)(?!.*\\s).*$`);
const MIN_MAX_PATTERNS = /[^(|$|m²|ha|HA|Ha|hA)\d.]/;
const HEXADECIMALS = /^(?:#[0-9A-F]{6}|#[0-9A-F]{4}|#[0-9A-F]{3})$/i;

const EMPTY_WITHOUT_TRIM = (value = '', name) => {
    let error;
    if (value.length === 0) {
        error = `Must not be empty${name ? `. Please enter ${name}.` : ''}`;
    }
    return error;
};

const EMPTY = (value = '', name) => EMPTY_WITHOUT_TRIM(value.trim(), name);

const PHONE = (value) => {
    let error;
    if (value.length > 0 && !PHONE_PATTERN.test(value)) {
        error = 'Invalid character.';
    }
    return error;
};

const PHONE_NOT_EMPTY = (value) => {
    let error;
    error = EMPTY(value, 'a phone number');
    if (!error && !PHONE_PATTERN.test(value)) {
        error = 'Invalid character.';
    }
    return error;
};

const AREA_CODE_VALID = (value) => {
    let error;
    if (value && !PHONE_PATTERN.test(value)) {
        error = 'Invalid character.';
    }
    return error;
};

const EMAIL = (value) => {
    let error;
    error = EMPTY(value, 'an email address');
    if (!error && !EMAIL_PATTERN.test(value)) {
        error = 'Need a valid email address.';
    }
    return error;
};

const EMAIL_WITHOUT_VALUE_CHECK = (value) => {
    let error;
    if (!error && !EMAIL_PATTERN.test(value)) {
        error = 'Need a valid email address.';
    }
    return error;
};

const EMAIL_AND_LIMIT_LIST = (value, list = [], limit = 0) => {
    let error;
    error = EMAIL(value);
    if (!error && list.length) {
        error = list.length === limit
            ? 'You have reach the limit of email addresses that can be entered'
            : '';
    }
    return error;
};

const MOBILE_NUMBER = (value) => {
    let error;
    error = EMPTY(value, 'a mobile number');
    if (!error) {
        error = PHONE(value);
    }
    return error;
};

const NAME = (value, name) => {
    let error;
    error = EMPTY(value, name);
    if (!error && !NAME_PATTERN.test(value)) {
        error = 'Invalid character.';
    }
    return error;
};

// function below uses event as param

const NAME_KEY = (event) => {
    if (NAME_PATTERN.test(String.fromCharCode(event.charCode))) {
        return true;
    }
    event.preventDefault();
    return false;
};

const NUMBER_KEY = (event) => {
    const isCommand = event.metaKey || event.ctrlKey || event.altKey;
    if (DIGIT_PATTERN.test(String.fromCharCode(event.charCode)) || isCommand) {
        return true;
    }
    event.preventDefault();
    return false;
};

const MIN_MAX_LENGTH = (value, min, max) => {
    const regexp = new RegExp(MIN_MAX_LENGTH_PATTERN(min, max));
    if (regexp.test(value)) {
        return false;
    }
    return ValidationMessage.MIN_MAX_LENGTH;
};

const LOWER_UPPER_CASING = (value) => {
    if ((LOWER_UPPER_CASING_PATTERN).test(value)) {
        return false;
    }
    return ValidationMessage.LOWER_UPPER_CASING;
};

const HAS_NUMBER = (value) => {
    if ((HAS_NUMBER_PATTERN).test(value)) {
        return false;
    }
    return ValidationMessage.HAS_NUMBER;
};

const HAS_SPECIAL_CHARACTER = (value) => {
    if ((SPECIAL_CHARACTERS).test(value)) {
        return false;
    }
    return ValidationMessage.HAS_SPECIAL_CHARACTER;
};

const PASSWORD_MATCH = (value, matchValue) => {
    if (value !== matchValue) {
        return ValidationMessage.PASSWORD_MATCH;
    }
    return false;
};

// PASSWORD VALIDATION BASED ON THIS RULES:
// http://confluence.rpdata.local/display/BA/User+Password+Security+Rules
const NEW_PASSWORD = (value) => {
    const validations = {
        EMPTY,
        MIN_MAX_LENGTH,
        LOWER_UPPER_CASING,
        HAS_NUMBER,
        HAS_SPECIAL_CHARACTER,
    };

    const minChar = 8;
    const maxChar = 128;

    const error = Object.keys(validations).map((key) => {
        let validationResult = validations[key](value);
        if (key === 'MIN_MAX_LENGTH') {
            validationResult = validations[key](value, minChar, maxChar);
        }
        return {
            isError: validationResult,
            message: ValidationMessage[key],
        };
    });

    if (error.every(err => !err.isError)) {
        return null;
    }

    return error;
};

const CONFIRM_PASSWORD = (value, newPassword) => {
    let error = EMPTY(value);
    if (!error) {
        error = PASSWORD_MATCH(value, newPassword);
    }
    return error;
};

const SECURITY_ANSWER = (value) => {
    let error = EMPTY(value);
    if (!error) {
        error = (value.length > 100) ? ValidationMessage.SECURITY_ANSWER : false;
    }
    return error;
};

const EMPTY_ADDRESS = (value, message) => {
    let error = EMPTY(value);
    if (error) {
        error = `Must not be empty. ${message}.`;
    }
    return error;
};

const POST_CODE = (value, message) => {
    let error = EMPTY_ADDRESS(value, message);
    if (!error) {
        error = value.length !== 4 && 'Need a valid postcode. Please input a value of 4 digits';
    }
    return error;
};

const HEX_CODE = (value) => {
    if (value === '') {
        return 'Must not be empty.';
    } else if (!new RegExp(HEXADECIMALS).test(value)) {
        return 'Invalid hex code.';
    }
    return '';
};

export default {
    EMPTY,
    EMPTY_WITHOUT_TRIM,
    EMAIL_WITHOUT_VALUE_CHECK,
    EMAIL,
    MOBILE_NUMBER,
    PHONE,
    NAME,
    NAME_KEY,
    NUMBER_KEY,
    MIN_MAX_LENGTH,
    LOWER_UPPER_CASING,
    HAS_NUMBER,
    HAS_SPECIAL_CHARACTER,
    NEW_PASSWORD,
    CONFIRM_PASSWORD,
    PASSWORD_MATCH,
    SECURITY_ANSWER,
    PHONE_NOT_EMPTY,
    AREA_CODE_VALID,
    MIN_MAX_PATTERNS,
    EMPTY_ADDRESS,
    POST_CODE,
    EMAIL_AND_LIMIT_LIST,
    HEX_CODE,
};
