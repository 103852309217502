import Assets from '../assets';
import OTM from '../otm';
import DateFilterConst from 'js/constants/rapidSearch/dateFilter';
import SuggestionTypes from '../suggestionTypes';
import ListPropertyPreview from '../listPropertyPreview';

export const RESULT_VIEW = {
    LIST: 'List',
    CARD: 'Card',
    MAP: 'Map',
};

export const ALL = 'All';
export const LOWERCASE_ALL = ALL.toLowerCase();

const PILLS = [ALL, 'Recent Sale', 'For Sale', 'For Rent'];

const PROPERTY_TYPES = ['house', 'unit', 'flats', 'commercial', 'land', 'farm', 'other'];
const PROPERTY_TYPES_NZ = ['residential', 'commercial', 'industrial', 'lifestyle', 'rural', 'other'];
export const SUGGESTION_TYPE_TENURE = [
    SuggestionTypes.ADDRESS,
    SuggestionTypes.STATE,
    SuggestionTypes.POSTCODE,
    SuggestionTypes.COUNCIL_AREA,
    SuggestionTypes.LOCALITY,
    SuggestionTypes.SCHOOL,
    SuggestionTypes.TERRITORIAL_AUTHORITY,
    SuggestionTypes.SUBURB,
    SuggestionTypes.PARCEL,
    SuggestionTypes.LEGAL_DESC,
    SuggestionTypes.TITLE_REF,
];

export const ADVANCED_FILTER_FIELD_NAMES = {
    salesClassification: 'Sales Index',
    allWeatherAccessToLand: 'All Weather Access to Land',
    allWeatherRoadsToFarm: 'All Weather Roads to Farm',
    housesOnFarm: 'Houses on Farm',
    irrigatedArea: 'Irrigated Area',
    damsBores: 'No. of Dams/Bores',
    paddocksYards: 'Number of Paddocks/Yards',
    permanentWater: 'Permanent Water',
    sealedRoadsToProperty: 'Sealed Roads to Property',
    accessAvailable24Hrs: '24 Hour Access Available',
    accessSecurityInstalled: 'Access Security Installed',
    closeToPublicTransport: 'Close to Public Transport',
    liftInstalled: 'Lift Installed',
    onMainRoad: 'On Main Road',
    sewage: 'Sewage',
    townSewerageAvailable: 'Town Sewerage Available',
    townWaterAvailable: 'Town Water Available',
    water: 'Water',
    canalFrontage: 'Canal Frontage',
    coastFrontage: 'Coast Frontage',
    golfFrontage: 'Golf Frontage',
    highExposure: 'High Exposure',
    lakeFrontage: 'Lake Frontage',
    riverFrontage: 'River Frontage',
    scenicView: 'Scenic View',
    waterFrontage: 'Water Frontage',
    boatRampMooringSpace: 'Boat Ramp/Mooring/Space',
    fenced: 'Fenced',
    grannyFlatSelfContained: 'Granny Flat/Self Contained',
    lockupGarages: 'Lockup Garage',
    materialsInExternalWalls: 'Materials in External Walls',
    wallMaterial: 'Materials in External Walls',
    roofMaterial: 'Materials in Roof',
    materialsInRoof: 'Materials in Roof',
    swimmingPool: 'Swimming Pool',
    noOfTennisCourts: 'Tennis Courts',
    treesOnLand: 'Trees on Land',
    latestConsumerSaleEstimate: 'Valuation Estimate Price',
    latestConsumerSaleConfidence: 'Valuation Estimate Confidence',
    highConfidence: 'High Confidence',
    mediumToHighConfidence: 'Medium to High Confidence',
    mediumConfidence: 'Medium Confidence',
    lowToMediumConfidence: 'Low to Medium Confidence',
    lowConfidence: 'Low Confidence',
    currentUnimprovedLandValue: 'Current Unimproved Land Value',
    unimprovedCapitalValue: 'Unimproved Capital Value',
    unimprovedCapitalValueDate: 'Unimproved Capital Value Date',
    distanceToHospital: 'Distance to Hospital (km)',
    distanceToRiverFrontage: 'Distance to River Frontage (km)',
    distanceToSchool: 'Distance to School (km)',
    distanceToSeaRiverCanal: 'Distance to Sea/River/Canal (km)',
    distanceToShops: 'Distance to Shops (km)',
    feeCode: 'Fee Code',
    factoryArea: 'M2 of Factory Space',
    mezzanineArea: 'M2 of Mezzanine Space',
    officeShowroomArea: 'M2 of Office/Showroom Space',
    otherArea: 'M2 of Other Space',
    shopArea: 'M2 of Shop Space',
    showroomArea: 'M2 of Showroom Space',
    warehouseArea: 'M2 of Warehouse Space',
    workshopArea: 'M2 of Workshop Space',
    airConditioned: 'Air Conditioned',
    builtInWardrobes: 'Built In Wardrobes',
    ductedHeating: 'Ducted Heating',
    ductedVacuum: 'Ducted Vacuum',
    fireplace: 'Fireplace',
    openPlan: 'Open Plan',
    polishedFloors: 'Polished Floors',
    renovated: 'Renovated',
    diningRooms: 'Dining Rooms',
    familyRumpusRooms: 'Family/Rumpus Rooms',
    floorLevelsInside: 'Floor Levels Inside',
    kitchens: 'Kitchens',
    loungeRooms: 'Lounge Rooms',
    loungeDiningRoomsCombined: 'Lounge/Dining Rooms Combined',
    noOfStudyRooms: 'No. of Study',
    toilets: 'Toilets',
    heritageOtherClassification: 'Heritage/Other Classification',
    buildingArea: 'Building Area',
    equivalentBuildingArea: 'Equivalent Building Area',
    floorLevelOfUnit: 'Floor Level of Unit',
    floorArea: 'Floor Area',
    floorLevelsInsideUnit: 'No. Floor Levels Inside Unit',
    flats: 'Number of Flats',
    streetFrontage: 'Street Frontage (m)',
    totalFloorsInBuilding: 'Total Floors in Building',
    yearBuildingRefurbished: 'Year Building Refurbished',
    yearEstablished: 'Year Established',
    freeStandingBuilding: 'Free Standing Building',
    valuationLandValue: 'Land Value',
    valuationImprovementsValue: 'Improvements Value',
    valuationAssessmentDate: 'Valuation Date',
    valuationCapitalValue: 'Capital Value',
    salesLastSaleSettlementDate: 'Settlement Date',
    salesLastCampaignStartDate: 'First Listing Date',
    rentalLastCampaignStartDate: 'First Rental Date',
};

export const PROPERTY_TYPES_V2 = [
    { label: 'House', value: 'House' },
    { label: 'Unit', value: 'Unit' },
    { label: 'Flats', value: 'Flats' },
    { label: 'Commercial', value: 'Commercial' },
    { label: 'Business', value: 'Business' },
    { label: 'Land', value: 'Land' },
    { label: 'Farm', value: 'Farm' },
    { label: 'Community', value: 'Community' },
    { label: 'Storage Unit', value: 'Storage Unit' },
    { label: 'Other', value: 'Other' },
];

export const PROPERTY_TYPES_NZ_V2 = [
    { label: 'Residential', value: 'Residential' },
    { label: 'Commercial', value: 'Commercial' },
    { label: 'Industrial', value: 'Industrial' },
    { label: 'Mining', value: 'Mining' },
    { label: 'Lifestyle', value: 'Lifestyle' },
    { label: 'Arable', value: 'Arable' },
    { label: 'Dairying', value: 'Dairying' },
    { label: 'Forestry', value: 'Forestry' },
    { label: 'Horticulture', value: 'Horticulture' },
    { label: 'Pastoral', value: 'Pastoral' },
    { label: 'Utilities', value: 'Utilities' },
    { label: 'Specialist', value: 'Specialist' },
    { label: 'Other', value: 'Other' },
];

export const DAYS_ON_MARKET_OPTIONS = [
    { label: '50+ days', value: '50+ days' },
    { label: '60+ days', value: '60+ days' },
    { label: '70+ days', value: '70+ days' },
    { label: '80+ days', value: '80+ days' },
]

export const RURAL_FILTERS = [
    { label: ADVANCED_FILTER_FIELD_NAMES.allWeatherAccessToLand, value: 'allWeatherAccessToLand' },
    { label: ADVANCED_FILTER_FIELD_NAMES.allWeatherRoadsToFarm, value: 'allWeatherRoadsToFarm' },
    { label: ADVANCED_FILTER_FIELD_NAMES.permanentWater, value: 'permanentWater' },
    { label: ADVANCED_FILTER_FIELD_NAMES.sealedRoadsToProperty, value: 'sealedRoadsToProperty' },
];

export const RURAL_DROPDOWN_FILTERS = [
    { label: ADVANCED_FILTER_FIELD_NAMES.housesOnFarm, value: 'housesOnFarm' },
    { label: ADVANCED_FILTER_FIELD_NAMES.irrigatedArea, value: 'irrigatedArea' },
    { label: ADVANCED_FILTER_FIELD_NAMES.damsBores, value: 'damsBores' },
    { label: ADVANCED_FILTER_FIELD_NAMES.paddocksYards, value: 'paddocksYards' },
];

export const SERVICE_FILTERS = [
    { label: ADVANCED_FILTER_FIELD_NAMES.accessAvailable24Hrs, value: 'accessAvailable24Hrs' },
    { label: ADVANCED_FILTER_FIELD_NAMES.accessSecurityInstalled, value: 'accessSecurityInstalled' },
    { label: ADVANCED_FILTER_FIELD_NAMES.closeToPublicTransport, value: 'closeToPublicTransport' },
    { label: ADVANCED_FILTER_FIELD_NAMES.liftInstalled, value: 'liftInstalled' },
    { label: ADVANCED_FILTER_FIELD_NAMES.onMainRoad, value: 'onMainRoad' },
    { label: ADVANCED_FILTER_FIELD_NAMES.sewage, value: 'sewage' },
    { label: ADVANCED_FILTER_FIELD_NAMES.townSewerageAvailable, value: 'townSewerageAvailable' },
    { label: ADVANCED_FILTER_FIELD_NAMES.townWaterAvailable, value: 'townWaterAvailable' },
    { label: ADVANCED_FILTER_FIELD_NAMES.water, value: 'water' },
];

export const DISTANCE_FILTERS = [
    { label: ADVANCED_FILTER_FIELD_NAMES.distanceToHospital, value: 'distanceToHospital' },
    { label: ADVANCED_FILTER_FIELD_NAMES.distanceToRiverFrontage, value: 'distanceToRiverFrontage' },
    { label: ADVANCED_FILTER_FIELD_NAMES.distanceToSchool, value: 'distanceToSchool' },
    { label: ADVANCED_FILTER_FIELD_NAMES.distanceToSeaRiverCanal, value: 'distanceToSeaRiverCanal' },
    { label: ADVANCED_FILTER_FIELD_NAMES.distanceToShops, value: 'distanceToShops' },
];

export const FRONTAGE = [
    { label: ADVANCED_FILTER_FIELD_NAMES.canalFrontage, value: 'canalFrontage' },
    { label: ADVANCED_FILTER_FIELD_NAMES.coastFrontage, value: 'coastFrontage' },
    { label: ADVANCED_FILTER_FIELD_NAMES.golfFrontage, value: 'golfFrontage' },
    { label: ADVANCED_FILTER_FIELD_NAMES.highExposure, value: 'highExposure' },
    { label: ADVANCED_FILTER_FIELD_NAMES.lakeFrontage, value: 'lakeFrontage' },
    { label: ADVANCED_FILTER_FIELD_NAMES.riverFrontage, value: 'riverFrontage' },
    { label: ADVANCED_FILTER_FIELD_NAMES.scenicView, value: 'scenicView' },
    { label: ADVANCED_FILTER_FIELD_NAMES.waterFrontage, value: 'waterFrontage' },
];

export const VALUATION_CONFIDENCE = [
    { label: ADVANCED_FILTER_FIELD_NAMES.highConfidence, value: 'High', circleColor: '#39B54A' },
    { label: ADVANCED_FILTER_FIELD_NAMES.mediumToHighConfidence, value: 'Medium-High', circleColor: '#8ABA14' },
    { label: ADVANCED_FILTER_FIELD_NAMES.mediumConfidence, value: 'Medium', circleColor: '#EFA000' },
    { label: ADVANCED_FILTER_FIELD_NAMES.lowToMediumConfidence, value: 'Medium-Low', circleColor: '#F0763E' },
    { label: ADVANCED_FILTER_FIELD_NAMES.lowConfidence, value: 'Low', circleColor: '#FF0F0F' },
];

export const INTERNAL = {
    checkbox: [
        { label: ADVANCED_FILTER_FIELD_NAMES.airConditioned, value: 'airConditioned' },
        { label: ADVANCED_FILTER_FIELD_NAMES.builtInWardrobes, value: 'builtInWardrobes' },
        { label: ADVANCED_FILTER_FIELD_NAMES.ductedHeating, value: 'ductedHeating' },
        { label: ADVANCED_FILTER_FIELD_NAMES.ductedVacuum, value: 'ductedVacuum' },
        { label: ADVANCED_FILTER_FIELD_NAMES.fireplace, value: 'fireplace' },
        { label: ADVANCED_FILTER_FIELD_NAMES.openPlan, value: 'openPlan' },
        { label: ADVANCED_FILTER_FIELD_NAMES.polishedFloors, value: 'polishedFloors' },
        { label: ADVANCED_FILTER_FIELD_NAMES.renovated, value: 'renovated' },
    ],
    range: [
        { label: ADVANCED_FILTER_FIELD_NAMES.diningRooms, value: 'diningRooms' },
        { label: ADVANCED_FILTER_FIELD_NAMES.familyRumpusRooms, value: 'familyRumpusRooms' },
        { label: ADVANCED_FILTER_FIELD_NAMES.floorLevelsInside, value: 'floorLevelsInside' },
        { label: ADVANCED_FILTER_FIELD_NAMES.kitchens, value: 'kitchens' },
        { label: ADVANCED_FILTER_FIELD_NAMES.loungeRooms, value: 'loungeRooms' },
        { label: ADVANCED_FILTER_FIELD_NAMES.loungeDiningRoomsCombined, value: 'loungeDiningRoomsCombined' },
        { label: ADVANCED_FILTER_FIELD_NAMES.noOfStudyRooms, value: 'noOfStudyRooms' },
        { label: ADVANCED_FILTER_FIELD_NAMES.toilets, value: 'toilets' },
    ],
    textfield: {
        label: ADVANCED_FILTER_FIELD_NAMES.heritageOtherClassification, value: 'heritageOtherClassification',
    },
};
export const OTM_PRICE_FIELD = {
    [OTM.ALL]: 'Sale Price',
    [OTM.RECENT_SALE]: 'Sale Price',
    [OTM.FOR_SALE]: 'Listing Price',
    [OTM.FOR_RENT]: 'Rental Price',
    [OTM.AGED]: 'Listing Price',
    [OTM.WITHDRAWN]: 'Listing Price',
};

export const LISTING_TYPE_AU_V2 = [
    { label: 'Normal Sale', value: 'Normal Sale' },
    { label: 'Auction', value: 'Auction' },
    { label: 'Tender', value: 'Tender' },
    { label: 'Mortgagee Sale', value: 'Mortgagee Sale' },
    { label: 'Offers', value: 'Offers' },
    { label: 'Unknown', value: 'Unknown' },
];

export const LISTING_TYPE_NZ_V2 = [
    { label: 'Asking Price', value: 'Asking Price' },
    { label: 'Auction', value: 'Auction' },
    { label: 'By Negotiation', value: 'By Negotiation' },
    { label: 'Enquiries Over', value: 'Enquiries Over' },
    { label: 'Mortgagee Sale', value: 'Mortgagee Sale' },
    { label: 'Deadline Private Treaty', value: 'Deadline Private Treaty' },
    { label: 'Tender', value: 'Tender' },
    { label: 'Unknown', value: 'Unknown' },
];

const LISTING_TYPE_METHOD = {
    'Normal Sale': 'Normal Sale',
    Auction: 'Auction',
    Tender: 'Tender',
    'Mortgagee Sale': 'Mortgagee Sale',
    Offers: 'Offers',
    Unknown: 'Unknown',
};

const LISTING_TYPE_METHOD_NZ = {
    'Asking Price': 'Asking Price',
    Auction: 'Auction',
    'By Negotiation': 'By Negotiation',
    'Enquiries Over': 'Enquiries Over',
    'Mortgagee Sale': 'Mortgagee Sale',
    'Deadline Private Treaty': 'Deadline Private Treaty',
    Tender: 'Tender',
    Unknown: 'Unknown',
};

export const BED_BATH_CAR_RANGE_SELECT = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6+', label: '6+' },
];

export const METER_ONLY_RANGE_SELECT = [
    { value: '0.5', label: '0.5m' },
    { value: '1', label: '1m' },
    { value: '2.5', label: '2.5m' },
    { value: '5', label: '5m' },
    { value: '10', label: '10m' },
    { value: '25', label: '25m' },
    { value: '50', label: '50m' },
];

export const MIN_OPTION = { label: 'Min', value: '' };
export const MAX_OPTION = { label: 'Max', value: '' };

export const BASIC_RANGE_SELECT = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
];

export const STATUS_TYPE_OPTIONS = [
    {
        label: 'All Properties',
        subLabel: 'Search all properties regardless of status',
        value: OTM.ALL,
    },
    {
        label: 'Sales',
        subLabel: 'Search properties that have sold',
        value: OTM.RECENT_SALE,
    },
    {
        label: 'For Sale',
        subLabel: 'Search properties listed for sale',
        value: OTM.FOR_SALE,
    },
    {
        label: 'For Rent',
        subLabel: 'Search properties listed for rent',
        value: OTM.FOR_RENT,
    },
];

export const STATUS_TYPE_OPTIONS_ADDRESS = [
    ...STATUS_TYPE_OPTIONS,
    {
        label: 'Aged',
        subLabel: 'Search properties on the market longer than a selected number of days',
        value: OTM.AGED,
        entitlementChecker: 'hasProspectorRole',
    },
    {
        label: 'Withdrawn',
        subLabel: 'Properties listed in the last 365 days but no activity in the last 100 days',
        value: OTM.WITHDRAWN,
        entitlementChecker: 'hasProspectorRole',
    },
    {
        label: 'Tenure',
        subLabel: 'Search properties most likely to list based on past sales and market conditions',
        value: OTM.TENURE,
        entitlementChecker: 'hasProspectorRole',
        ldFlagKey: 'crux.tenure.search',
        tooltipText: 'Tenure is not available for street or multiple location searches',
    }
];

const BED_BATH_CAR = ['1', '2', '3', '4', '5', '6+'];

const LAND_SIZE_OPTIONS = [
    '100m\u00B2',
    '200m\u00B2',
    '300m\u00B2',
    '400m\u00B2',
    '500m\u00B2',
    '600m\u00B2',
    '700m\u00B2',
    '800m\u00B2',
    '900m\u00B2',
    '1,000m\u00B2',
    '1,250m\u00B2',
    '1,500m\u00B2',
    '1,750m\u00B2',
    '2,000m\u00B2',
    '5,000m\u00B2',
    '7,500m\u00B2',
    '1Ha',
    '2Ha',
    '5Ha',
    '10Ha',
];

export const LAND_SIZE_OPTIONS_V2 = [
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '300', value: '300' },
    { label: '400', value: '400' },
    { label: '500', value: '500' },
    { label: '600', value: '600' },
    { label: '700', value: '700' },
    { label: '800', value: '800' },
    { label: '900', value: '900' },
    { label: '1,000', value: '1000' },
    { label: '1,250', value: '1250' },
    { label: '1,500', value: '1500' },
    { label: '1,750', value: '1750' },
    { label: '2,000', value: '2000' },
    { label: '5,000', value: '5000' },
    { label: '7,500', value: '7500' },
];

export const FLOOR_AREA_OPTIONS = [
    { label: '100m\u00B2', value: '100m\u00B2' },
    { label: '200m\u00B2', value: '200m\u00B2' },
    { label: '300m\u00B2', value: '300m\u00B2' },
    { label: '400m\u00B2', value: '400m\u00B2' },
    { label: '500m\u00B2', value: '500m\u00B2' },
    { label: '600m\u00B2', value: '600m\u00B2' },
    { label: '700m\u00B2', value: '700m\u00B2' },
    { label: '800m\u00B2', value: '800m\u00B2' },
    { label: '900m\u00B2', value: '900m\u00B2' },
    { label: '1,000m\u00B2', value: '1,000m\u00B2' },
    { label: '1,250m\u00B2', value: '1,250m\u00B2' },
    { label: '1,500m\u00B2', value: '1,500m\u00B2' },
    { label: '1,750m\u00B2', value: '1,750m\u00B2' },
    { label: '2,000m\u00B2', value: '2,000m\u00B2' },
];

const SALE_PRICE_OPTION = [
    '$100,000',
    '$150,000',
    '$200,000',
    '$250,000',
    '$300,000',
    '$350,000',
    '$400,000',
    '$450,000',
    '$500,000',
    '$600,000',
    '$700,000',
    '$800,000',
    '$900,000',
    '$1,000,000',
    '$1,250,000',
    '$1,500,000',
    '$2,000,000',
    '$5,000,000',
    '$10,000,000',
];
export const SALE_PRICE_OPTION_V2 = [
    { label: '$100,000', value: '100000' },
    { label: '$150,000', value: '150000' },
    { label: '$200,000', value: '200000' },
    { label: '$250,000', value: '250000' },
    { label: '$300,000', value: '300000' },
    { label: '$350,000', value: '350000' },
    { label: '$400,000', value: '400000' },
    { label: '$450,000', value: '450000' },
    { label: '$500,000', value: '500000' },
    { label: '$600,000', value: '600000' },
    { label: '$700,000', value: '700000' },
    { label: '$800,000', value: '800000' },
    { label: '$900,000', value: '900000' },
    { label: '$1,000,000', value: '1000000' },
    { label: '$1,250,000', value: '1250000' },
    { label: '$1,500,000', value: '1500000' },
    { label: '$2,000,000', value: '2000000' },
    { label: '$5,000,000', value: '5000000' },
    { label: '$10,000,000', value: '10000000' },
];

const RENTAL_PRICE_OPTION = [
    '$100',
    '$150',
    '$200',
    '$250',
    '$300',
    '$350',
    '$400',
    '$450',
    '$500',
    '$600',
    '$700',
    '$800',
    '$900',
    '$1,000',
    '$1,250',
    '$1,500',
    '$2,000',
    '$3,000',
    '$5,000',
    '$6,000',
    '$7,000',
    '$8,000',
    '$9,000',
    '$10,000',
    '$15,000',
    '$20,000',
    '$30,000',
    '$40,000',
    '$50,000',
    '$60,000',
    '$70,000',
    '$80,000',
    '$90,000',
    '$100,000',
    '$120,000',
    '$140,000',
    '$160,000',
    '$180,000',
    '$200,000',
    '$300,000',
    '$500,000',
    '$800,000',
    '$1,000,000',
    '$5,000,000',
    '$10,000,000',
];

export const RENTAL_PRICE_OPTION_V2 = [
    { label: '$100', value: '100' },
    { label: '$150', value: '150' },
    { label: '$200', value: '200' },
    { label: '$250', value: '250' },
    { label: '$300', value: '300' },
    { label: '$350', value: '350' },
    { label: '$400', value: '400' },
    { label: '$450', value: '450' },
    { label: '$500', value: '500' },
    { label: '$600', value: '600' },
    { label: '$700', value: '700' },
    { label: '$800', value: '800' },
    { label: '$900', value: '900' },
    { label: '$1,000', value: '1000' },
    { label: '$1,250', value: '1250' },
    { label: '$1,500', value: '1500' },
    { label: '$2,000', value: '2000' },
    { label: '$3,000', value: '3000' },
    { label: '$5,000', value: '5000' },
    { label: '$6,000', value: '6000' },
    { label: '$7,000', value: '7000' },
    { label: '$8,000', value: '8000' },
    { label: '$9,000', value: '9000' },
    { label: '$10,000', value: '10000' },
    { label: '$15,000', value: '15000' },
    { label: '$20,000', value: '20000' },
    { label: '$30,000', value: '30000' },
    { label: '$40,000', value: '40000' },
    { label: '$50,000', value: '50000' },
    { label: '$60,000', value: '60000' },
    { label: '$70,000', value: '70000' },
    { label: '$80,000', value: '80000' },
    { label: '$90,000', value: '90000' },
    { label: '$100,000', value: '100000' },
    { label: '$120,000', value: '120000' },
    { label: '$140,000', value: '140000' },
    { label: '$160,000', value: '160000' },
    { label: '$180,000', value: '180000' },
    { label: '$200,000', value: '200000' },
    { label: '$300,000', value: '300000' },
    { label: '$500,000', value: '500000' },
    { label: '$800,000', value: '800000' },
    { label: '$1,000,000', value: '1000000' },
    { label: '$5,000,000', value: '5000000' },
    { label: '$10,000,000', value: '10000000' },
];

const COMMON_SEARCH_RESULTS_HEADER = [
    {
        id: 'search-result-col-address',
        label: 'Address',
        attribute: 'address',
    },
    {
        id: 'search-result-col-bedroom',
        label: Assets.ATTRIBUTES.BEDS,
        tooltip: 'Bedrooms',
        className: 'text-center',
        attribute: 'beds',
    },
    {
        id: 'search-result-col-bathroom',
        label: Assets.ATTRIBUTES.BATHS,
        tooltip: 'Bathrooms',
        className: 'text-center',
        attribute: 'baths',
    },
    {
        id: 'search-result-col-car',
        label: Assets.ATTRIBUTES.CARS,
        tooltip: 'Car Spaces',
        className: 'text-center',
        attribute: 'carSpaces',
    },
    {
        id: 'search-result-col-land-area',
        label: Assets.ATTRIBUTES.LANDSIZE,
        tooltip: 'Land Size',
        className: 'text-center',
        attribute: 'landArea',
    },
    {
        id: 'search-result-col-property-type',
        label: 'Type',
    },
];
const SALES_HEADER = [
    {
        id: 'search-result-col-sale-date',
        label: 'Sale Date',
        className: 'col-text-center',
        attribute: 'salesLastSaleContractDate',
    },
    {
        id: 'search-result-col-sale-price',
        label: 'Sale Price',
        className: 'col-text-center',
        attribute: 'salesLastSoldPrice',
    },
];

const IN_LISTINGS_HEADER = [
    {
        id: 'search-result-col-sale-date',
        label: 'Listing Date',
        className: 'col-text-center',
        attribute: 'salesLastCampaignEndDate',
    },
    {
        id: 'search-result-col-sale-price',
        label: 'Listing Price',
        className: 'col-text-center',
        attribute: 'salesLastCampaignLastListedPrice',
    },
    {
        id: 'search-result-col-sale-type',
        attribute: 'salesLastCampaignListedType',
        label: 'Listing Type',
    },
];
const SEARCH_RESULTS_HEADER = {
    all: [
        ...COMMON_SEARCH_RESULTS_HEADER,
        ...SALES_HEADER,
        {
            id: 'search-result-col-owner-name',
            label: 'Owner Name',
        },
    ],
    recentSale: [
        ...COMMON_SEARCH_RESULTS_HEADER,
        ...SALES_HEADER,
        {
            id: 'search-result-col-sale-type',
            label: 'Sale Type',
        },
    ],
    tenure: [
        ...COMMON_SEARCH_RESULTS_HEADER,
        ...SALES_HEADER,
        {
            id: 'search-result-col-sale-type',
            label: 'Sale Type',
        },
    ],
    forSale: [
        ...COMMON_SEARCH_RESULTS_HEADER,
        ...IN_LISTINGS_HEADER
    ],
    forRent: [
        ...COMMON_SEARCH_RESULTS_HEADER,
        {
            id: 'search-result-col-sale-date',
            label: 'Rental Date',
            className: 'col-text-center',
            attribute: 'rentalLastCampaignEndDate',
        },
        {
            id: 'search-result-col-sale-price',
            label: 'Rental Price',
            className: 'col-text-center',
            attribute: 'rentalLastCampaignLastListedPrice',
        },
    ],
    aged: [
        ...COMMON_SEARCH_RESULTS_HEADER,
        ...IN_LISTINGS_HEADER
    ],
    withdrawn: [
        ...COMMON_SEARCH_RESULTS_HEADER,
        ...IN_LISTINGS_HEADER
    ],
};

const FOR_SALE_LISTING_TYPE = [
    {
        label: 'All',
        value: 'all',
    }, {
        label: 'Normal Sale',
        value: 'S',
    }, {
        label: 'Auction',
        value: 'A',
    }, {
        label: 'Tender',
        value: 'T',
    }, {
        label: 'Mortgagee Sale',
        value: 'M',
    }, {
        label: 'Mortgagee Auction',
        value: 'MA',
    }, {
        label: 'Offers',
        value: 'O',
    },
];

const FOR_RENT_PERIOD = [
    {
        label: 'Weekly',
        value: 'W',
    },
    {
        label: 'Daily',
        value: 'D',
    },
    {
        label: 'Fortnightly',
        value: 'F',
    }, {
        label: 'Monthly',
        value: 'M',
    }, {
        label: 'Quarterly',
        value: 'Q',
    }, {
        label: 'Annual',
        value: 'A',
    },
];

const TABS_ATTR = {
    all: 'Property',
    recentSale: 'Sales History',
    forSale: 'On The Market',
    forRent: 'Rentals',
    aged: 'Aged Listings',
    withdrawn: 'Withdrawn Listings',
    tenure: 'Tenure',
};

const WEEKLY = 'week';
const MONTHLY = 'month';
const YEARLY = 'year';
const UNLEASH_WEEKLY = 'weekly';
const UNLEASH_MONTHLY = 'monthly';
const UNLEASH_YEARLY = 'annually';

const FILTER_PERIOD_VALUES = [
    WEEKLY,
    MONTHLY,
    YEARLY,
];

const FILTER_PERIOD_VALUES_UNLEASH = [
    UNLEASH_WEEKLY,
    UNLEASH_MONTHLY,
    UNLEASH_YEARLY,
];

const FILTER_PERIOD_OPTIONS = {
    [WEEKLY]: {
        label: 'Weekly',
    },
    [MONTHLY]: {
        label: 'Monthly',
    },
    [YEARLY]: {
        label: 'Yearly',
    },
    [UNLEASH_WEEKLY]: {
        label: 'Weekly',
    },
    [UNLEASH_MONTHLY]: {
        label: 'Monthly',
    },
    [UNLEASH_YEARLY]: {
        label: 'Yearly',
    },
};

const EXCLUDE_RECENT_ADVISE_TEXT = 'Exclude Recently Advised Sales';
const EXCLUDE_RECENT_ADVISE_TEXT_V2 = 'Exclude recently advised sales';
const EXCLUDE_RELATED_PARTIES = 'Excluded related parties';
const SHOW_ACTIVE_LISTINGS_ONLY = 'Show active listings only';
const EXCLUDE_LIKELY_RENTAL_PROPERTIES = 'Exclude likely rental properties';

const ERROR_MESSAGE = {
    lastNameRequired: '*Last Name must not be empty',
    stateRequired: '*Must select a State',
    companyNameRequired: '*Company name must not be empty',
    volumeNumberRequired: '*Volume number must not be empty',
    buildingNameRequired: '*Building name must not be empty',
    rollValuationRequired: '*Roll/Valuation must not be empty',
};

const RETURN_FIELDS = {
    lastSale: 'lastSale',
    defaultPhoto: 'defaultPhoto',
    attributes: 'attributes',
    location: 'location',
    attributesAdditional: 'attributesAdditional',
    site: 'site',
    photoList: 'photoList',
    legalDetails: 'legalDetails',
    featureDetails: 'featureDetails',
    occupancyDetails: 'occupancyDetails',
    saleCampaign: 'saleCampaign',
    rentCampaign: 'rentCampaign',
};

const RADIUS_SEARCH_DISTANCE_OPTIONS = [
    {
        label: '0.5Km',
        value: '0.5km',
    },
    {
        label: '1Km',
        value: '1km',
    },
    {
        label: '2.5Km',
        value: '2.5km',
    },
    {
        label: '5Km',
        value: '5km',
    },
    {
        label: '10Km',
        value: '10km',
    },
    {
        label: '25Km',
        value: '25km',
    },
    {
        label: '50Km',
        value: '50km',
    },
];

const RADIUS_SEARCH_DISTANCE_OPTIONS_V2 = [
    {
        label: '0.5Km',
        value: '0.5Km',
    },
    {
        label: '1Km',
        value: '1Km',
    },
    {
        label: '2.5Km',
        value: '2.5Km',
    },
    {
        label: '5Km',
        value: '5Km',
    },
    {
        label: '10Km',
        value: '10Km',
    },
    {
        label: '25Km',
        value: '25Km',
    },
    {
        label: '50Km',
        value: '50Km',
    },
];

const SEARCH_FILTER_FORMS = {
    ADDRESS: 'addressSearchFilterForm',
    RADIUS: 'radiusSearchFilterForm',
};

const ASCENDING_SORT = '+';
const DESCENDING_SORT = '-';

const SORT = {
    asc: ASCENDING_SORT,
    desc: DESCENDING_SORT,
};

const SEARCH_DEFAULT_SORT = {
    RADIUS: [{ attr: 'distance', order: ASCENDING_SORT }, { attr: 'address', order: ASCENDING_SORT }],
    ADDRESS: [{ attr: 'address', order: ASCENDING_SORT }],
};

const VALUER_GENERAL = 'Valuer General,Valuer General Confirmed';

export const INITIAL_ERROR_FIELDS = {
    [OTM.ALL]: [],
    [OTM.FOR_RENT]: [],
    [OTM.FOR_SALE]: [],
    [OTM.RECENT_SALE]: [],
    [OTM.AGED]: [],
    [OTM.WITHDRAWN]: [],
    [OTM.TENURE]: [],
};
const SUMMARY_RETURN_FIELDS = [
    RETURN_FIELDS.location,
    RETURN_FIELDS.photoList,
    RETURN_FIELDS.lastSale,
];

const MAX_NUMBER_OF_SEARCH_RESULTS = 1000;
const MAX_NUMBER_OF_RADIUS_SEARCH_RESULTS = MAX_NUMBER_OF_SEARCH_RESULTS - 1;

const getTableHeaders = (statusType, additionalHeader) => {
    if (additionalHeader) {
        return [...SEARCH_RESULTS_HEADER[statusType], additionalHeader];
    }
    return SEARCH_RESULTS_HEADER[statusType];
};
const MAX_NUMBER_OF_RESULTS_HAS_REACHED = 'Max number of results returned. Please refine your search criteria.';

const SORT_BY_SETTING_AU = {
    [OTM.ALL]: 'ALL_SORT_BY_AU',
    [OTM.RECENT_SALE]: 'RECENT_SALE_SORT_BY_AU',
    [OTM.FOR_SALE]: 'FOR_SALE_SORT_BY_AU',
    [OTM.FOR_RENT]: 'FOR_RENT_SORT_BY_AU',
    [OTM.AGED]: 'AGED_SORT_BY_AU',
    [OTM.WITHDRAWN]: 'WITHDRAWN_SORT_BY_AU',
    [OTM.TENURE]: 'TENURE_SORT_BY_AU',
};

const SORT_BY_SETTING_NZ = {
    [OTM.ALL]: 'ALL_SORT_BY_NZ',
    [OTM.RECENT_SALE]: 'RECENT_SALE_SORT_BY_NZ',
    [OTM.FOR_SALE]: 'FOR_SALE_SORT_BY_NZ',
    [OTM.FOR_RENT]: 'FOR_RENT_SORT_BY_NZ',
    [OTM.AGED]: 'AGED_SORT_BY_NZ',
    [OTM.WITHDRAWN]: 'WITHDRAWN_SORT_BY_NZ',
    [OTM.TENURE]: 'TENURE_SORT_BY_NZ',
};

const RADIUS_SORT_BY_SETTING_AU = {
    [OTM.ALL]: 'RADIUS_ALL_SORT_BY_AU',
    [OTM.RECENT_SALE]: 'RADIUS_RECENT_SALE_SORT_BY_AU',
    [OTM.FOR_SALE]: 'RADIUS_FOR_SALE_SORT_BY_AU',
    [OTM.FOR_RENT]: 'RADIUS_FOR_RENT_SORT_BY_AU',
    [OTM.AGED]: 'RADIUS_AGED_SORT_BY_AU',
    [OTM.WITHDRAWN]: 'RADIUS_WITHDRAWN_SORT_BY_AU',
};

const RADIUS_SORT_BY_SETTING_NZ = {
    [OTM.ALL]: 'RADIUS_ALL_SORT_BY_NZ',
    [OTM.RECENT_SALE]: 'RADIUS_RECENT_SALE_SORT_BY_NZ',
    [OTM.FOR_SALE]: 'RADIUS_FOR_SALE_SORT_BY_NZ',
    [OTM.FOR_RENT]: 'RADIUS_FOR_RENT_SORT_BY_NZ',
    [OTM.AGED]: 'RADIUS_AGED_SORT_BY_NZ',
    [OTM.WITHDRAWN]: 'RADIUS_WITHDRAWN_SORT_BY_NZ',
};

const ACTIVE_LISTING_ONLY_SETTING_AU = {
    [OTM.FOR_SALE]: 'FOR_SALE_IS_ACTIVE_LISTINGS_ONLY_AU',
    [OTM.FOR_RENT]: 'FOR_RENT_IS_ACTIVE_LISTINGS_ONLY_AU',
};
const ACTIVE_LISTING_ONLY_SETTING_NZ = {
    [OTM.FOR_SALE]: 'FOR_SALE_IS_ACTIVE_LISTINGS_ONLY_NZ',
    [OTM.FOR_RENT]: 'FOR_RENT_IS_ACTIVE_LISTINGS_ONLY_NZ',
};

const SUGGEST_FILTERS_AU = {
    exactAddress: 'addressComplete',
    address: 'addressCollective',
    street: 'addressStreetSuburbStatePostcode',
    locality: 'addressSuburbStatePostcode',
    postcode: 'addressPostcodeState',
    state: 'addressState',
    councilArea: 'councilAreaState',
};

const SUGGEST_FILTERS_NZ = {
    exactAddress: 'addressComplete',
    address: 'addressContains',
    street: 'addressStreetSuburbTown',
    locality: 'addressSuburbTown',
    councilArea: 'councilArea',
    postcode: 'addressPostcode',
    streetOnly: 'addressStreet',
    territorialAuthority: 'councilArea',
};

const DATE_FILTER_OPTIONS = {
    [DateFilterConst.THREE_MONTHS]: {
        label: 'Last 3 Months',
        value: 'Last 3',
        less: 3,
    },
    [DateFilterConst.SIX_MONTHS]: {
        label: 'Last 6 Months',
        value: 'Last 6',
        less: 6,
    },
    [DateFilterConst.NINE_MONTHS]: {
        label: 'Last 9 Months',
        value: 'Last 9',
        less: 9,
    },
    [DateFilterConst.TWELVE_MONTHS]: {
        label: 'Last 12 Months',
        value: 'Last 12',
        less: 12,
    },
    [DateFilterConst.EIGHTEEN_MONTHS]: {
        label: 'Last 18 Months',
        value: 'Last 18',
        less: 18,
    },
    [DateFilterConst.TWENTYFOUR_MONTHS]: {
        label: 'Last 24 Months',
        value: 'Last 24',
        less: 24,
    },
    custom: {
        label: 'Custom Date',
        value: 'Custom',
        less: 1,
    },
};

export const SALES_CLASSIFICATION_OPTIONS = [
    { label: 'Select', value: '' },
    { label: 'Market Index', value: 'Market Index' },
    { label: 'Non-Market', value: 'Non-Market' },
    { label: 'Market Non-Index', value: 'Market Non-Index' },
    { label: 'Not Classified', value: 'Not Classified' },
];

export const FEE_CODE_OPTIONS = [
    { label: 'Select', value: '' },
    { label: 'Exempt', value: 'Exempt' },
    { label: 'Freehold', value: 'Freehold' },
    { label: 'Freehold/Leasehold', value: 'Freehold/Leasehold' },
    { label: 'Leasehold', value: 'Leasehold' },
    { label: 'State Land', value: 'State Land' },
];

export const STRUCTURE_RANGE_FILTERS = [
    'buildingArea', 'equivalentBuildingArea', 'floorLevelOfUnit',
    'floorLevelsInsideUnit', 'flats', 'streetFrontage',
    'totalFloorsInBuilding', 'yearBuildingRefurbished', 'yearEstablished',
];

export const COMMERCIAL_RANGE_FILTERS = [
    'factoryArea', 'mezzanineArea', 'officeShowroomArea', 'otherArea',
    'shopArea','showroomArea', 'warehouseArea', 'workshopArea',
];

export const EXTERNAL_RANGE_FILTERS = [
    'lockupGarages', 'noOfTennisCourts',
];

const AREA_FILTERS = ['landArea', 'factoryArea', 'mezzanineArea', 'officeShowroomArea', 'otherArea',
    'showroomArea', 'warehouseArea', 'workshopArea', 'shopArea',
    'buildingArea', 'equivalentBuildingArea'];

const BASE_FILTER_BY_SEARCH_TYPE = {
    [ListPropertyPreview.SEARCH_TYPE.PARCEL]: 'addressParcelSuburbState',
    [ListPropertyPreview.SEARCH_TYPE.TITLE_REFERENCE]: 'titleReference',
    [ListPropertyPreview.SEARCH_TYPE.LEGAL_DESCRIPTION]: 'legalDescription',
};


export default {
    VALUER_GENERAL,
    SEARCH_RESULTS_HEADER,
    FOR_SALE_LISTING_TYPE,
    FOR_RENT_PERIOD,
    PILLS,
    PROPERTY_TYPES,
    PROPERTY_TYPES_NZ,
    BED_BATH_CAR,
    BED_BATH_CAR_RANGE_SELECT,
    TABS_ATTR,
    WEEKLY,
    MONTHLY,
    YEARLY,
    FILTER_PERIOD_OPTIONS,
    LAND_SIZE_OPTIONS,
    SALE_PRICE_OPTION,
    RENTAL_PRICE_OPTION,
    LISTING_TYPE_METHOD,
    LISTING_TYPE_METHOD_NZ,
    EXCLUDE_RECENT_ADVISE_TEXT,
    EXCLUDE_RECENT_ADVISE_TEXT_V2,
    SHOW_ACTIVE_LISTINGS_ONLY,
    EXCLUDE_LIKELY_RENTAL_PROPERTIES,
    ERROR_MESSAGE,
    RETURN_FIELDS,
    RADIUS_SEARCH_DISTANCE_OPTIONS,
    RADIUS_SEARCH_DISTANCE_OPTIONS_V2,
    SEARCH_FILTER_FORMS,
    SEARCH_DEFAULT_SORT,
    ASCENDING_SORT,
    DESCENDING_SORT,
    INITIAL_ERROR_FIELDS,
    SUMMARY_RETURN_FIELDS,
    LAND_SIZE_OPTIONS_V2,
    MAX_NUMBER_OF_SEARCH_RESULTS,
    MAX_NUMBER_OF_RADIUS_SEARCH_RESULTS,
    MAX_NUMBER_OF_RESULTS_HAS_REACHED,
    getTableHeaders,
    ACTIVE_LISTING_ONLY_SETTING_AU,
    ACTIVE_LISTING_ONLY_SETTING_NZ,
    SUGGEST_FILTERS_AU,
    SUGGEST_FILTERS_NZ,
    DATE_FILTER_OPTIONS,
    DAYS_ON_MARKET_OPTIONS,
    FEE_CODE_OPTIONS,
    COMMERCIAL_RANGE_FILTERS,
    STRUCTURE_RANGE_FILTERS,
    FLOOR_AREA_OPTIONS,
    EXCLUDE_RELATED_PARTIES,
    SORT_BY_SETTING_AU,
    SORT_BY_SETTING_NZ,
    FILTER_PERIOD_VALUES,
    FILTER_PERIOD_VALUES_UNLEASH,
    UNLEASH_WEEKLY,
    UNLEASH_MONTHLY,
    UNLEASH_YEARLY,
    SORT,
    AREA_FILTERS,
    BASE_FILTER_BY_SEARCH_TYPE,
    RADIUS_SORT_BY_SETTING_AU,
    RADIUS_SORT_BY_SETTING_NZ,
};
