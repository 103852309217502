import API from './index';
import methods from '../constants/methods';
import CLAPI from "./clapi";
import { UriBuilder } from '../helpers/UriBuilder';

const { large: longApiTimeout } = API.getTimeouts();
const getCoverage = (clUserId, polygon) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/nearmap/coverage/${polygon}/?${appAccountUserGuid}`,
        isProxy: true,
        timeout: longApiTimeout,
    }
    const headerOptions = {
        method: methods.GET.method,
    }
    return API.initiateSessionApiCall(requestOptions, headerOptions);
}

const getAucklandFloodLayerGeoJson = async (bounds, geometryPrecision, signal) => {
    const {
        north, east, west, south,
    } = bounds;

    const publicBaseUri = 'https://services1.arcgis.com/n4yPwebTjJCmXB6W/arcgis/rest/services/Flood_Sensitive_Areas/FeatureServer/0/query';
    const aucklandFloodLayerUrl = new UriBuilder(publicBaseUri)
        .addParam('geometry', `${west},${south},${east},${north}`)
        .addParam('geometryType', 'esriGeometryEnvelope')
        .addParam('inSR', '4326')
        .addParam('spatialRel', 'esriSpatialRelIntersects')
        .addParam('units', 'esriSRUnit_Meter')
        .addParam('returnGeometry', 'true')
        .addParam('returnEnvelope', 'true')
        .addParam('featureEncoding', 'esriDefault')
        .addParam('outSR', '4326')
        .addParam('returnExceededLimitFeatures', 'true')
        .addParam('f', 'geojson');

    if (geometryPrecision) {
        aucklandFloodLayerUrl.addParam('geometryPrecision', geometryPrecision);
    }

    const options = signal ? { signal, method: 'get' } : {}
    const res = await fetch(aucklandFloodLayerUrl.build(), options);
    return await res.json();
}

export default {
    Nearmap: {
        getCoverage,
    },
    getAucklandFloodLayerGeoJson,
}
