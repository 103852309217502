import { all } from 'redux-saga/effects';

import configSagas from './config';
import segmentSagas from './segment';
import claudSagas from './claud';
import clapiSagas from './clapi';
import msgApiSagas from './msgApi';
import signatureSagas from './signature';
import init from './init';
import titlesSagas from './titles';
import searchResultSagas from './searchResults';
import cruxSagas from './crux';
import userPreferencesSagas from './userPreferences';
import rapidSagas from './rapid';
import linkedAccounts from './linkedAccounts';
import manageUsersSagas from './manageUsers';
import manageBrandingSagas from './manageBranding';
import customiseReportSagas from './customiseReport';
import previewReport from './previewReport';
import map from './map';
import printPdf from './printPdf';
import savedSearch from './savedSearch';
import watchList from './watchList';
import territories from './territories';
import reiForms from './reiForms';
import pdfReports from './pdfReports';
import reinz from './reinz';

export default function* rootSaga() {
    yield all([
        ...configSagas,
        ...segmentSagas,
        ...claudSagas,
        ...clapiSagas,
        ...msgApiSagas,
        ...signatureSagas,
        ...init,
        ...titlesSagas,
        ...searchResultSagas,
        ...cruxSagas,
        ...userPreferencesSagas,
        ...rapidSagas,
        ...manageUsersSagas,
        ...manageBrandingSagas,
        ...customiseReportSagas,
        ...previewReport,
        ...linkedAccounts,
        ...map,
        ...printPdf,
        ...savedSearch,
        ...watchList,
        ...territories,
        ...reiForms,
        ...pdfReports,
        ...reinz,
    ]);
}
