import { call, put, takeLatest, select } from 'redux-saga/effects';
import { selectSelectedAccount } from '../selectors/linkedAccount';
import UserPreferences from '../api/userPreferences';
import * as ReinzAction from '../actions/reinz';
import { trackEvent } from '../actions/segment';
import Segment from '../helpers/Segment';

const syncReinzMembershipStatusFn = () => function* () {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const isMember = yield call(
            UserPreferences.syncReinzMembershipStatus,
            selectedClAppAccountUserGuid,
        );

        yield put({
            type: ReinzAction.SYNC_MEMBERSHIP_STATUS_SUCCESS,
            payload: isMember,
        });
    } catch (error) {
        yield put({ type: ReinzAction.SYNC_MEMBERSHIP_STATUS_FAIL });
    }
};

const connectReinzFn = () => function* (action) {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const reinz = yield call(
            UserPreferences.addReinzMembership,
            selectedClAppAccountUserGuid,
            action?.payload,
        );

        yield put(ReinzAction.connectionSuccess({ isMember: reinz?.isMember }));
        yield put(ReinzAction.setReinzIntegrationStatus({
            connectStatus: reinz?.isMember ? 'success' : 'failed',
        }));

        if (reinz?.isMember) {
            yield put(trackEvent(Segment.integrations({
                eventName: 'Connect Integration',
                name: 'REINZ Member',
            })));
        }
    } catch (error) {
        yield put(ReinzAction.setReinzIntegrationStatus({
            connectStatus: 'error',
        }));
        yield put(ReinzAction.connectionSuccess({ isMember: false }));
    }
};

const cancelReinzFn = () => function* () {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        yield call(
            UserPreferences.cancelReinzMembership,
            selectedClAppAccountUserGuid,
        );

        const isMember = yield call(
            UserPreferences.syncReinzMembershipStatus,
            selectedClAppAccountUserGuid,
        );

        yield put(ReinzAction.cancelSuccess(isMember));
        yield put(ReinzAction.setReinzIntegrationStatus({
            cancelStatus: 'success',
        }));
        yield put(trackEvent(Segment.integrations({
            eventName: 'Disconnect Integration',
            name: 'REINZ Member',
        })));
    } catch (error) {
        yield put(ReinzAction.setReinzIntegrationStatus({
            cancelStatus: 'error',
        }));
        yield put(ReinzAction.cancelFail());
    }
};

export const syncReinzMembershipStatus = syncReinzMembershipStatusFn();

export const connectReinz = connectReinzFn();

export const cancelReinz = cancelReinzFn();

export function* syncReinzMembershipStatusWatcher() {
    yield takeLatest(
        ReinzAction.SYNC_MEMBERSHIP_STATUS,
        syncReinzMembershipStatus,
    );
}

export function* connectReinzWatcher() {
    yield takeLatest(
        ReinzAction.CONNECT_REINZ,
        connectReinz,
    );
}

export function* cancelReinzWatcher() {
    yield takeLatest(
        ReinzAction.CANCEL_REINZ,
        cancelReinz,
    );
}

export default [
    syncReinzMembershipStatusWatcher(),
    connectReinzWatcher(),
    cancelReinzWatcher(),
];