import Commons from './Commons';
import { LAYER, LAYER_NZ } from '../constants/map';
import { COUNTRY, isNZ } from '../constants/crux';

const getCatchmentPath = (shapeStr) => {
    const polygons = [];
    const parser = new DOMParser();
    const parsedPolygon = parser.parseFromString(shapeStr, 'text/xml');
    // Format of received coordinates is as follows:
    // <coordinates>153.050617,-27.443688999999367 153.05066,......,-27.443688999999367
    // First and last elements are not pairs, rather longitude and latitude respectively
    const coordinates = parsedPolygon.getElementsByTagName('coordinates');
    if (coordinates && coordinates.length > 0) {
        for (const coordinate of coordinates) {
            if (coordinate.childNodes[0] && coordinate.childNodes[0].nodeValue) {
                const path = [];
                const latlng = coordinate.childNodes[0].nodeValue.trim().split(/\s+/);
                latlng.forEach((element) => {
                    const point = element.trim().split(',');
                    const node = { lng: Number(point[0].trim()) };
                    if (point[1]) {
                        node.lat = Number(point[1].trim());
                    }
                    path.push(node);
                });

                polygons.push(path);
            }
        }
    }

    return polygons;
};
const getCatchmentPaths = (catchmentSchools, activeListId) => {
    const paths = [];
    if (catchmentSchools && catchmentSchools.length > 0) {
        catchmentSchools.forEach((catchmentSchool) => {
            const { schoolDetails } = catchmentSchool;
            if (schoolDetails) {
                let type = 'Primary';
                const { placeMetaData, placeId, shape } = schoolDetails;
                const { school } = placeMetaData;
                const displaySelectedSchool = !activeListId || placeId === activeListId;

                if (!displaySelectedSchool) return;
                if (placeMetaData && school && school.length > 0) {
                    type = school[0].schoolType ? school[0].schoolType : type;
                }
                paths.push({
                    id: placeId,
                    path: getCatchmentPath(shape),
                    type,
                });
            }
        });
    }
    return paths;
};

const getPropertyBubbleHeight = (length = 1) => {
    // if bubble is no longer centered
    // double check height values
    const propertyContentHeight = 101;
    const iconHeight = 35;
    const bubbleMarginBottom = 10;
    const groupedPropertySummaryHeight = 38;
    let total = ((propertyContentHeight * length) + bubbleMarginBottom + iconHeight);
    if (length > 1) {
        total += groupedPropertySummaryHeight;
    }

    return total;
};

const getSchoolBubbleHeight = (isTargetProperty = false) => {
    const schoolBubbleHeight = 62;
    const schoolBubbleIconHeight = 45;
    const targetPropertyBubbleHeight = 44;
    const targetPropertyIconHeight = 35;
    const bubbleMarginBottom = 10;
    let total = bubbleMarginBottom + schoolBubbleHeight + schoolBubbleIconHeight;
    if (isTargetProperty) {
        total = bubbleMarginBottom + targetPropertyBubbleHeight + targetPropertyIconHeight;
    }

    return total;
};

const centerBubbleInMap = (mapInstance, coordinates, bubbleHeight) => {
    mapInstance.panTo(coordinates);
    mapInstance.panBy(0, (bubbleHeight / 2) * -1);
};

const addCoordinateToBounds = (marker, bounds, key = '') => {
    if (marker instanceof Array) {
        let _markers = [];
        _markers = _markers.concat(marker);
        _markers.forEach((_marker) => {
            const coordinate = key ? Commons.get(_marker, key) : _marker;
            bounds.extend({
                lat: coordinate.latitude || coordinate.lat,
                lng: coordinate.longitude || coordinate.lng,
            });
        });
    } else {
        bounds.extend({
            lat: marker.latitude || marker.lat,
            lng: marker.longitude || marker.lng,
        });
    }
};

const addPolygonToBounds = (polygon, bounds) => {
    if (polygon instanceof Array) {
        polygon.forEach((schoolCatchment) => {
            if (schoolCatchment.path && schoolCatchment.path.length > 0) {
                schoolCatchment.path.forEach((coordinate) => {
                    if (coordinate && coordinate.length > 0) {
                        addCoordinateToBounds(coordinate, bounds);
                    }
                });
            }
        });
    } else {
        polygon.path.forEach((coordinate) => {
            if (coordinate && coordinate.length > 0) {
                addCoordinateToBounds(coordinate, bounds);
            }
        });
    }
};

const convertMetersToKm = (num) => {
    if (!num || num === 0) return num;

    if (num > 999) {
        return `${(num / 1000).toFixed(3)}km`;
    }
    return `${num.toFixed(2)}m`;
};

const getLayerNames = (layers = []) => {
    const layerNames = [];
    let mapLayer;
    try {
        const countryLayer = isNZ ? LAYER_NZ : LAYER;
        layers.forEach((layer) => {
            Object.keys(countryLayer).forEach((category) => {
                mapLayer = countryLayer[category].find(_layer => _layer.id === layer.layerName);
                if (mapLayer) layerNames.push(mapLayer.label);
            });
        });
    } catch (err) {
        // err
    }

    return layerNames;
};

const getCoordinateFromGeocoder = (address, callback) => {
    try {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
            address, componentRestrictions: { country: COUNTRY },
        }, (results, status) => {
            if (status === 'OK' && results && results.length > 0) {
                const latLng = results[0];
                const { lat, lng } = latLng.geometry.location.toJSON();
                callback({ lat, lng });
            } else {
                callback(null);
            }
        });
    } catch (err) {
        callback(null);
    }
};

const hasGoogleMap = () => {
    try {
        if (typeof window.google === 'object' &&
            typeof window.google.maps === 'object') {
            return true;
        }
    } catch (err) {
        // let it proceed and return false;
    }

    return false;
};

const getCoordinateFromPropertyCommons = (propertyCommons) => {
    const location = Commons.get(propertyCommons, 'location');

    if (location) {
        const { latitude, longitude } = location;

        if (latitude && longitude) {
            return {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
            };
        }
    }

    return {};
};

const getDistanceFromTarget = (targetProperty, property) => {
    const { lat, lon } = property || {};
    const {
        lat: targetPropertyLat,
        lon: targetPropertyLon,
    } = targetProperty || {}
    const point1 = new window.google.maps.LatLng(lat, lon);
    const point2 = new window
        .google
        .maps
        .LatLng(targetPropertyLat, targetPropertyLon);

    return Math.round((window.google.maps.geometry.spherical.computeDistanceBetween(
        point1,
        point2
    ) / 1000) * 100)  / 100;

};

export default {
    getCatchmentPaths,
    getPropertyBubbleHeight,
    centerBubbleInMap,
    addCoordinateToBounds,
    addPolygonToBounds,
    getSchoolBubbleHeight,
    convertMetersToKm,
    getLayerNames,
    getCoordinateFromGeocoder,
    hasGoogleMap,
    getCoordinateFromPropertyCommons,
    getDistanceFromTarget,
};
